<template>
    <div class="incident-crud">
        <div class="back-area" @click="back()">
            <img src="../../../../../images/back.svg" alt="">
            <span>{{ $t('incident.back') }}</span>
        </div>
        <div class="title-area">
            <h1>{{ $t('safe_reporting_incident') }}</h1>
        </div>
        <div class="form-fields">
            <div :class="`form-field ${$HasError(errors, 'room') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.room') }}</div>
                <el-input size="medium" v-model="model.room"></el-input>
                <div v-if="$HasError(errors, 'room')" class="field-error">
                    <span>{{ $GetError(errors, 'room') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'reason') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.reason') }}</div>
                <el-input type="textarea" :rows="3" v-model="model.reason"></el-input>
                <div v-if="$HasError(errors, 'reason')" class="field-error">
                    <span>{{ $GetError(errors, 'reason') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'category') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.category') }}</div>
                <el-select
                        v-model="model.category"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="">
                    <el-option
                            v-for="category in categories"
                            :key="'category-' + category.value"
                            :label="category.label"
                            :value="category.value">
                    </el-option>
                </el-select>
                <div v-if="$HasError(errors, 'category')" class="field-error">
                    <span>{{ $GetError(errors, 'category') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'facts_and_possible_causes') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.facts_and_possible_causes') }}</div>
                <el-input type="textarea" :rows="3" v-model="model.facts_and_possible_causes"></el-input>
                <div v-if="$HasError(errors, 'facts_and_possible_causes')" class="field-error">
                    <span>{{ $GetError(errors, 'facts_and_possible_causes') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'implications') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.implications') }}</div>
                <el-input type="textarea" :rows="3" v-model="model.implications"></el-input>
                <div v-if="$HasError(errors, 'implications')" class="field-error">
                    <span>{{ $GetError(errors, 'implications') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'classification') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.classification') }}</div>
                <el-select
                        v-model="model.classification"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="">
                    <el-option
                            v-for="classification in classifications"
                            :key="'classification-' + classification.value"
                            :label="classification.label"
                            :value="classification.value">
                    </el-option>
                </el-select>
                <div v-if="$HasError(errors, 'classification')" class="field-error">
                    <span>{{ $GetError(errors, 'classification') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'short_description') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.short_description') }}</div>
                <el-input type="textarea" :rows="3" v-model="model.short_description"></el-input>
                <div v-if="$HasError(errors, 'short_description')" class="field-error">
                    <span>{{ $GetError(errors, 'short_description') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'measurement') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.measurement') }}</div>
                <el-input type="textarea" :rows="3" v-model="model.measurement"></el-input>
                <div v-if="$HasError(errors, 'measurement')" class="field-error">
                    <span>{{ $GetError(errors, 'measurement') }}</span>
                </div>
            </div>
            <div v-if="model.user_id !== undefined" :class="`form-field ${$HasError(errors, 'user_id') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('incident.fields.user_id') }}</div>
                <el-select
                        v-model="model.user_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="">
                    <el-option
                            v-for="user in users"
                            :key="'user-' + user.value"
                            :label="user.label"
                            :value="user.value">
                    </el-option>
                </el-select>
                <div v-if="$HasError(errors, 'user_id')" class="field-error">
                    <span>{{ $GetError(errors, 'user_id') }}</span>
                </div>
            </div>
        </div>
        <div class="form-action">
            <el-button @click="submit()" size="medium" type="primary" round>{{ $t('incident.submit') }}</el-button>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data() {
            return {
                initial: JSON.parse(JSON.stringify(this.$parent.incident_crud.model)),
                model: this.$parent.incident_crud.model,
                errors: {}
            }
        },
        methods: {
            back(){
                this.$parent.toggleIncidentCrud(this.action);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else {
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/incident', model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getIncidents();

                        this.back();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/incident/' + id, model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getIncidents();

                        this.back();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.incident_crud.action;
            },
            categories: function () {
                let categories = [];

                Object.keys(this.$t('incident.categories')).map(value => {
                    categories.push({
                        value: value,
                        label: this.$t('incident.categories.' + value)
                    });
                });

                return categories;
            },
            classifications: function () {
                let classifications = [];

                Object.keys(this.$t('incident.classifications')).map(value => {
                    classifications.push({
                        value: value,
                        label: this.$t('incident.classifications.' + value)
                    });
                });

                return classifications;
            },
            users: function () {
                let users = [];

                if (this.initial.user_id !== undefined){
                    if (this.action === 'create'){
                        users.push({
                            value: this.$User().id,
                            label: this.$FullName(this.$User())
                        });

                        users.push({
                            value: null,
                            label: this.$t('incident.anonymous')
                        });
                    } else {
                        if (this.$RoleIs('director') && this.$User().id !== this.initial.user_id){
                            users.push({
                                value: this.$User().id,
                                label: this.$FullName(this.$User())
                            });
                        }

                        if (this.initial.user_id){
                            users.push({
                                value: this.initial.user_id,
                                label: this.$FullName(this.$parent.incidents.find(i => i.id === this.initial.id).applicant)
                            });
                        }

                        users.push({
                            value: null,
                            label: this.$t('incident.anonymous')
                        });
                    }
                }

                return users;
            }
        }
    }
</script>