<template>
    <section class="personnel-section default-section">
        <div class="section-title">
            <h1>{{ $t('staff') }}</h1>
        </div>

        <div class="personnel-content">
            <div class="table-parent scroll-grey">
                <el-table border :empty-text="$t('no_staff')" :data="data">
                    <el-table-column :label="$t('actions')" class-name="table-actions">
                        <template slot-scope="scope">
                            <el-button @click="toggleFiles(scope.row.files)" :disabled="scope.row.files.length === 0" size="medium" type="warning" icon="el-icon-folder-opened" circle></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('name') + ' ' + $t('surname')" class-name="image-text">
                        <template slot-scope="scope">
                            <img :src="$Avatar(scope.row)" alt="">
                            <span>{{ $FullName(scope.row) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('email')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.email }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('role')">
                        <template slot-scope="scope">
                            <span>{{ $GetRoleName(scope.row.role.name, scope.row.gender) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('gender')">
                        <template slot-scope="scope">
                            <span>{{ $t('genders.' + scope.row.gender) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('address')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.address }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('postal_code')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.postal_code }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('place')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.place }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('bsn_number')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.bsn_number }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('birthday')">
                        <template slot-scope="scope">
                            <span>{{ $Date(scope.row['birthday'], 'DD-MM-YYYY') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('created_at')">
                        <template slot-scope="scope">
                            <span>{{ $Date(scope.row['created_at'], 'DD-MM-YYYY') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('verified_at')" class-name="flex-direction">
                        <template slot-scope="scope">
                            <span class="success-text" v-if="scope.row['email_verified']">{{ $Date(scope.row['email_verified_at'], 'DD-MM-YYYY') }}</span>
                            <span class="warning-text" v-else>-------------------</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <el-pagination
                    v-if="personnel.length > pagination.limit"
                    background
                    layout="pager"
                    @current-change="paginate"
                    :pagerCount="5"
                    :current-page="pagination.page"
                    :page-size="pagination.limit"
                    :total="personnel.length">
            </el-pagination>
        </div>

        <transition name="fade">
            <Files v-if="files.popup" :files="files.items"></Files>
        </transition>
    </section>
</template>

<script>
    import Files from "./Files";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import helper from "../../../../mixins/helper";

    export default {
        mixins: [helper],
        components: {Files},
        beforeMount() {
            this.getPersonnel();
        },
        data() {
            return {
                files: {
                    popup: false,
                    items: []
                },
                personnel: [],
                pagination: {
                    page: 1,
                    limit: 10
                }
            }
        },
        methods: {
            getPersonnel() {
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/personnel').then(({data}) => {
                    this.personnel = data.personnel;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            paginate(page) {
                this.pagination.page = page;
            },
            toggleFiles(items){
                if (this.files.popup){
                    this.files = {
                        popup: false,
                        items: []
                    };
                } else {
                    this.files = {
                        popup: true,
                        items: items
                    };
                }
            }
        },
        computed: {
            data: function () {
                return this.personnel.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            }
        }
    }
</script>