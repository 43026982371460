<template>
    <section class="protocols-section default-section">
        <div v-if="active" class="back-to-protocols" @click="toggleActive()">
            <img src="../../../../images/back.svg" alt="">
            <span>{{ $t('back_to_protocols') }}</span>
        </div>
        <div v-else class="section-title">
            <h1>{{ $t('protocols') }}</h1>
            <el-button v-if="$HasPermission('crud-protocols')" @click="toggleCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
        </div>

        <div v-if="active" class="single-item">
            <h1 class="single-title">{{ active.title }}</h1>
            <p class="single-created">{{ $Date(active.created_at, 'DD-MM-YYYY') }}</p>
            <vue-editor v-model="active.content" class="editor-preview" disabled/>
            <div class="single-files">
                <el-link
                        v-for="(file, key) in active.files"
                        :key="'protocol-file-' + key"
                        :href="file.fullPath"
                        target="_blank"
                        type="primary"
                >
                    <span>{{ file.name }}</span>
                    <el-tag effect="dark">
                        {{ $GetFileExtension(file.path) }}
                    </el-tag>
                </el-link>
            </div>
            <div class="single-actions">
                <div class="single-action" @click="share(active)">
                    <input type="hidden" id="clipboard"/>
                    <i class="fa fa-clipboard"></i>
                    <span>{{ $t('copy_url') }}</span>
                </div>
                <div class="single-action" v-if="$HasPermission('crud-protocols')" @click="update(active)">
                    <img src="../../../../images/edit.svg" alt="">
                    <span>{{ $t('edit') }}</span>
                </div>
                <el-popconfirm
                        icon-color="red"
                        icon="el-icon-info"
                        :cancel-button-text="$t('no')"
                        :confirm-button-text="$t('yes')"
                        :title="$t('delete_question')"
                        @confirm="destroy(active)"
                >
                    <div class="single-action" slot="reference" v-if="$HasPermission('crud-protocols')">
                        <img src="../../../../images/delete.png" alt="">
                        <span>{{ $t('delete') }}</span>
                    </div>
                </el-popconfirm>
            </div>
        </div>

        <div v-else class="protocols-content">
            <div class="search-area">
                <el-autocomplete
                        v-model="search"
                        :fetch-suggestions="fetchSuggestions"
                        value-key="title"
                        :debounce="500"
                        :trigger-on-focus="false"
                        @select="toggleActive"
                >
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-autocomplete>
            </div>

            <div class="most-read">
                <h2 class="most-read-title">{{ $t('most_read') }}</h2>

                <div class="most-read-content">
                    <div class="most-read-items">
                        <div
                                v-for="(item, key) in most_read"
                                :key="'most-read-' + key"
                                class="most-read-item"
                                @click="toggleActive(item)"
                        >{{ item.title }}</div>
                    </div>
                </div>
            </div>

            <div class="categories">
                <h2 class="categories-title">{{ $t('browse_by_category') }}</h2>

                <el-breadcrumb v-if="breadcrumb.length" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item, key) in breadcrumb" :key="'breadcrumb-item-' + key">
                        <div v-if="breadcrumb.length === 1" class="back-to-first-category" @click="changeCategory(null)">
                            <img src="../../../../images/back.svg" alt="">
                            <span>{{ $t('back_to_first_category') }}</span>
                        </div>
                        <div v-else @click="changeCategory(item)">{{ item.name }}</div>
                    </el-breadcrumb-item>
                </el-breadcrumb>

                <div class="areas">
                    <div class="area" v-for="(area, key) in data" :key="'area-' + key">
                        <div class="item" v-for="(item, key) in area" :key="'item-' + key">
                            <div v-if="item.protocols" class="item-content" @click="changeCategory(item)">
                                <div class="icon-area">
                                    <img src="../../../../images/folder.svg" alt="">
                                </div>
                                <span>{{ item.name }}</span>
                            </div>
                            <div v-else class="item-content" @click="toggleActive(item)">
                                <div class="icon-area">
                                    <img src="../../../../images/file.svg" alt="">
                                </div>
                                <span>{{ item.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade">
            <Crud v-if="crud.popup"></Crud>
        </transition>
    </section>
</template>

<script>
    import {VueEditor} from "vue2-editor";
    import helper from "../../../../mixins/helper";
    import Crud from "./Crud";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        components: {Crud, VueEditor},
        async beforeMount() {
            await this.fetchData();
            await this.checkURL();
        },
        data() {
            return {
                most_read: [],
                categories: [],
                tree: [],
                active: null,
                search: '',
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                category: null
            };
        },
        methods: {
            async fetchData(){
                await this.getMostRead();
                await this.getCategories();
                if (this.active){
                    this.active = this.most_read.find(p => p.id === this.active.id);
                }
            },
            async getMostRead(){
                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/protocol').then(({data}) => {
                    this.most_read = data.most_read;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            async getCategories(){
                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/category').then(({data}) => {
                    this.categories = data.categories;
                    this.tree = data.tree;
                    this.checkCategory();
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            async checkURL(){
                let search = this.$route.query.protocol;

                if (search){
                    let id = search.split('-').reverse()[0];
                    this.$store.commit('setPreloader', true);
                    await $http().get(base_url + '/api/client/protocol/' + id).then(({data}) => {
                        this.toggleActive(data.protocol);
                        this.$store.commit('setPreloader', false);
                    }).catch(() => {
                        this.toggleActive();
                        this.$store.commit('setPreloader', false);
                    });
                }
            },
            toggleActive(active = null){
                this.active = active;
                if (active){
                    this.see(active.id);
                } else if (this.$route.query.protocol) {
                    this.$router.push({name: 'protocols'});
                }
            },
            see(id){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/protocol/' + id + '/see').then(() => {
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            fetchSuggestions(keyword, cb) {
                $http().get(base_url + '/api/client/protocol/action/search?keyword=' + keyword).then(({data}) => {
                    cb(data.result);
                });
            },
            destroy(protocol){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/protocol/' + protocol.id).then(async ({data}) => {
                    if (data.success){
                        this.active = null;
                        await this.fetchData();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(protocol){
                let model = {
                    id: protocol.id,
                    category_id: protocol.category_id,
                    title: protocol.title,
                    content: protocol.content,
                    files: protocol.files
                };

                this.toggleCrud('update', model);
            },
            share(protocol){
                let link = window.location.origin + '/#/protocols?protocol=' + this.$Slug(protocol.title) + '-' + protocol.id;
                let clipboard = document.querySelector('#clipboard');
                clipboard.setAttribute('type', 'text');
                clipboard.setAttribute('value', link);
                clipboard.select();
                document.execCommand("copy");
                clipboard.setAttribute('type', 'hidden');
                Message({
                    message: this.$t('copied_to_clipboard'),
                    showClose: true,
                    type: 'success'
                });
            },
            toggleCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        category_id: null,
                        title: null,
                        content: null,
                        files: []
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        model: {}
                    }
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        model: model
                    }
                }
            },
            checkCategory(){
                if (this.category){
                    let category = this.categories.find(c => c.id === this.category.id);
                    this.category = category ? category : null;
                }
            },
            changeCategory(category){
                this.category = category;
            }
        },
        computed: {
            data: function () {
                let data = [];

                if (this.category){
                    if (this.category.children){
                        data = [...this.category.children];
                    }
                    data = [...data, ...this.category.protocols];
                } else {
                    data = [...this.tree];
                }

                let group = [[], [], []];

                if (data.length < 16){
                    group[0] = data.slice(0, 5);
                    group[1] = data.slice(5, 10);
                    group[2] = data.slice(10, 15);
                } else {
                    let count = Math.ceil(data.length / 3);
                    group[0] = data.slice(0, count);
                    group[1] = data.slice(count, count * 2);
                    group[2] = data.slice(count * 2, count * 3);
                }

                return group;
            },
            breadcrumb: function () {
                let breadcrumb = [];

                if (this.category){
                    breadcrumb = this.categories.filter((category) => {
                        return this.category['parent_ids'].indexOf(category.id) !== -1;
                    });

                    breadcrumb.push(this.category);
                }

                return breadcrumb;
            }
        }
    };
</script>