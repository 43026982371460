<template>
    <div class="popup crud-popup fields-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t(action) }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div v-if="action === 'create'" class="crud-form fields-form">
                    <div class="form-field" v-for="(field, key) in model.fields" :key="'field-' + key">
                        <el-input size="medium" v-model="field.name" :placeholder="$t('name')"></el-input>
                        <el-button @click="removeField(key)" icon="el-icon-delete" type="danger" size="medium" circle></el-button>
                    </div>
                    <el-button @click="addField()" icon="el-icon-plus" type="primary" size="medium" circle></el-button>
                </div>
                <div v-else class="crud-form">
                    <div :class="`form-field ${$HasError(errors, 'name') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('name') }}</div>
                        <el-input size="medium" v-model="model.name"></el-input>
                        <div v-if="$HasError(errors, 'name')" class="field-error">
                            <span>{{ $GetError(errors, 'name') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" :disabled="!can_submit" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data() {
            return {
                model: this.$parent.field_crud.model,
                errors: {}
            }
        },
        methods: {
            close(){
                this.$parent.toggleFieldCrud(this.action);
            },
            addField(){
                this.model.fields.push({name: null});
            },
            removeField(key){
                this.model.fields.splice(key, 1);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else {
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/checklist-room-type-field', model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getRooms();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/checklist-room-type-field/' + id, model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getRooms();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.field_crud.action;
            },
            can_submit: function () {
                if (this.action === 'update'){
                    return true;
                }
                return this.model.fields.filter((field) => {
                    if (typeof field.name === 'string'){
                        return field.name.trim() !== '';
                    }
                    return field.name !== null;
                }).length === this.model.fields.length && this.model.fields.length > 0;
            }
        }
    }
</script>