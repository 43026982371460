<template>
    <div class="popup used-hours-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('used_hours') }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="used-hours-form">
                    <div class="start-and-end">
                        <div :class="`form-field ${$HasError(errors, 'start') ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('start') }}</div>
                            <el-date-picker
                                    v-model="model.start"
                                    format="dd-MM-yyyy"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    :placeholder="$t('start')">
                            </el-date-picker>
                            <div v-if="$HasError(errors, 'start')" class="field-error">
                                <span>{{ $GetError(errors, 'start') }}</span>
                            </div>
                        </div>
                        <div :class="`form-field ${$HasError(errors, 'end') ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('end') }}</div>
                            <el-date-picker
                                    v-model="model.end"
                                    format="dd-MM-yyyy"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    :placeholder="$t('end')">
                            </el-date-picker>
                            <div v-if="$HasError(errors, 'end')" class="field-error">
                                <span>{{ $GetError(errors, 'end') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="used-hours-result">
                    <div class="used-hours-item">{{ $t('absence') + ' - ' + result.absence.toFixed(2) }}</div>
                    <div class="used-hours-item">{{ $t('sick') + ' - ' + result.sick.toFixed(2) }}</div>
                    <div class="used-hours-item">{{ $t('vacation') + ' - ' + result.vacation.toFixed(2) }}</div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="check()" size="medium" type="success" round>{{ $t('check') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";

    export default {
        mixins: [helper],
        data(){
            return {
                model: {
                    start: null,
                    end: null
                },
                errors: {},
                result: {
                    absence: 0,
                    sick: 0,
                    vacation: 0
                }
            }
        },
        methods: {
            close(){
                this.$parent.toggleUsedHours();
            },
            check(){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/free-day-used-hours', this.model).then(({data}) => {
                    if (data.success){
                        this.errors = {};
                        this.result = {
                            absence: data.absence,
                            sick: data.sick,
                            vacation: data.vacation
                        };
                    } else {
                        this.errors = data.errors;
                        this.result = {
                            absence: 0,
                            sick: 0,
                            vacation: 0
                        };
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>