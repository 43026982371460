<template>
    <div class="popup crud-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ title }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="crud-form">
                    <div class="form-field">
                        <div class="field-label">{{ $t('title') }}</div>
                        <el-input size="medium" v-model="model.title"></el-input>
                    </div>
                    <div class="appointment-content">
                        <div class="appointment-part">
                            <div class="form-field">
                                <div class="field-label">{{ $t('start_date') }}</div>
                                <el-date-picker
                                        :clearable="false"
                                        v-model="model.start_date"
                                        format="dd-MM-yyyy"
                                        value-format="yyyy-MM-dd"
                                        type="date">
                                </el-date-picker>
                            </div>
                            <div v-if="end_date_visibility" class="inclusive-area">
                                <span>{{ $t('inclusive') }}</span>
                            </div>
                            <div v-if="end_date_visibility" class="form-field">
                                <div class="field-label">{{ $t('end_date') }}</div>
                                <el-date-picker
                                        :clearable="false"
                                        v-model="model.end_date"
                                        format="dd-MM-yyyy"
                                        value-format="yyyy-MM-dd"
                                        type="date">
                                </el-date-picker>
                            </div>
                            <div v-else class="end-date-action">
                                <el-button @click="end_date_visibility = true" type="primary">{{ $t('select_end_date') }}</el-button>
                            </div>
                        </div>
                        <div v-if="!model.whole_day" class="appointment-part">
                            <div class="form-field">
                                <div class="field-label">{{ $t('start_time') }}</div>
                                <el-time-select
                                        v-model="model.start_time"
                                        :clearable="false"
                                        :picker-options="{
                                            start: '00:00',
                                            step: '00:05',
                                            end: '23:55'
                                        }"
                                        placeholder="--:--"
                                ></el-time-select>
                            </div>
                            <div v-if="end_date_visibility" class="inclusive-area"></div>
                            <div class="form-field">
                                <div class="field-label">{{ $t('end_time') }}</div>
                                <el-time-select
                                        v-model="model.end_time"
                                        :clearable="false"
                                        :picker-options="{
                                            start: '00:00',
                                            step: '00:05',
                                            end: '23:55'
                                        }"
                                        placeholder="--:--"
                                ></el-time-select>
                            </div>
                        </div>
                        <div class="whole-day">
                            <el-checkbox @change="changeWholeDay" v-model="model.whole_day">{{ $t('whole_day') }}</el-checkbox>
                        </div>
                    </div>
                    <div class="form-field">
                        <div class="field-label">{{ $t('description') }}</div>
                        <el-input type="textarea" :rows="5" v-model="model.description"></el-input>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="submit()" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import moment from "moment";

    export default {
        mixins: [helper],
        beforeMount() {
            if (this.model.whole_day){
                let a = moment(this.model.start_date).format('YYYY-MM-DD');
                let b = moment(this.model.end_date).format('YYYY-MM-DD');
                this.end_date_visibility = moment(b).diff(moment(a), 'days') > 1;
            }
        },
        data() {
            return {
                model: this.$parent.crud.model,
                end_date_visibility: true
            }
        },
        methods: {
            close(){
                this.$parent.crud = {
                    popup: false,
                    action: null,
                    model: {}
                };
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else {
                    this.update(this.model, this.model.id);
                }
            },
            changeWholeDay(value){
                if (value){
                    this.model.start_time = '00:00';
                    this.model.end_time = '00:00';
                } else {
                    this.end_date_visibility = true;
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/event', model).then(({data}) => {
                    if (data.success){
                        this.$parent.options.initialEvents.push(data.event);

                        this.$parent.refresh++;

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/event/' + id, model).then(({data}) => {
                    if (data.success){
                        this.$parent.options.initialEvents.splice(this.$parent.options.initialEvents.findIndex(e => e.id === id), 1, data.event);

                        this.$parent.refresh++;

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            title: function () {
                let start = this.model.start_date;
                let end = this.model.end_date;

                if (!this.model.whole_day){
                    start = start + ' ' + this.model.start_time + ':00';
                    end = end + ' ' + this.model.end_time + ':00'
                }

                return this.$DisplayDatesRange(start, end);
            },
            action: function () {
                return this.$parent.crud.action;
            }
        }
    }
</script>