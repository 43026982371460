<template>
    <section class="checklist-group-section default-section">
        <overview v-if="screen === 'overview'"></overview>
        <history v-if="screen === 'history'"></history>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import Overview from "./Overview";
    import History from "./History";

    export default {
        mixins: [helper],
        components: {
            Overview,
            History
        },
        beforeMount() {
            this.check();
        },
        methods: {
            check(){
                if (this.screens.indexOf(this.screen) === -1){
                    this.$router.push({name: 'checklist', params: {prefix: 'overview'}});
                }
            }
        },
        computed: {
            screen: function () {
                return this.$route.params.prefix;
            },
            screens: function () {
                let screens = {
                    overview: true,
                    history: this.$RoleIs('director') || this.$RoleIs('manager')
                };

                Object.keys(screens).map((screen) => {
                    if (!screens[screen]){
                        delete screens[screen];
                    }
                });

                return Object.keys(screens);
            }
        },
        watch: {
            $route(){
                this.check();
            }
        }
    }
</script>