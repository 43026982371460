<template>
    <el-upload
            drag
            multiple
            class="uploader"
            :on-change="change"
            :auto-upload="false"
            :show-file-list="false"
            action="https://jsonplaceholder.typicode.com/posts/"
    >
        <i class="el-icon-upload"></i>
        <div class="el-upload-placeholder">
            <p>{{ $t('uploader.title') }}</p>
        </div>
        <div v-if="files.new.length || files.uploaded.length" class="el-upload-content" slot="tip">
            <div class="files-area">
                <p>{{ $t('uploader.uploaded_files') + ' / (' + $t('name') + (category ? ' - ' + $t('category') : '') + ')' }}</p>
                <div class="files">
                    <div class="file" v-for="(file, key) in files.new" :key="'new-file-' + key">
                        <div class="file-fields">
                            <el-input v-model="file.name" class="new-file-input">
                                <template #prepend>
                                    <div>
                                        <span>{{ $t('new') }}</span>
                                    </div>
                                </template>
                                <template #append>{{ $GetFileExtension(file.raw.name) }}</template>
                            </el-input>
                            <el-select
                                    v-if="category"
                                    v-model="file.category"
                                    filterable
                                    remote
                                    reserve-keyword
                                    :placeholder="$t('uploader.category_placeholder')">
                                <el-option
                                        v-for="category in categories"
                                        :key="'new-category-' + category.value"
                                        :label="category.label"
                                        :value="category.value"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="file-actions">
                            <el-button @click="preview(file)" size="medium" type="primary" icon="el-icon-view" circle></el-button>
                            <el-button @click="remove(file)" size="medium" type="danger" icon="el-icon-delete" circle></el-button>
                        </div>
                    </div>
                    <div class="file" v-for="(file, key) in files.uploaded" :key="'uploaded-file-' + key">
                        <div class="file-fields">
                            <el-input v-model="file.name" :disabled="!uploadedByMe(file)">
                                <template #append>{{ $GetFileExtension(file.path) }}</template>
                            </el-input>
                            <el-select
                                    v-if="category"
                                    v-model="file.category"
                                    :disabled="!uploadedByMe(file)"
                                    filterable
                                    remote
                                    reserve-keyword
                                    :placeholder="$t('uploader.category_placeholder')">
                                <el-option
                                        v-for="category in categories"
                                        :key="'uploaded-category-' + category.value"
                                        :label="category.label"
                                        :value="category.value"
                                ></el-option>
                            </el-select>
                        </div>
                        <div class="file-actions">
                            <el-button @click="view(file)" size="medium" type="primary" icon="el-icon-view" circle></el-button>
                            <el-button v-if="uploadedByMe(file)" @click="destroy(file)" size="medium" type="danger" icon="el-icon-delete" circle></el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-upload>
</template>

<script>
    import {$http} from "../hooks/api";
    import {base_url} from "../constants/api";
    import {Message} from "element-ui";
    import helper from "../mixins/helper";

    export default {
        mixins: [helper],
        props: {
            action: {
                type: Function
            },
            uploaded: {
                type: Array
            },
            upload_errors: {
                type: Object
            },
            category: {
                type: Boolean
            }
        },
        beforeMount() {
            this.files.uploaded = JSON.parse(JSON.stringify(this.uploaded));
            this.files.core_uploaded = JSON.parse(JSON.stringify(this.uploaded));
        },
        data(){
            return {
                files: {
                    new: [],
                    uploaded: [],
                    core_uploaded: []
                }
            }
        },
        methods: {
            change(file){
                let object = {
                    uid: file.uid,
                    raw: file.raw,
                    name: file.name.replace(/\.[^/.]+$/, "")
                };

                const types = [
                    'doc',
                    'docx',
                    'xls',
                    'xlsx',
                    'pdf',
                    'jpg',
                    'jpeg',
                    'png',
                    'bmp',
                    'html'
                ];

                if (types.indexOf(this.$GetFileExtension(file.raw.name)) !== -1){
                    if (this.category){
                        object.category = null;
                    }
                    this.files.new.push(object);
                } else {
                    Message({
                        message: this.$t('uploader.error'),
                        showClose: true,
                        type: 'warning'
                    });
                }
            },
            remove(file){
                this.files.new = this.files.new.filter((item) => {
                    return item.uid !== file.uid;
                });
            },
            preview(file){
                window.open(URL.createObjectURL(file.raw),'_blank');
            },
            view(file){
                window.open(file.fullPath,'_blank');
            },
            uploadedByMe(file){
                return file['uploaded_by'] === this.$User().id;
            },
            update(){
                let files = [];

                this.files.uploaded.map(file => {
                    if (this.uploadedByMe(file)){
                        let core_file = this.files.core_uploaded.find(f => f.id === file.id);
                        if (file.name !== core_file.name || (this.category && file.category !== core_file.category)){
                            let object = {
                                id: file.id,
                                name: file.name
                            };

                            if (this.category){
                                object.category = file.category;
                            }

                            files.push(object);
                        }
                    }
                });

                let data = {
                    files: files,
                    has_category: this.category
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/upload', data).then(({data}) => {
                    if (data.success){
                        this.action();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Object.keys(data.errors).map(id => {
                            const message = this.files.core_uploaded.find(f => +f.id === +id).name + ' | ' + data.errors[id];
                            Message({
                                message: message,
                                showClose: true,
                                duration: 10000,
                                type: 'warning'
                            });
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            destroy(file){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/upload/' + file.id).then(({data}) => {
                    if (data.success){
                        this.action();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            categories: function () {
                let categories = this.$t('uploader.categories');
                let result = [];

                Object.keys(categories).map((value) => {
                    result.push({
                        value: value,
                        label: categories[value]
                    });
                });

                return result;
            }
        },
        watch: {
            upload_errors(errors){
                Object.keys(errors).map(uid => {
                    const message = this.files.new.find(f => +f.uid === +uid).name + ' | ' + errors[uid];
                    Message({
                        message: message,
                        showClose: true,
                        duration: 10000,
                        type: 'warning'
                    });
                });
            },
            'files.uploaded': {
                deep: true,
                immediate: true,
                handler: function (files) {
                    let changed = [];

                    files.map(file => {
                        if (this.uploadedByMe(file)){
                            let core_file = this.files.core_uploaded.find(f => f.id === file.id);
                            if (file.name !== core_file.name || (this.category && file.category !== core_file.category)){
                                changed.push(file);
                            }
                        }
                    });

                    this.$parent.changed_files = changed.length > 0;
                }
            }
        }
    }
</script>