<template>
    <div class="popup evaluation-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('evaluation.label') + ' - ' + $DisplayID(model.id, 'EVA') }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="columns-area">
                    <div>
                        <p>{{ $t('made_on') }}</p>
                        <p>{{ moment(model.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YY') }} {{ $t('at') }} {{ moment(model.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }}</p>
                    </div>
                    <div>
                        <p>{{ $t('evaluation.year_label') }}</p>
                        <p>{{ model.year }}</p>
                    </div>
                    <div>
                        <p>{{ $t('evaluation.label') }}</p>
                        <p>{{ model.content }}</p>
                    </div>
                    <div>
                        <p>{{ $t('evaluation.created_by') }}</p>
                        <p>{{ $FullName(model.user) }}</p>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import moment from 'moment';

    export default {
        mixins: [helper],
        data(){
            return {
                moment: moment,
                model: this.$parent.evaluation_popup.model
            }
        },
        methods: {
            close(){
                this.$parent.toggleEvaluation();
            }
        }
    }
</script>