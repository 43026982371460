<template>
    <div class="popup crud-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t(action) }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="crud-form">
                    <div :class="`form-field ${$HasError(errors, 'patient') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('patient_name') }} </div>
                        <el-input size="medium" v-model="model.patient"></el-input>
                        <div v-if="$HasError(errors, 'patient')" class="field-error">
                            <span>{{ $GetError(errors, 'patient') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'treatment_at') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('treatment_at') }} </div>
                        <el-date-picker
                                v-model="model.treatment_at"
                                format="dd-MM-yyyy"
                                value-format="yyyy-MM-dd"
                                type="date">
                        </el-date-picker>
                        <div v-if="$HasError(errors, 'treatment_at')" class="field-error">
                            <span>{{ $GetError(errors, 'treatment_at') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'email') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('email') }} </div>
                        <el-input size="medium" v-model="model.email"></el-input>
                        <div v-if="$HasError(errors, 'email')" class="field-error">
                            <span>{{ $GetError(errors, 'email') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'phone') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('phone') }} </div>
                        <el-input size="medium" v-model="model.phone"></el-input>
                        <div v-if="$HasError(errors, 'phone')" class="field-error">
                            <span>{{ $GetError(errors, 'phone') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'topic') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('topic') }} </div>
                        <el-input size="medium" v-model="model.topic"></el-input>
                        <div v-if="$HasError(errors, 'topic')" class="field-error">
                            <span>{{ $GetError(errors, 'topic') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'user_id') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('assign_to_employee') }} </div>
                        <el-select
                                v-model="model.user_id"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    class="option-with-image"
                                    v-for="worker in workers"
                                    :key="'worker-' + worker.id"
                                    :label="$FullName(worker)"
                                    :value="worker.id">
                                <img :src="$Avatar(worker)" alt="">
                                <span>{{ $FullName(worker) }}</span>
                            </el-option>
                        </el-select>
                    </div>
                    <div v-if="action === 'create'" :class="`form-field ${$HasError(errors, 'content') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('content') }}</div>
                        <vue-editor
                                :editorOptions="editorOptions"
                                @image-added="$Upload"
                                useCustomImageHandler
                                v-model="model.content"
                        ></vue-editor>
                        <div v-if="$HasError(errors, 'content')" class="field-error">
                            <span>{{ $GetError(errors, 'content') }}</span>
                        </div>
                    </div>
                    <div v-if="action === 'update'" :class="`form-field ${$HasError(errors, 'status') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('status') }} </div>
                        <el-select
                                v-model="model.status"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="status in statuses"
                                    :key="'status-' + status.value"
                                    :label="status.label"
                                    :value="status.value"
                            ></el-option>
                        </el-select>
                        <div v-if="$HasError(errors, 'status')" class="field-error">
                            <span>{{ $GetError(errors, 'status') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";
    import { VueEditor } from "vue2-editor";

    export default {
        mixins: [helper],
        components: {VueEditor},
        data() {
            return {
                editorOptions: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                model: this.$parent.crud.model,
                errors: {},
                statuses: [
                    {value: 'opened', label: this.$t('opened')},
                    {value: 'closed', label: this.$t('closed')}
                ]
            }
        },
        methods: {
            close(){
                this.$parent.toggleCrud(this.action);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else{
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/complaint', model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getComplaints();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/complaint/' + id, model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getComplaints();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.crud.action;
            },
            workers: function () {
                return this.$parent.workers;
            }
        }
    }
</script>