<template>
    <section class="graphic-section default-section">
        <div class="section-title">
            <h1>{{ $t('graphic') }}</h1>
        </div>

        <div class="graphic-filters">
            <div class="graphic-filter">
                <el-button @click="back()" type="primary" size="mini" icon="el-icon-arrow-left" circle></el-button>
                <el-date-picker
                        v-model="filters.day"
                        @change="filtering()"
                        size="mini"
                        format="dd-MM-yyyy"
                        value-format="dd-MM-yyyy"
                        type="date">
                </el-date-picker>
                <el-button @click="next()" type="primary" size="mini" icon="el-icon-arrow-right" circle></el-button>
                <el-button @click="today()" class="today-button" type="primary" size="small">Today</el-button>
                <div class="only-me">
                    <el-checkbox v-model="filters.only_me" @change="filtering()" size="mini">Only Me</el-checkbox>
                </div>
            </div>
            <div class="graphic-filter">
                <el-button
                        v-if="$HasPermission('change-graphic') && !readonly"
                        @click="toggleTime('create')"
                        type="info"
                        icon="el-icon-plus"
                        size="small"
                >{{ $t('time') }}</el-button>
                <el-button
                        v-if="$HasPermission('crud-room')"
                        @click="toggleCrud('create')"
                        class="new-room"
                        size="small"
                        type="primary"
                        icon="el-icon-plus"
                >{{ $t('room') }}</el-button>
                <el-button @click="changeView('day')" size="small"
                           :type="`${view.type === 'day' ? 'success' : 'info'}`">{{
                    $t('day') }}
                </el-button>
                <el-button @click="changeView('week')" size="small"
                           :type="`${view.type === 'week' ? 'success' : 'info'}`">
                    {{ $t('week') }}
                </el-button>
            </div>
        </div>

        <div class="graphic-parent scroll-grey">
            <div class="graphic-content">
                <div :class="`graphic-header ${'repeat-' + graphic.length} ${readonly ? 'is-readonly' : ''}`">
                    <div class="header-column" v-for="(day, key) in graphic" :key="'header-column-' + key">
                        <div :class="`column-title ${$IsToday(day.title) ? 'is-today' : ''}`">{{ dayName(day.title) }}</div>
                    </div>
                </div>
                <div class="graphic-body scroll-grey">
                    <div class="graphic-rooms">
                        <draggable
                                class="graphic-list"
                                :draggable="$HasPermission('crud-room') ? '.room-item' : '.no-sorting'"
                                tag="div"
                                group="id"
                                ghostClass="ghost"
                                :list="rooms"
                                @change="sorting()"
                        >
                            <div :class="`graphic-room room-item ${$HasPermission('crud-room') ? 'all-scroll' : ''}`" v-for="(room, key) in rooms" :key="'room-' + key" :style="{height: roomHeight}">
                                <div>{{ room.name }}</div>
                                <el-dropdown v-if="$HasPermission('crud-room')" trigger="click" :hide-on-click="false">
                                    <span class="el-dropdown-link">
                                        <i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <el-button @click="update(room)" size="mini" type="primary">{{ $t('update') }}
                                            </el-button>
                                        </el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-popconfirm
                                                    icon-color="red"
                                                    icon="el-icon-info"
                                                    :cancel-button-text="$t('no')"
                                                    :confirm-button-text="$t('yes')"
                                                    :title="$t('delete_question')"
                                                    @confirm="destroy(room)"
                                            >
                                                <el-button slot="reference" size="mini" type="danger">{{ $t('delete') }}
                                                </el-button>
                                            </el-popconfirm>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </draggable>
                    </div>
                    <div :class="`graphic-days ${graphic.length > 1 ? 'graphic-week-view-' + graphic.length : 'graphic-day-view'} ${readonly ? 'is-readonly' : ''}`">
                        <div class="graphic-day" v-for="(day, key) in graphic" :key="'day-' + key">
                            <div class="graphic-room" v-for="(room, key) in day.rooms" :key="'room-' + key" :style="{height: roomHeight}">
                                <div class="graphic-section" v-for="(section, key) in getSections(room.sections)" :key="'section-' + key">
                                    <el-popover
                                            v-for="(time, key) in section.times" :key="'time-' + key"
                                            :title="time.start + ' - ' + time.end"
                                            :disabled="time.type === 'empty'"
                                            popper-class="graphic-popover"
                                            placement="bottom"
                                            width="200"
                                            trigger="hover">
                                        <template>
                                            <slot v-if="time.type === 'busy'" name="content">
                                                <div class="time-popover-content">
                                                    <div class="user-name-and-image">
                                                        <img :src="$Avatar(time.user)" alt="">
                                                        <span>{{ $FullName(time.user) }}</span>
                                                    </div>
                                                    <div v-if="$HasPermission('change-graphic')" class="time-actions">
                                                        <el-button @click="updateTime(time)" size="mini" type="primary" icon="el-icon-edit" circle></el-button>
                                                        <el-popconfirm
                                                                v-if="time.combo === null"
                                                                icon-color="red"
                                                                icon="el-icon-info"
                                                                :cancel-button-text="$t('no')"
                                                                :confirm-button-text="$t('yes')"
                                                                :title="$t('delete_question')"
                                                                @confirm="destroyTime(time)"
                                                        >
                                                            <el-button slot="reference" size="mini" type="danger" icon="el-icon-delete" circle></el-button>
                                                        </el-popconfirm>
                                                        <el-button v-else @click="destroyTimePopup(time)" size="mini" type="danger" icon="el-icon-delete" circle></el-button>
                                                    </div>
                                                </div>
                                            </slot>

                                            <div v-if="time.type === 'empty'"
                                                 :class="`graphic-time ${$HasPermission('change-graphic') ? 'time-is-empty' : ''}`"
                                                 :style="time.style"
                                                 slot="reference"
                                                 @click="singleTime(time, room.id, day)"
                                            >{{ time.start + ' - ' + time.end}}</div>

                                            <div v-else class="graphic-time time-is-busy"
                                                 :style="time.style"
                                                 slot="reference"
                                            >
                                                <div class="busy-full-name">{{ $FullName(time.user) + ' ' + time.start + ' - ' + time.end}}</div>
                                            </div>
                                        </template>
                                    </el-popover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-button
                v-if="$HasPermission('change-graphic') && !readonly"
                @click="toggleTime('create')"
                class="new-graphic"
                type="success"
                icon="el-icon-time"
                size="big"
                circle
        ></el-button>

        <transition name="fade">
            <Crud v-if="crud.popup"></Crud>
        </transition>

        <transition name="fade">
            <Time v-if="time.popup"></Time>
        </transition>

        <transition name="fade">
            <DestroyTime v-if="destroy_time.popup"></DestroyTime>
        </transition>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import Crud from "./Crud";
    import Time from "./Time";
    import DestroyTime from "./DestroyTime";
    import {Message} from "element-ui";
    import {base_url} from "../../../../constants/api";
    import {$http} from "../../../../hooks/api";
    import moment from 'moment';
    import draggable from 'vuedraggable';

    export default {
        mixins: [helper],
        components: {Crud, Time, DestroyTime, draggable},
        beforeMount() {
            this.getGraphic();
        },
        data() {
            const start = moment().isoWeekday(1).startOf('isoWeek');
            const end = moment().isoWeekday(1).endOf('isoWeek');
            return {
                users: [],
                rooms: [],
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                time: {
                    popup: false,
                    action: null,
                    model: {}
                },
                destroy_time: {
                    popup: false,
                    time: {},
                    type: null
                },
                view: {
                    type: 'week',
                    start: moment(start).format('DD-MM-YYYY'),
                    end: moment(end).format('DD-MM-YYYY')
                },
                filters: {
                    day: null,
                    only_me: false
                },
                settings: {
                    start: this.$Company().start,
                    end: this.$Company().end
                }
            }
        },
        methods: {
            getGraphic(){
                let dates = JSON.stringify(this.dates);
                let only_me = this.filters.only_me ? 'yes' : 'no';

                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/graphic?dates=' + dates + '&only_me=' + only_me).then(({data}) => {
                    this.users = data.users;
                    this.rooms = data.rooms;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(room) {
                let model = {
                    id: room.id,
                    name: room.name
                };

                this.toggleCrud('update', model);
            },
            destroy(room) {
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/room/' + room.id).then(({data}) => {
                    if (data.success) {
                        this.getGraphic();
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            sorting() {
                let ids = this.rooms.map((room) => {return room.id});
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/room-sorting', {ids: ids}).then(() => {
                    this.getGraphic();
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        name: null
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        model: {}
                    }
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        model: model
                    }
                }
            },
            updateTime(time) {
                let model = {
                    id: time.id,
                    user_id: time.user.id,
                    room_id: time.room.id,
                    day: moment(time.day, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    start: time.start,
                    end: time.end,
                    combo: time.combo
                };

                this.toggleTime('update', model);
            },
            destroyTime(time, type = 'single') {
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/graphic/' + time.id + '?type=' + type).then(({data}) => {
                    if (data.success) {
                        this.getGraphic();
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            singleTime(time, room_id, day){
                if (this.$HasPermission('change-graphic')){
                    this.time = {
                        popup: true,
                        action: 'create',
                        model: {
                            user_id: null,
                            room_id: room_id,
                            day: day.title,
                            start: time.start,
                            end: time.end
                        }
                    }
                }
            },
            toggleTime(action, model = {}) {
                if (action === 'create') {
                    model = {
                        user_id: null,
                        room_id: null,
                        day: this.dates[0],
                        start: null,
                        end: null
                    };
                }

                if (this.time.popup) {
                    this.time = {
                        popup: false,
                        action: null,
                        model: {}
                    }
                } else {
                    this.time = {
                        popup: true,
                        action: action,
                        model: model
                    }
                }
            },
            destroyTimePopup(time = null) {
                if (time) {
                    this.destroy_time = {
                        popup: true,
                        time: time,
                        type: 'current'
                    };
                } else {
                    this.destroy_time = {
                        popup: false,
                        time: {},
                        type: null
                    };
                }
            },
            today() {
                if (this.view.date !== moment().format('DD-MM-YYYY')) {
                    this.view = {
                        type: 'day',
                        date: moment().format('DD-MM-YYYY')
                    };
                    this.filters.day = null;
                    this.getGraphic();
                }
            },
            filtering() {
                if (this.filters.day !== null) {
                    if (this.view.type === 'day') {
                        this.view.date = this.filters.day;
                    } else {
                        let start = moment(this.filters.day, 'DD-MM-YYYY').isoWeekday(1).startOf('isoWeek');
                        let end = moment(this.filters.day, 'DD-MM-YYYY').isoWeekday(1).endOf('isoWeek');
                        this.view.start = moment(start).format('DD-MM-YYYY');
                        this.view.end = moment(end).format('DD-MM-YYYY');
                    }
                }
                this.getGraphic();
            },
            back() {
                if (this.view.type === 'day') {
                    this.view.date = moment(this.view.date, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
                    this.filters.day = this.view.date;
                } else {
                    this.view.start = moment(this.view.start, 'DD-MM-YYYY').subtract(7, 'days').format('DD-MM-YYYY');
                    this.view.end = moment(this.view.end, 'DD-MM-YYYY').subtract(7, 'days').format('DD-MM-YYYY');
                    this.filters.day = this.view.start;
                }
                this.getGraphic();
            },
            next() {
                if (this.view.type === 'day') {
                    this.view.date = moment(this.view.date, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
                    this.filters.day = this.view.date;
                } else {
                    this.view.start = moment(this.view.start, 'DD-MM-YYYY').add(7, 'days').format('DD-MM-YYYY');
                    this.view.end = moment(this.view.end, 'DD-MM-YYYY').add(7, 'days').format('DD-MM-YYYY');
                    this.filters.day = this.view.start;
                }
                this.getGraphic();
            },
            changeView(type) {
                if (this.view.type !== type) {
                    if (type === 'day') {
                        this.view = {
                            type: 'day',
                            date: moment().format('DD-MM-YYYY')
                        };
                    } else {
                        const start = moment().isoWeekday(1).startOf('isoWeek');
                        const end = moment().isoWeekday(1).endOf('isoWeek');

                        this.view = {
                            type: 'week',
                            start: moment(start).format('DD-MM-YYYY'),
                            end: moment(end).format('DD-MM-YYYY')
                        };
                    }
                    this.filters.day = null;
                    this.getGraphic();
                }
            },
            setSections(groups, sections = []) {
                if (groups.length){
                    for (let group of groups) {
                        if (!group.finished){

                            let groupSections = [];

                            groupSections.push({
                                times: [
                                    {
                                        start: this.settings.start,
                                        end: this.settings.end,
                                        type: 'empty',
                                        style: {
                                            width: '100%',
                                            background: 'none'
                                        }
                                    }
                                ]
                            });

                            for (let time of group) {
                                if (!time.added){
                                    groupSections.map((s) => {
                                        s.times.map((t, index) => {
                                            if (t.type === 'empty') {
                                                let a = this.$Interval(t.start, time.start);
                                                let b = this.$Interval(t.end, time.end);

                                                if (a >= 0 && b <= 0) {
                                                    let items = [];

                                                    if (t.start !== time.start) {
                                                        items.push({
                                                            start: t.start,
                                                            end: time.start,
                                                            type: 'empty',
                                                            style: {
                                                                width: (this.$Interval(t.start, time.start) * 100) / this.$Interval(this.settings.start, this.settings.end) + '%',
                                                                background: 'none'
                                                            }
                                                        });
                                                    }

                                                    if (time.start !== time.end) {
                                                        items.push({
                                                            id: time.id,
                                                            user: time.user,
                                                            room: time.room,
                                                            day: time.day,
                                                            start: time.start,
                                                            end: time.end,
                                                            combo: time.combo,
                                                            type: 'busy',
                                                            style: {
                                                                width: (this.$Interval(time.start, time.end) * 100) / this.$Interval(this.settings.start, this.settings.end) + '%',
                                                                background: this.company.pickers[time.user.role.name]
                                                            }
                                                        });
                                                    }

                                                    if (time.end !== t.end) {
                                                        items.push({
                                                            start: time.end,
                                                            end: t.end,
                                                            type: 'empty',
                                                            style: {
                                                                width: (this.$Interval(time.end, t.end) * 100) / this.$Interval(this.settings.start, this.settings.end) + '%',
                                                                background: 'none'
                                                            }
                                                        });
                                                    }

                                                    s.times.splice(index, 1, items);

                                                    s.times = this.$PasteArraysOfObject(s.times);

                                                    time.added = true;
                                                }
                                            }
                                        });
                                    });
                                }
                            }

                            sections = sections.concat(groupSections);

                            let missed = group.filter((time) => {
                                if (!time.added){
                                    return time;
                                }
                            });

                            if (missed.length === 0){
                                group.finished = true;
                            } else {
                                sections = this.setSections(groups, sections);
                            }
                        }
                    }
                }

                let empty = {
                    times: [
                        {
                            start: this.settings.start,
                            end: this.settings.end,
                            type: 'empty',
                            style: {
                                width: '100%',
                                background: 'none'
                            }
                        }
                    ]
                };

                if (sections.length === 0){
                    sections.push(empty, empty);
                } else if (sections.length < 3) {
                    let added = false;

                    sections.map(section => {
                        if (JSON.stringify(section) === JSON.stringify(empty)){
                            added = true;
                        }
                    });

                    if (!added){
                        sections.push(empty);
                    }
                }

                return sections;
            },
            getSections(sections){
                if (sections.length < this.sectionsCount){
                    for (let i = sections.length; i <= this.sectionsCount; i++){
                        sections.push({
                            times: [
                                {
                                    start: this.settings.start,
                                    end: this.settings.end,
                                    type: 'empty',
                                    style: {
                                        width: '100%',
                                        background: 'none'
                                    }
                                }
                            ]
                        });
                    }
                }

                return sections;
            },
            dayName(day){
                if (this.view.type === 'day'){
                    return moment(day, 'DD-MM-YYYY').format('dddd, DD MMMM YYYY');
                }
                return moment(day, 'DD-MM-YYYY').format('dd, DD-MM-YY');
            }
        },
        computed: {
            dates: function () {
                let dates = [];

                if (this.view.type === 'day') {
                    dates = [this.view.date];
                } else {
                    for (let i = 1; i <= 7; i++){
                        if (this.company.working_days.indexOf(i + 'nd') !== -1){
                            dates.push(moment(this.view.start, 'DD-MM-YYYY').add(i - 1, 'days').format('DD-MM-YYYY'));
                        }
                    }
                }

                return dates;
            },
            graphic: function () {
                let graphic = [];

                for (let day of this.dates) {
                    let rooms = [];

                    for (let room of this.rooms) {
                        let item = room.days.find(d => d.day === day);

                        if (item){
                            rooms.push({
                                id: room.id,
                                sections: this.setSections(Object.values(item.groups))
                            });
                        } else {
                            rooms.push({
                                sections: []
                            });
                        }
                    }

                    graphic.push({
                        title: day,
                        rooms: rooms
                    });
                }

                return graphic;
            },
            readonly: function () {
                return this.view.type === 'day' && this.company.working_days.indexOf(moment(this.view.date, 'DD-MM-YYYY').isoWeekday() + 'nd') === -1;
            },
            company: function () {
                return this.$Company();
            },
            sectionsCount: function () {
                let count = 1;

                this.graphic.map((day) => {
                    day.rooms.map((room) => {
                        if (room.sections.length > count) {
                            count = room.sections.length;
                        }
                    });
                });

                return count;
            },
            roomHeight: function () {
                return (this.sectionsCount * 40) + 'px';
            }
        }
    }
</script>
