<template>
    <div class="extra-salary">
        <div v-if="savable" class="extra-salary-form">
            <div :class="`form-field ${$HasError(errors, 'payroll_tax') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('payroll_tax') }}</div>
                <el-input-number
                        v-model="model.payroll_tax"
                        controls-position="right"
                        size="medium"
                        :min="0"
                ></el-input-number>
                <div v-if="$HasError(errors, 'payroll_tax')" class="field-error">
                    <span>{{ $GetError(errors, 'payroll_tax') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'pension_fund') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('pension_fund') }}</div>
                <el-input-number
                        v-model="model.pension_fund"
                        controls-position="right"
                        size="medium"
                        :min="0"
                ></el-input-number>
                <div v-if="$HasError(errors, 'pension_fund')" class="field-error">
                    <span>{{ $GetError(errors, 'pension_fund') }}</span>
                </div>
            </div>
            <el-popconfirm
                    icon-color="red"
                    icon="el-icon-info"
                    :cancel-button-text="$t('no')"
                    :confirm-button-text="$t('yes')"
                    :title="$t('do_you_want', {status: $t('save')})"
                    @confirm="save()"
            >
                <el-button slot="reference" size="medium" type="success" round>{{ $t('save') }}</el-button>
            </el-popconfirm>
        </div>
        <div v-else-if="payable" class="extra-salary-content">
            <div class="extra-salary-items">
                <div class="extra-salary-item">
                    <div>{{ $t('payroll_tax') }}</div>
                    <hr>
                    <div>{{ extra_salary.payroll_tax }}</div>
                </div>
                <div class="extra-salary-item">
                    <div>{{ $t('pension_fund') }}</div>
                    <hr>
                    <div>{{ extra_salary.pension_fund }}</div>
                </div>
            </div>
            <el-popconfirm
                    icon-color="red"
                    icon="el-icon-info"
                    :cancel-button-text="$t('no')"
                    :confirm-button-text="$t('yes')"
                    :title="$t('do_you_want', {status: $t('mark_as_paid')})"
                    @confirm="pay()"
            >
                <el-button slot="reference" size="medium" type="success" round>{{ $t('mark_as_paid') }}</el-button>
            </el-popconfirm>
        </div>
        <div v-else-if="paid" class="extra-salary-content">
            <div class="extra-salary-items">
                <div class="extra-salary-item">
                    <div>{{ $t('payroll_tax') }}</div>
                    <hr>
                    <div>{{ extra_salary.payroll_tax }}</div>
                </div>
                <div class="extra-salary-item">
                    <div>{{ $t('pension_fund') }}</div>
                    <hr>
                    <div>{{ extra_salary.pension_fund }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {roles} from "../../../../constants/roles";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        beforeMount() {
            this.getExtraSalary();
        },
        data(){
            return {
                extra_salary: null,
                model: {
                    payroll_tax: null,
                    pension_fund: null
                },
                errors: {}
            }
        },
        methods: {
            getExtraSalary(){
                let date = moment(this.$parent.date).format('YYYY-MM');
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/extra-salary?date=' + date).then(({data}) => {
                    this.extra_salary = data.extra_salary;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            save(){
                let data = {
                    date: moment(this.$parent.date).format('YYYY-MM'),
                    payroll_tax: this.model.payroll_tax,
                    pension_fund: this.model.pension_fund,
                    status: 'save'
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/extra-salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.errors = {};

                        this.getExtraSalary();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else if (data.errors){
                        this.errors = data.errors;
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            pay(){
                let data = {
                    date: moment(this.$parent.date).format('YYYY-MM'),
                    status: 'pay'
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/extra-salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.getExtraSalary();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            savable: function () {
                return this.$RoleIs(roles.accountant) && (this.extra_salary === null || this.extra_salary.status === 'saved');
            },
            payable: function () {
                return this.$RoleIs(roles.director) && this.extra_salary !== null && this.extra_salary.status === 'saved';
            },
            paid: function () {
                return this.extra_salary !== null && this.extra_salary.status === 'paid';
            }
        },
        watch: {
            extra_salary: {
                deep: true,
                immediate: true,
                handler: function (value) {
                    this.model = {
                        payroll_tax: value ? value.payroll_tax : null,
                        pension_fund: value ? value.pension_fund : null
                    };
                }
            }
        }
    }
</script>