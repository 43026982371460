<template>
    <div class="popup crud-popup evaluation-crud">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('evaluation.crud_title') }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="crud-form">
                    <div v-if="action === 'create'" :class="`form-field ${$HasError(errors, 'year') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('evaluation.year_label') }}</div>
                        <el-select
                                v-model="model.year"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="year in years"
                                    :key="'year-' + year.value"
                                    :label="year.label"
                                    :value="year.value"
                            ></el-option>
                        </el-select>
                        <div v-if="$HasError(errors, 'year')" class="field-error">
                            <span>{{ $GetError(errors, 'year') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'content') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('content') }}</div>
                        <el-input type="textarea" :rows="10" v-model="model.content"></el-input>
                        <div v-if="$HasError(errors, 'content')" class="field-error">
                            <span>{{ $GetError(errors, 'content') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t('save') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";
    import moment from "moment";

    export default {
        mixins: [helper],
        data() {
            return {
                model: this.$parent.evaluation_crud.model,
                errors: {}
            }
        },
        methods: {
            close(){
                this.$parent.toggleEvaluationCrud(this.action);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else {
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/evaluate', model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getEvaluations();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/evaluate/' + id, model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getEvaluations();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.evaluation_crud.action;
            },
            years: function () {
                let years = [];

                this.$parent.incidents.map(incident => {
                    let year = moment(incident.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');

                    if (years.findIndex(item => item.value === year) === -1){
                        years.push({
                            label: year,
                            value: year
                        });
                    }
                });

                return years;
            }
        }
    }
</script>