<template>
    <div class="popup crud-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t(action) }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="crud-form">
                    <div :class="`form-field ${$HasError(errors, 'name') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('name') }}</div>
                        <el-input size="medium" v-model="model.name"></el-input>
                        <div v-if="$HasError(errors, 'name')" class="field-error">
                            <span>{{ $GetError(errors, 'name') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data() {
            return {
                model: this.$parent.room_crud.model,
                errors: {}
            }
        },
        methods: {
            close(){
                this.$parent.toggleRoomCrud(this.action);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else {
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/checklist-room', model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getRooms();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/checklist-room/' + id, model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getRooms();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.room_crud.action;
            }
        }
    }
</script>