const nl = {
    // Greeting
    dawn : 'Goedemorgen',
    midday: 'Goedemiddag',
    evening: 'Goedenavond',
    noon: 'Goedenacht',
    get_started: 'Kies hieronder een onderdeel om te beginnen.',

    // Alerts
    unauthenticated: 'Niet geauthenticeerd.',
    not_found: 'Oops! Ververs de pagina a.u.b.',
    access_denied: 'Toegang geweigerd.',
    server_failure: 'Er is een server fout opgetreden.',

    // Empty
    no_companies: 'Geen organisaties',
    no_staff: 'Geen personeelsleden',
    no_categories: 'Geen categorieën',
    no_complaints: 'Geen klachten',
    no_free_days: 'Geen aanvragen',
    no_files: 'Geen bestanden',
    no_incidents: 'Geen incidenten',
    no_evaluations: 'Geen evaluaties',

    // Tooltips
    graphic_delete: {
        current: 'Verwijder het huidige item',
        full: 'Verwijder de huidige en toekomstige herhalingen van dit specifieke item',
        next: 'Verwijder alle toekomstige herhalingen van dit specifieke item'
    },

    // Popovers
    no: 'Nee',
    yes: 'Ja',
    delete_question: 'Weet je zeker dat je dit item wilt verwijderen?',
    delete_question_user: 'Weet je zeker dat je deze gebruiker wilt verwijderen?',
    remove_user: 'Gebruiker verwijderen',
    are_you_sure: 'Weet je zeker?',
    do_you_want: 'Wil je {status}?',
    send_email_question: 'Wil je een e-mail sturen naar {email}?',

    // Counts
    counts: {
        manager: 'Praktijkmanagers',
        accountant: 'Boekhouders',
        dentist: 'Tandartsen',
        assistent: 'Assistentes',
        hygienist: 'Mondhygiënisten'
    },

    // Roles
    male: {
        admin: 'Admin',
        director: 'Directeur',
        manager: 'Praktijkmanager',
        accountant: 'Boekhouder',
        dentist: 'Tandarts',
        assistent: 'Assistent',
        hygienist: 'Mondhygiënist'
    },
    female: {
        admin: 'Admin',
        director: 'Directrice',
        manager: 'Praktijkmanager',
        accountant: 'Boekhoudster',
        dentist: 'Tandarts',
        assistent: 'Assistente',
        hygienist: 'Hygieniste'
    },

    // Permissions
    'view-graphic': 'Personeelsrooster tonen',
    'change-graphic': 'Personeelsrooster inplannen',
    'crud-room': 'Personeelsrooster kamers aanpassen',
    'view-notes': 'Mededelingen tonen',
    'crud-notes': 'Mededelingen aanpassen',
    'view-categories': 'Protocol categorieën tonen',
    'crud-categories': 'Protocol categorieën aanpassen',
    'view-protocols': 'Protocollen tonen',
    'crud-protocols': 'Protocollen aanpassen',
    'manage-checklists': 'Checklists beheren',

    // Header
    my_profile: 'Mijn profiel',
    logout: 'Uitloggen',

    // Sidebar
    companies: 'Organisaties',
    company: 'Organisatie',
    settings: 'Instellingen',
    staff: 'Personeel',
    functional_interviews: 'Functioneringsgesprekken',
    graphic: 'Personeelsrooster',
    notes: 'Mededelingen',
    categories: 'Categorieën',
    protocols: 'Protocollen',
    log_hours: 'Uren loggen',
    free_days: 'Verlof, ziek en vakantie',
    salary: 'Salarisadministratie',
    incidents_and_complaints: 'Incidenten en klachten',
    safe_reporting_incident: 'Veilig melden incident (VMI)',
    complaints: 'Klachten',
    agenda: 'Agenda',

    // Incident
    incident: {
        label: 'Incident',
        tab: 'Gemelde incidenten',
        back: 'Terug naar Gemelde incidenten',
        year_label: 'Toon het jaar',
        all_years: 'Alle jaren',
        create_button: 'Incident melden',
        fields: {
            room: 'Ruimte',
            reason: 'Toedracht en mogelijke oorzaken van incident',
            category: 'Selecteer een categorie',
            facts_and_possible_causes: 'Toedracht en mogelijke oorzaken van incident',
            implications: 'Gevolgen voor patiënt/medewerker',
            classification: 'Classificatie ernst',
            short_description: 'Korte omschrijving van incident',
            measurement: 'Maatregel die is genomen om herhaling te voorkomen',
            user_id: 'Naam melder (mag ook anoniem)'
        },
        columns: {
            date: 'Datum',
            timestamp: 'Tijdstip',
            room: 'Ruimte',
            reason: 'Toedracht en mogelijke oorzaken van incident',
            category: 'Categorie',
            facts_and_possible_causes: 'Toedracht en mogelijke oorzaken van incident',
            implications: 'Gevolgen voor patiënt/medewerker',
            classification: 'Classificatie ernst',
            short_description: 'Korte omschrijving van incident',
            measurement: 'Maatregel die is genomen om herhaling te voorkomen',
            user_id: 'Naam melder'
        },
        categories: {
            treatments: 'Behandelingen',
            prick_accident: 'Prikaccident',
            hygiene: 'Hygiene',
            file: 'Dossier',
            prescriptions: 'Recepten',
            medical_history: 'Medische anamnese',
            x_ray: 'Röntgen',
            appointments: 'Afspraken',
            other: 'Overige'
        },
        classifications: {
            mild: 'Licht (minst ernstig)',
            moderate: 'Matig (tamelijk ernstig)',
            severe: 'Ernstig',
            very_serious: 'Zeer ernstig',
            fatal: 'Fataal (meest ernstig)'
        },
        anonymous: 'Anoniem',
        submit: 'Melding opslaan'
    },

    // Interview
    interview: {
        empty: 'Geen functioneringsgesprekken',
        back: 'Terug naar Functioneringsgesprekken',
        all_years: 'Alle jaren',
        year_label: 'Toon het jaar',
        create_button: 'Functioneringsgesprek voeren',
        until: 'tot',
        share_question: 'Weet je zeker dat je dit functioneringsgesprek wilt delen met de werknemer?',
        job_titles: {
            dentist: 'Tandarts',
            dental_assistant: 'Tandartsassistente',
            dental_hygienist: 'Mondhygieniste',
            prevention_assistant: 'Preventieassistente',
            office_manager: 'Office manager',
            clinic_manager: 'Praktijkmanager',
            dental_technician: 'Tandtechnicus',
            prosthodontist: 'Tandprotheticus'
        },
        group_labels: {
            your_performance: 'Je functioneren',
            future: 'Toekomst'
        },
        fields: {
            user_id: 'Naam medewerker',
            job_title: 'Functie',
            range: 'Tijdvak waarover het gesprek gaat',
            date: 'Datum functioneringsgesprek',
            performance_1: 'Wat vind je van je werk en waarom wil je bij {company} blijven werken?',
            performance_2: 'Hoe verloopt de samenwerking tussen jou en je collega’s?',
            performance_3: 'Over welke zaken ben je tevreden, wat vind je goed gaan?',
            performance_4: 'Waar ben je minder tevreden over, waar kun je nog beter in worden? Hoe kan dat worden verbeterd?',
            future: 'Waar zie je jezelf over een aantal jaren? Waar zie je jezelf in de toekomst?',
            before: 'Vorige afspraken & verbeterpunten',
            after: 'Verbeterpunten en afspraken voor de komende periode'
        },
        actions: {
            save: 'Opslaan',
            save_and_share: 'Opslaan en delen',
            revoke_share: 'Delen intrekken'
        },
        columns: {
            user_id: 'Naam medewerker',
            job_title: 'Functie',
            date: 'Datum functioneringsgesprek',
            range: 'Tijdvak waarover het gesprek gaat'
        }
    },

    // Evaluation
    evaluation: {
        label: 'Evaluatie',
        created_by: 'Gemaakt door',
        tab: 'Evaluaties',
        create_button: 'Nieuwe evaluatie maken',
        crud_title: 'Evalueer gemelde incidenten',
        year_label: 'Evaluatie over het jaar'
    },

    // Checklist
    checklist: 'Checklist',
    checklist_areas: {
        room: {
            title: 'Kamers',
            empty: 'Geen kamers',
            are_you_sure: 'Door een kamer te verwijderen worden ook de herhalingen, de onderliggende taken en de rapportages ervan gewist. Wil je doorgaan?'
        },
        type: {
            title: 'Herhaal de takenlijst',
            empty: 'No frequenties for {room}',
            are_you_sure: 'Door een herhaling te verwijderen worden ook de onderliggende taken en de rapportages ervan gewist. Wil je doorgaan?'
        },
        field: {
            title: '{type}e taken in {room}',
            empty: 'No fields for {type}'
        }
    },

    checklist_overview: {
        title: 'Aftekenlijst',
        description: 'Kies een ruimte om af te tekenen',
        back: 'Terug naar Overzicht',
        empty: 'Geen checklist om te tonen.'
    },
    checklist_submit: {
        title: '{type}e checklist van {room} van {date}',
        step_one: 'Stap 1 van 2: Vink de uitgevoerde taak af',
        step_two: 'Stap 2 van 2: Onderteken en verstuur',
        select_all: 'Selecteer alles',
        unselect_all: 'Deselecteer alles',
        agree: 'Ik, {full_name} heb de hierboven genoemde taken uitgevoerd op {date}'
    },
    checklist_history: {
        title: 'Rapportage',
        description: 'Checklist rapportage',
        empty: 'Er zijn geen aftekenlijsten gevonden die voldoen aan de zoekcriteria.',
        go_to_current_month: 'Ga naar huidige maand'
    },

    frequency: 'Frequentie',
    frequency_steps: {
        frequency: 'Step 1 of 2: Create frequency',
        fields: 'Step 2 of 2: Create fields for frequency'
    },
    frequency_types: {
        daily: 'Dagelijks',
        weekly: 'Wekelijks',
        two_weekly: 'Tweewekelijks',
        monthly: 'Maandelijks',
        two_monthly: 'Tweemaandelijks',
        half_yearly: 'Halfjaarlijks',
        yearly: 'Jaarlijks'
    },
    frequency_separator_one: 'op',
    frequency_separator_two: 'startend vanaf',
    frequency_label: 'Toon de checklist:',
    frequency_daily_description: 'elke werkdag',

    // Week names
    week_names: {
        mo: 'ma',
        tu: 'di',
        we: 'wo',
        th: 'do',
        fr: 'vr',
        sa: 'za',
        su: 'zo'
    },

    // Auth
    login: {
        title: 'Inloggen bij Celder',
        submit: 'Inloggen',
        link: 'Wachtwoord vergeten'
    },
    create_password: {
        title: 'Wachtwoord aanmaken',
        submit: 'Activeren',
        link: 'Terug naar Inloggen'
    },
    forgot_password: {
        title: 'Wachtwoord vergeten',
        submit: 'Bevestigen',
        link: 'Terug naar Inloggen'
    },
    reset_password: {
        title: 'Wachtwoord resetten',
        submit: 'Bevestigen',
        link: 'Terug naar Inloggen'
    },

    // Choose by
    choose_by: {
        rooms: 'Kies door kamers',
        users: 'Kies door gebruikers',
        types: 'Kies door types',
        statuses: 'Kies door statussen'
    },

    // Others -> Not found
    not_found_title: 'Oops! Er is niets gevonden.',
    not_found_description: 'De pagina waar je op zoek naar bent is verwijderd, aangepast of tijdelijk niet beschikbaar.',
    return_to_back: 'Terug',

    // Notifications
    notifications: {
        published_new_note: {
            title: 'Nieuwe mededeling is gepubliceerd'
        },
        log_hours_declined: {
            title: 'Je uren zijn afgekeurd'
        },
        complaint: {
            title: 'Nieuwe klacht ingediend'
        },
        complaint_reaction: {
            title: 'Er is een reactie geplaatst op de klacht van de patiënt {patient}'
        },
        assigned_complaint: {
            title: 'Er is een klacht toegewezen aan jou'
        },
        unassigned_complaint: {
            title: 'Je bent niet langer toegewezen voor de klacht “{topic}”'
        },
        free_day_request: {
            title: 'Er is een nieuwe {type} aanvraag ingediend'
        },
        free_day_accepted: {
            title: 'Je {type} uren zijn goedgekeurd!'
        },
        free_day_rejected: {
            title: 'Je {type} uren zijn afgekeurd'
        },
        log_hours_passed: {
            title: '{user} heeft zojuist de uren van {month} aangeleverd'
        },
        functional_interview: {
            title: 'De notulen van je functioneringsgesprek zijn beschikbaar'
        },
        birthday: {
            title: 'Hieperdepiep! 🎉 {anniversary_name} is vandaag jarig! {gender} is {years} geworden.'
        },
        birthday_reminder: {
            title: 'Whoop whoop 🎉 {anniversary_name} is bijna jarig! Volgende week {week_day} wordt {gender} {years}.'
        }
    },

    // Week days
    week_days: {
        '1nd': 'Maandag',
        '2nd': 'Dinsdag',
        '3nd': 'Woensdag',
        '4nd': 'Donderdag',
        '5nd': 'Vrijdag',
        '6nd': 'Zaterdag',
        '7nd': 'Zondag'
    },

    // Repeat
    repeat: 'Herhaal',
    repeat_types: {
        every: 'Herhaal elke {day}',
        workday: 'Elke werkdag'
    },
    repeat_actions: {
        date: 'Selecteer de einddatum',
        count: 'Stop na X keer'
    },
    tooltip_on_updating_repeat: 'Het volgende item is een onderdeel van een herhaling. Aanpassen van dit item zal deze ontkoppelen van de herhaling.',

    // Uploader
    uploader: {
        update_files: 'Update files',
        error: 'File type not supported, please select one of the following files to upload: PDF, WORD, EXCEL, PNG, JPEG, JPG, BMP, HTML.',
        title: 'Sleep één of meerdere bestanden hiernaartoe of klik hier om bestand(en) te selecteren.',
        category_placeholder: 'Selecteer een map',
        uploaded_files: 'Geüploade bestanden',
        categories: {
            'other': 'Overige',
            'hepatitis-b-statement': 'Hepatitis B verklaring',
            'diplomas-and-certificates': 'Diploma\'s en certificaten',
            'personal-files': 'Persoonlijke documenten'
        }
    },

    // Free day types
    free_day_types: {
        absence: 'verlof',
        sick: 'ziekte',
        vacation: 'vakantie'
    },

    // Duplicates
    free_hours: {
        label: 'Aantal vakantie-uren',
        sick_checkbox: 'Maak gebruik van beschikbare vakantie-uren',
        available: 'Beschikbare vakantiedagen of -uren: {days} dagen ({hours} uur)'
    },
    sick_unused: 'Ziek - Geen vakantieuren opgeomen',
    sick_used: 'Ziek - Vakantieuren opgenomen',
    new: 'New',
    email: 'E-mail',
    password: 'Wachtwoord',
    create: 'Aanmaken',
    share: 'Protocollink kopieren',
    copy: 'Kopië',
    copy_url: 'Kopië URL',
    copied_to_clipboard: 'De protocollink is gekopieërd naar klembord.',
    protocol_categories: 'Protocol categories',
    close: 'Sluiten',
    name: 'Naam',
    pickers: 'Kleurkiezer',
    surname: 'Achternaam',
    gender: 'Aanhef',
    genders: {
        male: 'meneer',
        female: 'mevrouw'
    },
    genders_basic: {
        male: 'Hij',
        female: 'Zij'
    },
    address: 'Adres',
    bsn_number: 'BSN-nummer',
    birthday: 'Geboortedatum',
    made_on: 'Gemaakt op',
    created_at: 'Aangemaakt',
    updated_at: 'Aangepast op',
    verified_at: 'Geactiveerd',
    submitted_at: 'Submitted At',
    actions: 'Acties',
    update: 'Aanpassen',
    role: 'Rol',
    permissions: 'Rechten',
    role_and_permissions: 'Rol en rechten',
    room: 'Kamer',
    delete: 'Verwijderen',
    section: 'Sectie',
    save: 'Opslaan',
    date: 'Datum',
    roles: 'Rollen',
    filters: 'Filters',
    rooms: 'Kamers',
    users: 'Gebruikers',
    only_me: 'Toon alleen mijzelf',
    user: 'Gebruiker',
    color: 'Kleur',
    day: 'Dag',
    week: 'Week',
    month: 'Maand',
    today: 'vandaag',
    start: 'Starttijd',
    end: 'Eindtijd',
    time: 'Tijd',
    password_confirmation: 'Bevestig het wachtwoord',
    activate: 'Activeren',
    title: 'Titel',
    content: 'Inhoud',
    opening_hours: 'Openingstijden',
    working_days: 'Werkdagen',
    view_overtime: 'Toon overuren',
    description: 'Omschrijving',
    event: 'Gebeurtenis',
    start_date: 'Startdatum',
    end_date: 'Einddatum',
    start_time: 'Starttijd',
    end_time: 'Eindtijd',
    seers: 'Gezien door',
    show_more: 'Lees meer',
    read: 'Gezien',
    unread: 'Niet gezien',
    back_to_notes: 'Terug naar Mededelingen',
    parent: 'Zet in de map',
    back_to_protocols: 'Terug naar Protocollen',
    back_to_first_category: 'Terug naar Alle categorieën',
    most_read: 'Meest gelezen',
    browse_by_category: 'Blader per categorie',
    subcategories: 'Subcategorieën',
    items: 'Items',
    category: 'Categorie',
    profile: 'Profiel',
    logged_hours: 'Gelogde uren',
    no_logged_hours: 'Geen gelogde uren',
    choose_the_employee: 'Kies de collega',
    hour_type: 'Uur type',
    normal: 'Normale uren',
    over: 'Overuren',
    pause: 'Pauze',
    pause_in_minutes: 'Pauze (in minuten)',
    total: 'Totaal',
    salary_total: 'Doorgeven aan boekhouder',
    cancel: 'Annuleren',
    pass_title: 'Doorgeven {days} {month}',
    pass_this_week: 'Deze week doorgeven',
    can_not_change_passed_hours: 'Na doorgeven kun je de uren niet meer wijzigen, tenzij deze afgekeurd worden.',
    match_calendar: ' / ',
    absence: 'Verlof',
    sick: 'Ziek',
    vacation: 'Vakantie',
    salary_amount: 'Salaris',
    amount: 'Bedrag',
    specification: 'Specificatie',
    status: 'Status',
    reject: 'Afkeuren',
    reason: 'Reden',
    submit: 'Versturen',
    overview: 'Overview',
    approve: 'Goedkeuren',
    reject_title: 'Keur de uren af van {user} van de maand {month}',
    edit_title: 'Pas de salaris aan van {user} van de maand {month}',
    accept: 'Accepteren',
    decline: 'Afkeuren',
    change: 'Aanpassen',
    choose: 'Kiezen',
    upload: 'Uploaden',
    edit: 'Wijzigen',
    uploaded: 'Geüpload',
    pay: 'Betalen',
    paid: 'Betaald',
    not_passed: 'Niet doorgegeven',
    code: 'Code',
    approved: 'Goedgekeurd',
    accepted: 'Geaccepteerd',
    assign_to_employee: 'Toewijzen aan de collega',
    patient_name: 'Patiëntnaam',
    phone: 'Telefoonnummer',
    topic: 'Onderwerp',
    treatment_at: 'Behandeldatum',
    opened: 'Open',
    closed: 'Gesloten',
    assigned: 'Toegewezen',
    search_complaint: 'Zoek de klacht per code',
    react: 'Reageren',
    is_patient: 'Reactie door {name}.',
    send_to_email: 'Stuur een bericht naar {email}․',
    messages: 'Berichten',
    company_code: 'Organisatie code',
    your_name: 'Jouw naam',
    your_email: 'Jouw e-mailadres',
    your_phone: 'Jouw telefoonnummer',
    current_password: 'Huidige wachtwoord',
    change_password: 'Pas het wachtwoord aan',
    password_placeholder: '(kies een sterk wachtwoord met minstens 8 karakters.)',
    postal_code: 'Postcode',
    place: 'Plaats',
    mark_all_as_read: 'Markeer alles als gelezen',
    empty_notifications: 'Je hebt geen nieuwe notificaties',
    publish: 'Publiceren',
    type: 'Type',
    pending: 'In afwachting',
    rejected: 'Afgekeurd',
    request: 'Aanvraag',
    send: 'Versturen',
    at: 'om',
    to: 't/m',
    of: '',
    inclusive: 'tot',
    select_end_date: 'Selecteer de einddatum',
    hour: 'uur',
    hours: 'Uren',
    used_hours: 'Gebruikte uren',
    free_day_hours: 'Aantal uur',
    free_day_notes: 'Opmerkingen',
    check: 'Controleren',
    files: 'Bestanden',
    file: 'Bestand',
    history: 'Geschidenis',
    payroll_tax: 'Loonheffing',
    pension_fund: 'Pensioenfonds',
    mark_as_paid: 'Markeer als betaald',
    set_start_and_end: 'Begin en einduren instellen',
    set: 'Instellen',
    whole_day: 'Hele dag',
    back: 'Back',
    next: 'Next',
    new_request: 'Nieuwe aanvraag',
    reporting_sick: 'Ziek melden',
    request_type: 'Soort aanvraag',
    select_the_days: 'Selecteer de dagen',
    call_in_sick_now: 'Nu ziekmelden',
    send_request: 'Aanvraag versturen',
    send_reporting_sick: 'Ziekmelding versturen',
    back_to_request_type: 'Terug naar soort aanvraag',
    files_and_folders: 'Bestanden en mappen',
    select_the_date_range: 'Selecteer het datumbereik',
    total_hours: 'Totaal aantal uren',
    requested_hours: 'Gewenste uren',
    summary: 'Samenvatting',
    edit_mode: "Bewerkmodus",
    fields: 'Fields'
};

export default nl;