<template>
    <section class="checklist-history-section default-section">
        <div class="section-title">
            <h1>{{ $t('checklist_history.description') }}</h1>
        </div>
        <div class="history-filters">
            <div class="history-filter">
                <el-button @click="goToCurrentMonth()" type="primary" size="small">{{ $t('checklist_history.go_to_current_month') }}</el-button>
            </div>
            <div class="history-filter filter-calendar">
                <el-button @click="back()" type="primary" size="small" icon="el-icon-arrow-left" circle></el-button>
                <p>{{ date.format('MMMM YYYY') }}</p>
                <el-button @click="next()" type="primary" size="small" icon="el-icon-arrow-right" circle></el-button>
            </div>
            <div class="history-filter">
                <el-select
                        v-model="filters.rooms"
                        size="small"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        :placeholder="$t('choose_by.rooms')">
                    <el-option
                            v-for="room in rooms"
                            :key="'filter-room-' + room.id"
                            :label="room.name"
                            :value="room.id"
                    ></el-option>
                </el-select>
            </div>
            <div class="history-filter">
                <el-select
                        v-model="filters.users"
                        size="small"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        :placeholder="$t('choose_by.users')">
                    <el-option
                            class="option-with-image"
                            v-for="user in users"
                            :key="'filter-user-' + user.id"
                            :label="$FullName(user)"
                            :value="user.id">
                        <img :src="$Avatar(user)" alt="">
                        <span>{{ $FullName(user) }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="history-content scroll-grey">
            <div class="history-left">
                <div></div>
                <div v-for="(row, key) in rows" :key="'history-left-room-' + key">{{ row.room.name }}</div>
            </div>
            <div class="history-right">
                <div :class="`history-days fractions-${days.length}`">
                    <div v-for="(day, key) in days" :key="'history-day-' + key">{{ day.format('D') }}</div>
                </div>
                <div v-for="(row, key) in rows" :key="'history-room-' + key" :class="`history-room fractions-${row.cubes.length}`">
                    <el-popover
                            v-for="(cube, key) in row.cubes" :key="'room-' + row.room.id + '-cube-' + key"
                            :disabled="!cube.tasks.length"
                            popper-class="cube-popover"
                            placement="bottom"
                            trigger="hover">
                        <template>
                            <slot name="content">
                                <p>{{ cube.day }}</p>
                                <div class="tasks">
                                    <div v-for="(task, key) in cube.tasks" :key="'task-' + key" class="task">
                                        <p>{{ typeName(task.type) }}</p>
                                        <div v-for="(field, key) in task.fields" :key="'task-field-' + key" class="task-field">
                                            <span>{{ field.name }}</span>
                                            <div class="task-field-status">
                                                <img v-if="field.checked" src="../../../../images/done.svg" alt="done">
                                                <img v-else src="../../../../images/undone.svg" class="undone" alt="undone">
                                            </div>
                                        </div>
                                        <div v-if="task.user" class="submitted-time">{{ task.submitted_at }} - {{ $ShortName(task.user) }}</div>
                                        <hr v-if="key < (cube.tasks.length - 1)">
                                    </div>
                                </div>
                            </slot>
                            <div v-if="cube.tasks.length" :class="`history-room-cube ${cube.done ? 'done' : 'undone'}`" slot="reference">
                                <img v-if="cube.done" src="../../../../images/done.svg" alt="done">
                                <img v-else src="../../../../images/undone.svg" class="undone" alt="undone">
                            </div>
                            <div v-else class="history-room-cube" slot="reference"></div>
                        </template>
                    </el-popover>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import moment from "moment";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";

    export default {
        mixins: [helper],
        beforeMount() {
            this.getHistory();
        },
        data(){
            return {
                date: moment().startOf('month'),
                rooms: [],
                users: [],
                submits: [],
                filters: {
                    rooms: [],
                    users: []
                }
            }
        },
        methods: {
            getHistory(){
                let date = moment(this.date).format('YYYY-MM');
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/checklist-history?date=' + date).then(({data}) => {
                    this.rooms = data.rooms;
                    this.users = data.users;
                    this.submits = data.submits;
                    this.filters = {
                        rooms: [],
                        users: []
                    };
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            goToCurrentMonth(){
                this.date = moment().startOf('month');
                this.getHistory();
            },
            typeName(type){
                if (['weekly', 'two_weekly'].includes(type.name)){
                    return this.$t('frequency_types.' + type.name) + ' - ' + this.$t('week_days.' + type.value);
                }
                return this.$t('frequency_types.' + type.name);
            },
            back(){
                this.date = moment(this.date.subtract(1, 'months'));
                this.getHistory();
            },
            next(){
                this.date = moment(this.date.add(1, 'months'));
                this.getHistory();
            }
        },
        computed: {
            days: function () {
                let days = [];
                let end = +moment(this.date).endOf('month').format('D');

                for (let i = 0; i < end; i++){
                    days.push(moment(this.date).add(i, 'days'));
                }

                return days;
            },
            rows: function () {
                let rows = [];

                let rooms = this.rooms.filter((room) => {
                    if (this.filters.rooms.length){
                        return this.filters.rooms.includes(room.id);
                    }
                    return true;
                });

                rooms.map((room) => {
                    let row = {
                        room: room,
                        cubes: []
                    };

                    this.days.map((day) => {
                        let cube = {
                            day: day.format('dd, D MMM YYYY'),
                            tasks: []
                        };

                        this.submits.map(submit => {
                            if (submit.generated_at === day.format('YYYY-MM-DD')) {
                                if (submit.user_id) {
                                    let task = {
                                        fields: [],
                                        type: submit.type,
                                        user: submit.user,
                                        submitted_at: null
                                    };

                                    submit.type.fields.map(field => {
                                        task.fields.push({
                                            name: field.name,
                                            checked: submit.fields.includes(field.id)
                                        });
                                    });

                                    let submitted_at = moment(submit.submitted_at, 'YYYY-MM-DD HH:mm:ss');

                                    if (submitted_at.format('YYYY-MM-DD') !== day.format('YYYY-MM-DD')){
                                        task.submitted_at = submitted_at.format('HH:mm (D MMM YYYY)');
                                    } else {
                                        task.submitted_at = submitted_at.format('HH:mm');
                                    }

                                    let append = submit.room.id === room.id;

                                    if (append && this.filters.users.length){
                                        append = this.filters.users.includes(submit.user_id);
                                    }

                                    if (append){
                                        cube.tasks.push(task);
                                    }
                                } else {
                                    let task = {
                                        fields: [],
                                        type: submit.type
                                    };

                                    submit.type.fields.map(field => {
                                        task.fields.push({
                                            name: field.name,
                                            checked: false
                                        });
                                    });

                                    let append = submit.room.id === room.id;

                                    if (append){
                                        cube.tasks.push(task);
                                    }
                                }
                            }
                        });

                        if (cube.tasks.length){
                            cube.done = true;
                            cube.tasks.map((task) => {
                                if (task.fields.find(f => !f.checked)){
                                    cube.done = false;
                                }
                            });
                        }

                        row.cubes.push(cube);
                    });

                    rows.push(row);
                });

                return rows;
            }
        }
    }
</script>