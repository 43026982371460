<template>
    <section>
        <staff v-if="screen === 'staff'"></staff>
        <functional-interview v-if="screen === 'functional-interviews'"></functional-interview>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import Staff from "../director/staff/Staff";
    import FunctionalInterview from "../functional-interview/FunctionalInterview";

    export default {
        mixins: [helper],
        components: {Staff, FunctionalInterview},
        beforeMount() {
            this.check();
        },
        methods: {
            check(){
                if (this.screens.indexOf(this.screen) === -1){
                    this.$router.push({name: 'staff', params: {prefix: this.$RoleIs('director') ? 'staff' : 'functional-interviews'}});
                }
            }
        },
        computed: {
            screen: function () {
                return this.$route.params.prefix;
            },
            screens: function () {
                let screens = {
                    'staff': this.$RoleIs('director'),
                    'functional-interviews': true
                };

                Object.keys(screens).map((screen) => {
                    if (!screens[screen]){
                        delete screens[screen];
                    }
                });

                return Object.keys(screens);
            }
        },
        watch: {
            $route(){
                this.check();
            }
        }
    }
</script>