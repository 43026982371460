<template>
    <div class="popup chat-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ complaint.patient }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="chat-info" v-if="complaint.messages.length">
                    <p>{{ $t('messages') }}</p>
                    <div class="chat-messages scroll-grey">
                        <div v-for="(message, key) in complaint.messages" :key="'chat-message-' + key" :class="chat(message, 'class')">
                            <div class="message-left">
                                <img class="message-image" :src="chat(message, 'image')" alt="">
                            </div>
                            <div class="message-right">
                                <div class="message-content" v-html="message.content"></div>
                                <div class="message-date">
                                    <p>{{ $Date(message.date, 'DD-MM-YYYY H:m') }}</p>
                                    <span v-if="message['sent_to_email']" class="el-icon-position"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat-form" v-if="actionable">
                    <div :class="`form-field ${$HasError(errors, 'content') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('content') }}</div>
                        <vue-editor
                                :editorOptions="editorOptions"
                                @image-added="$Upload"
                                useCustomImageHandler
                                v-model="model.content"
                        ></vue-editor>
                        <div v-if="$HasError(errors, 'content')" class="field-error">
                            <span>{{ $GetError(errors, 'content') }}</span>
                        </div>
                    </div>
                    <div class="form-field chat-actions">
                        <el-checkbox v-model="model.is_patient">{{ $t('is_patient', {name: complaint.patient}) }}</el-checkbox>
                        <el-checkbox v-model="model.send_to_email" v-if="complaint.email">{{ $t('send_to_email', {email: complaint.email}) }}</el-checkbox>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>

                <el-popconfirm
                        v-if="actionable && model.send_to_email"
                        icon-color="red"
                        icon="el-icon-info"
                        :cancel-button-text="$t('no')"
                        :confirm-button-text="$t('yes')"
                        :title="$t('send_email_question', {email: complaint.email})"
                        @confirm="react()"
                >
                    <el-button slot="reference" size="medium" type="success" round>{{ $t('react') }}</el-button>
                </el-popconfirm>

                <el-button v-if="actionable && !model.send_to_email" @click="react()" size="medium" type="success" round>{{ $t('react') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import { VueEditor } from "vue2-editor";
    import $ from 'jquery';
    import {roles} from "../../../../../constants/roles";

    export default {
        mixins: [helper],
        components: {VueEditor},
        mounted() {
            this.toBottom();
        },
        data() {
            return {
                editorOptions: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                complaint: this.$parent.chat.complaint,
                model: this.$parent.chat.model,
                errors: {}
            }
        },
        methods: {
            close(){
                this.$parent.toggleChat(this.complaint);
            },
            react(){
                let data = {
                    complaint_id: this.complaint.id,
                    content: this.model.content,
                    is_patient: this.model.is_patient,
                    send_to_email: this.model.send_to_email
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/complaint-message', data).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.complaint = data['complaint'];

                        this.model = {
                            content: null,
                            is_patient: false,
                            send_to_email: false
                        };

                        this.$parent.getComplaints();

                        this.toBottom();
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toBottom() {
                let chat_messages = $(".chat-messages");

                if (chat_messages.length){
                    chat_messages.animate({ scrollTop: chat_messages.prop("scrollHeight")}, 1000);
                }
            },
            chat(message, field){
                if (field === 'class'){
                    return 'chat-message ' + (message.user_id === this.$User().id ? 'to-right' : 'to-left');
                }

                return message.user && message.user.avatar ? message.user.avatar : require('../../../../../images/male.png');
            }
        },
        computed: {
            actionable: function () {
                return this.$RoleIs(roles.director) || this.$RoleIs(roles.manager) || this.complaint.user_id === this.$User().id;
            }
        }
    }
</script>