<template>
    <section class="company-section default-section">
        <div class="section-title"></div>

        <div class="company-form">
            <div :class="`form-field ${$HasError(errors, 'name') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('name') }} </div>
                <el-input size="medium" v-model="company.name"></el-input>
                <div v-if="$HasError(errors, 'name')" class="field-error">
                    <span>{{ $GetError(errors, 'name') }}</span>
                </div>
            </div>
            <div class="form-field">
                <div class="field-label">{{ $t('pickers') }} </div>
                <div :class="`picker-item ${$HasError(errors, 'director') ? 'invalid-picker' : ''}`" >
                    <el-color-picker v-model="company.pickers.director" size="small"></el-color-picker>
                    <div class="picker-role">{{ $t('male.director') }}</div>
                </div>
                <div :class="`picker-item ${$HasError(errors, 'manager') ? 'invalid-picker' : ''}`" >
                    <el-color-picker v-model="company.pickers.manager" size="small"></el-color-picker>
                    <div class="picker-role">{{ $t('male.manager') }}</div>
                </div>
                <div :class="`picker-item ${$HasError(errors, 'accountant') ? 'invalid-picker' : ''}`" >
                    <el-color-picker v-model="company.pickers.accountant" size="small"></el-color-picker>
                    <div class="picker-role">{{ $t('male.accountant') }}</div>
                </div>
                <div :class="`picker-item ${$HasError(errors, 'dentist') ? 'invalid-picker' : ''}`" >
                    <el-color-picker v-model="company.pickers.dentist" size="small"></el-color-picker>
                    <div class="picker-role">{{ $t('male.dentist') }}</div>
                </div>
                <div :class="`picker-item ${$HasError(errors, 'assistent') ? 'invalid-picker' : ''}`" >
                    <el-color-picker v-model="company.pickers.assistent" size="small"></el-color-picker>
                    <div class="picker-role">{{ $t('male.assistent') }}</div>
                </div>
                <div :class="`picker-item ${$HasError(errors, 'hygienist') ? 'invalid-picker' : ''}`" >
                    <el-color-picker v-model="company.pickers.hygienist" size="small"></el-color-picker>
                    <div class="picker-role">{{ $t('male.hygienist') }}</div>
                </div>
            </div>
            <div class="form-section">
                <div class="form-section-label">
                    <p>{{ $t('opening_hours') }}</p>
                </div>
                <div class="form-section-content">
                    <div class="start-and-end">
                        <div :class="`form-field ${$HasError(errors, 'start') ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('start') }} </div>
                            <el-time-select
                                    v-model="company.start"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:05',
                                        end: '23:55'
                                    }"
                                    placeholder="--:--">
                            </el-time-select>
                            <div v-if="$HasError(errors, 'start')" class="field-error">
                                <span>{{ $GetError(errors, 'start') }}</span>
                            </div>
                        </div>
                        <div :class="`form-field ${$HasError(errors, 'end') ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('end') }} </div>
                            <el-time-select
                                    v-model="company.end"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:05',
                                        end: '23:55'
                                    }"
                                    placeholder="--:--">
                            </el-time-select>
                            <div v-if="$HasError(errors, 'end')" class="field-error">
                                <span>{{ $GetError(errors, 'end') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <div class="form-section-label">
                    <p>{{ $t('working_days') }}</p>
                </div>
                <div class="form-section-content">
                    <div :class="`form-field ${$HasError(errors, 'working_days') ? 'field-invalid' : ''}`">
                        <el-checkbox-group v-model="company.working_days">
                            <el-checkbox
                                    v-for="working_day in $WorkingDays()"
                                    :key="'field-' + working_day"
                                    :label="working_day"
                            >{{ $t('week_days.' + working_day) }}</el-checkbox>
                        </el-checkbox-group>
                        <div v-if="$HasError(errors, 'working_days')" class="field-error">
                            <span>{{ $GetError(errors, 'working_days') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-section">
                <div class="form-section-label">
                    <p>{{ $t('view_overtime') }}</p>
                </div>
                <div class="form-section-content">
                    <div :class="`form-field ${$HasError(errors, 'show_log_hours_over') ? 'field-invalid' : ''}`">
                        <el-checkbox v-model="company.show_log_hours_over">Show</el-checkbox>
                        <div v-if="$HasError(errors, 'show_log_hours_over')" class="field-error">
                            <span>{{ $GetError(errors, 'show_log_hours_over') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="company-action">
            <el-button @click="update()" size="medium" type="success" round>{{ $t('update') }}</el-button>
        </div>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data() {
            const company = this.$Company();
            return {
                company: {
                    ...company,
                    show_log_hours_over: Boolean(company.show_log_hours_over)
                },
                errors: {}
            }
        },
        methods: {
            update(){
                const data = {
                    id: this.company.id,
                    name: this.company.name,
                    director: this.company.pickers.director,
                    manager: this.company.pickers.manager,
                    accountant: this.company.pickers.accountant,
                    dentist: this.company.pickers.dentist,
                    assistent: this.company.pickers.assistent,
                    hygienist: this.company.pickers.hygienist,
                    start: this.company.start,
                    end: this.company.end,
                    working_days: this.company.working_days,
                    show_log_hours_over: this.company.show_log_hours_over
                };

                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/director/company/' + data.id, data).then(({data}) => {
                    if (data.success){
                        this.errors = {};
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>