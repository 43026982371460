<template>
    <div v-if="show" class="notifications-popup scroll-grey">
        <div v-if="notifications.length" class="full-notifications">
            <div v-if="unreadNotificationsCount > 0" class="mark-all-as-read">
                <el-button @click="markAllAsRead()" type="primary" size="mini">{{ $t('mark_all_as_read') }}</el-button>
            </div>
            <div class="notification-items">
                <div v-for="(notification, key) in notifications" :key="'notification-' + key" class="notification-item">
                    <published-new-note v-if="notification['type'] === 'published_new_note'" :notification="notification"></published-new-note>
                    <log-hours-declined v-if="notification['type'] === 'log_hours_declined'" :notification="notification"></log-hours-declined>
                    <complaint v-if="notification['type'] === 'complaint'" :notification="notification"></complaint>
                    <complaint-reaction v-if="notification['type'] === 'complaint_reaction'" :notification="notification"></complaint-reaction>
                    <assigned-complaint v-if="notification['type'] === 'assigned_complaint'" :notification="notification"></assigned-complaint>
                    <unassigned-complaint v-if="notification['type'] === 'unassigned_complaint'" :notification="notification"></unassigned-complaint>
                    <free-day-request v-if="notification['type'] === 'free_day_request'" :notification="notification"></free-day-request>
                    <free-day-accepted v-if="notification['type'] === 'free_day_accepted'" :notification="notification"></free-day-accepted>
                    <free-day-rejected v-if="notification['type'] === 'free_day_rejected'" :notification="notification"></free-day-rejected>
                    <log-hours-passed v-if="notification['type'] === 'log_hours_passed'" :notification="notification"></log-hours-passed>
                    <functional-interview v-if="notification['type'] === 'functional_interview'" :notification="notification"></functional-interview>
                    <birthday v-if="notification['type'] === 'birthday'" :notification="notification"></birthday>
                    <birthday-reminder v-if="notification['type'] === 'birthday_reminder'" :notification="notification"></birthday-reminder>
                </div>
            </div>
        </div>
        <div v-else class="empty-notifications">
            <span>{{ $t('empty_notifications') }}</span>
        </div>
    </div>
</template>

<script>
    import helper from "../mixins/helper";
    import {$http} from "../hooks/api";
    import {base_url} from "../constants/api";
    import {$echo} from "../mixins/echo";
    import PublishedNewNote from "./notifications/PublishedNewNote";
    import LogHoursDeclined from "./notifications/LogHoursDeclined";
    import Complaint from "./notifications/Complaint";
    import ComplaintReaction from "./notifications/ComplaintReaction";
    import AssignedComplaint from "./notifications/AssignedComplaint";
    import UnassignedComplaint from "./notifications/UnassignedComplaint";
    import FreeDayRequest from "./notifications/FreeDayRequest";
    import FreeDayAccepted from "./notifications/FreeDayAccepted";
    import FreeDayRejected from "./notifications/FreeDayRejected";
    import LogHoursPassed from "./notifications/LogHoursPassed";
    import FunctionalInterview from "./notifications/FunctionalInterview";
    import Birthday from "./notifications/Birthday";
    import BirthdayReminder from "./notifications/BirthdayReminder";

    export default {
        mixins: [helper],
        props: {
            show: {
                type: Boolean
            }
        },
        components: {
            PublishedNewNote,
            LogHoursDeclined,
            Complaint,
            ComplaintReaction,
            AssignedComplaint,
            UnassignedComplaint,
            FreeDayRequest,
            FreeDayAccepted,
            FreeDayRejected,
            LogHoursPassed,
            FunctionalInterview,
            Birthday,
            BirthdayReminder
        },
        beforeMount() {
            this.getNotifications();
            this.joinNotificationEvent();
        },
        data(){
            return {
                notifications: []
            }
        },
        methods: {
            getNotifications() {
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/notifications').then(({data}) => {
                    this.notifications = data.notifications;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            joinNotificationEvent(){
                $echo().private('notification').listen('NotificationEvent', (event) => {
                    if (event.notification['user_id'] === this.$User().id){
                        this.notifications.unshift(event.notification.info);
                    }
                });
            },
            markRead(id){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/notifications/mark-read', {id: id}).then(() => {
                    this.notifications = this.notifications.map((item) => {
                        if (item.id === id){
                            return {
                                ...item,
                                is_read: true
                            };
                        }
                        return item;
                    });
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            markAllAsRead(){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/notifications/mark-all-as-read').then(() => {
                    this.notifications = this.notifications.map((item) => {
                        return {
                            ...item,
                            is_read: true
                        };
                    });
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            unreadNotificationsCount: function () {
                return this.notifications.filter(n => n['is_read'] === false).length;
            }
        },
        watch: {
            unreadNotificationsCount: {
                immediate: true,
                handler: function (value) {
                    this.$parent.updateUnreadNotificationsCount(value);
                }
            }
        }
    }
</script>