<template>
    <section class="complaints-section default-section">
        <div class="section-title">
            <h1>{{ $t('complaints') }}</h1>
            <el-button v-if="accessible" @click="toggleCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
        </div>

        <div class="complaints-search">
            <el-input v-model="search" :placeholder="$t('search_complaint')"></el-input>
        </div>

        <div class="complaints-content">
            <div class="table-parent scroll-grey">
                <el-table border :empty-text="$t('no_complaints')" :data="data">
                    <el-table-column :label="$t('actions')" class-name="table-actions">
                        <template slot-scope="scope">
                            <el-button v-if="accessible" @click="update(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
                            <el-popconfirm
                                    v-if="accessible"
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('delete_question')"
                                    @confirm="destroy(scope.row)"
                            >
                                <el-button slot="reference" type="danger" icon="el-icon-delete" circle></el-button>
                            </el-popconfirm>
                            <el-button v-if="canChat(scope.row)" @click="toggleChat(scope.row)" type="warning" icon="el-icon-s-comment" circle></el-button>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('assigned')" class-name="image-text">
                        <template slot-scope="scope">
                            <img v-if="scope.row.user" :src="$Avatar(scope.row.user)" alt="">
                            <span v-if="scope.row.user">{{ $FullName(scope.row.user) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('code')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.code }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('patient_name')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.patient }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('treatment_at')">
                        <template slot-scope="scope">
                            <span>{{ $Date(scope.row['treatment_at'], 'DD-MM-YYYY') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('email')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.email }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('phone')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.phone }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('status')">
                        <template slot-scope="scope">
                            <el-tag class="capitalize" v-if="scope.row.status === 'opened'" effect="dark" type="primary">{{ $t('opened') }}</el-tag>
                            <el-tag class="capitalize" v-if="scope.row.status === 'closed'" effect="dark" type="success">{{ $t('closed') }}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <el-pagination
                    v-if="complaints.live.length > pagination.limit"
                    background
                    layout="pager"
                    @current-change="paginate"
                    :pagerCount="5"
                    :current-page="pagination.page"
                    :page-size="pagination.limit"
                    :total="complaints.live.length">
            </el-pagination>
        </div>

        <transition name="fade">
            <Crud v-if="crud.popup"></Crud>
        </transition>

        <transition name="fade">
            <Chat v-if="chat.popup"></Chat>
        </transition>
    </section>
</template>

<script>
    import {base_url} from "../../../../../constants/api";
    import {roles} from "../../../../../constants/roles";
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {Message} from "element-ui";
    import Crud from "./Crud";
    import Chat from "./Chat";

    export default {
        mixins: [helper],
        components: {Crud, Chat},
        beforeMount() {
            this.getComplaints();
        },
        data() {
            return {
                workers: [],
                complaints: {
                    core: [],
                    live: []
                },
                pagination: {
                    page: 1,
                    limit: 10
                },
                search: null,
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                chat: {
                    popup: false,
                    complaint: {},
                    model: {}
                }
            }
        },
        methods: {
            getComplaints() {
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/complaint').then(({data}) => {
                    this.workers = data.workers;
                    this.complaints.core = JSON.parse(JSON.stringify(data.complaints));
                    this.complaints.live = JSON.parse(JSON.stringify(data.complaints));
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            paginate(page) {
                this.pagination.page = page;
            },
            update(item){
                let model = {
                    id: item.id,
                    user_id: item.user_id,
                    patient: item.patient,
                    email: item.email,
                    phone: item.phone,
                    topic: item.topic,
                    status: item.status,
                    treatment_at: item.treatment_at
                };

                this.toggleCrud('update', model);
            },
            destroy(item){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/complaint/' + item.id).then(({data}) => {
                    if (data.success){
                        this.getComplaints();
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        user_id: null,
                        patient: null,
                        email: null,
                        phone: null,
                        topic: null,
                        treatment_at: null,
                        content: null
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        model: {}
                    }
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        model: model
                    }
                }
            },
            canChat(complaint) {
                return this.accessible || complaint.user_id === this.$User().id;
            },
            toggleChat(complaint) {
                if (this.chat.popup){
                    this.chat = {
                        popup: false,
                        complaint: {},
                        model: {}
                    };
                } else {
                    this.chat = {
                        popup: true,
                        complaint: complaint,
                        model: {
                            content: null,
                            is_patient: false,
                            send_to_email: false
                        }
                    };
                }
            }
        },
        computed: {
            data: function () {
                return this.complaints.live.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            },
            accessible: function () {
                return this.$RoleIs(roles.director) || this.$RoleIs(roles.manager);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            },
            search: {
                deep: true,
                immediate: true,
                handler: function (keyword) {
                    if (keyword !== null){
                        if (keyword.trim() === ''){
                            this.complaints.live = JSON.parse(JSON.stringify(this.complaints.core));
                        } else {
                            this.complaints.live = this.complaints.core.filter((complaint) => {
                                return complaint.code.search(keyword) !== -1;
                            });
                        }
                    }
                }
            }
        }
    }
</script>