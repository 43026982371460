<template>
    <section class="notes-section default-section">
        <div v-if="active" class="back-to-notes" @click="toggleActive()">
            <img src="../../../../images/back.svg" alt="">
            <span>{{ $t('back_to_notes') }}</span>
        </div>
        <div v-else class="section-title">
            <h1>{{ $t('notes') }}</h1>
            <div v-if="$HasPermission('crud-notes')">
                <el-button @click="publish()" size="medium" type="success" round>{{ $t('publish') }}</el-button>
                <el-button @click="toggleCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
            </div>
        </div>

        <div v-if="active" class="single-item">
            <h1 class="single-title">{{ active.title }}</h1>
            <p class="single-created">{{ $Date(active.created_at, 'DD-MM-YYYY') }}</p>
            <p class="single-content" v-html="active.content"></p>
            <div class="single-files">
                <el-link
                        v-for="(file, key) in active.files"
                        :key="'note-file-' + key"
                        :href="file.fullPath"
                        target="_blank"
                        type="primary"
                >
                    <span>{{ file.name }}</span>
                    <el-tag effect="dark">
                        {{ $GetFileExtension(file.path) }}
                    </el-tag>
                </el-link>
            </div>
            <div class="single-actions">
                <div class="single-action" v-if="$HasPermission('crud-notes')" @click="update(active)">
                    <img src="../../../../images/edit.svg" alt="">
                    <span>{{ $t('update') }}</span>
                </div>
            </div>
        </div>

        <div v-else class="notes-content">
            <div class="notes-items">
                <div class="note-item" v-for="(note, key) in data" :key="'note-item-' + key">
                    <h1 class="note-title" @click="toggleActive(note)">{{ note.title }}</h1>
                    <p class="note-created">{{ $Date(note.created_at, 'DD-MM-YYYY') }}</p>
                    <p class="note-content" v-html="note.content"></p>
                    <div class="note-actions">
                        <div class="note-action">
                            <div v-if="$HasPermission('crud-notes')" @click="update(note)">{{ $t('update') }}</div>
                            <el-popconfirm
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('delete_question')"
                                    @confirm="destroy(note)"
                            >
                                <div slot="reference" v-if="$HasPermission('crud-notes')">{{ $t('delete') }}</div>
                            </el-popconfirm>
                        </div>
                        <div class="note-action">
                            <div @click="toggleSeers(note)">{{ $t('seers') }}</div>
                            <div @click="toggleActive(note)">{{ $t('show_more') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="notes-pagination">
                <el-pagination
                        v-if="notes.length > pagination.limit"
                        background
                        layout="pager"
                        @current-change="paginate"
                        :pagerCount="5"
                        :current-page="pagination.page"
                        :page-size="pagination.limit"
                        :total="notes.length">
                </el-pagination>
            </div>
        </div>

        <transition name="fade">
            <Crud v-if="crud.popup"></Crud>
        </transition>

        <transition name="fade">
            <Seers v-if="seers.popup"></Seers>
        </transition>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import Crud from "./Crud";
    import Seers from "./Seers";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        components: {Crud, Seers},
        beforeMount() {
            this.getNotes();
        },
        data() {
            return {
                notes: [],
                staff: [],
                pagination: {
                    page: 1,
                    limit: 3
                },
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                seers: {
                    popup: false,
                    note: {}
                },
                active: null
            };
        },
        methods: {
            async getNotes(){
                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/note').then(({data}) => {
                    this.notes = data.notes;
                    if (this.active){
                        this.active = this.notes.find(n => n.id === this.active.id);
                    }
                    this.staff = data.staff;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            paginate(page) {
                this.pagination.page = page;
            },
            update(note){
                let model = {
                    id: note.id,
                    title: note.title,
                    content: note.content,
                    files: note.files
                };

                this.toggleCrud('update', model);
            },
            destroy(note){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/note/' + note.id).then(({data}) => {
                    if (data.success){
                        this.notes.splice(this.notes.findIndex(n => n.id === note.id), 1);

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            see(id){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/note/' + id + '/see').then(({data}) => {
                    if (data.success){
                        this.notes.splice(this.notes.findIndex(n => n.id === id), 1, data.note);
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        title: null,
                        content: null,
                        files: []
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        model: {}
                    }
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        model: model
                    }
                }
            },
            toggleSeers(note = {}){
                if (this.seers.popup) {
                    this.seers = {
                        popup: false,
                        note: {}
                    }
                } else {
                    this.seers = {
                        popup: true,
                        note: note
                    }
                }
            },
            toggleActive(active = null){
                if (active && active.seers.findIndex(s => s.user_id === this.$User().id) === -1){
                    this.see(active.id);
                }
                this.active = active;
            },
            publish(){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/notes-publish').then(({data}) => {
                    Message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    });
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            data: function () {
                return this.notes.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            }
        }
    };
</script>