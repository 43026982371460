<template>
    <section class="auth-section">
        <h3 class="auth-title">{{ $t('login.title') }}</h3>
        <div class="auth-form">
            <div class="form-field">
                <div class="field-label">{{ $t('email') }} </div>
                <el-input @keyup.enter.native="submit()" size="medium" v-model="credentials.email" name="username"></el-input>
            </div>
            <div class="form-field">
                <div class="field-label">{{ $t('password') }} </div>
                <el-input
                        v-model="credentials.password"
                        @keyup.enter.native="submit()"
                        size="medium"
                        show-password
                        name="password"
                        class="with-append"
                >
                    <template slot="append">
                        <i class="el-icon-view"></i>
                    </template>
                </el-input>
            </div>
        </div>
        <el-button @click="submit()">{{ $t('login.submit') }}</el-button>
        <router-link :to="{name: 'forgot-password'}" class="auth-link" tag="a">{{ $t('login.link') }}</router-link>
    </section>
</template>

<script>
    import {$http} from "../../hooks/api";
    import {base_url} from "../../constants/api";
    import {users} from "../../constants/users";
    import {Message} from "element-ui";

    export default {
        data(){
            return {
                credentials: {
                    email: null,
                    password: null
                }
            }
        },
        methods: {
            submit(){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/login', this.credentials).then(({data}) => {
                    if (data.success) {
                        this.$store.commit("setAccessToken", data.access_token);
                        this.$store.commit("setExpiresAt", data.expires_at);
                        this.$store.commit("setIsLogged", true);
                        this.$store.commit("setUser", data.user);
                        this.$router.push({name: users[data.user.role.name].main});
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>