<template>
    <section class="functional-interview-section default-section">
        <crud v-if="crud.popup"/>
        <div v-else class="functional-interview-view">
            <div class="functional-interview-header">
                <h1>{{ $t('functional_interviews') }}</h1>
                <div class="top-actions">
                    <div class="top-action">
                        <p>{{ $t('interview.year_label') }}</p>
                        <el-select
                                v-model="filters.year"
                                filterable
                                size="small"
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="year in years"
                                    :key="'year-' + year.value"
                                    :label="year.label"
                                    :value="year.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="top-action" v-if="$RoleIs('director')">
                        <el-button @click="toggleCrud('create')" type="primary" size="small" icon="el-icon-plus" circle></el-button>
                        <p>{{ $t('interview.create_button') }}</p>
                    </div>
                </div>
            </div>
            <div class="functional-interview-content">
                <div class="table-parent scroll-grey">
                    <el-table border :empty-text="$t('interview.empty')" :data="data">
                        <el-table-column :label="$t('actions')" class-name="table-actions-center">
                            <template slot-scope="scope">
                                <el-button @click="toggleInterview(scope.row)" icon="el-icon-view" type="success" size="mini" circle></el-button>
                                <el-button v-if="$RoleIs('director')" @click="update(scope.row)" icon="el-icon-edit" type="primary" size="mini" circle></el-button>
                                <el-popconfirm
                                        icon-color="red"
                                        icon="el-icon-info"
                                        :cancel-button-text="$t('no')"
                                        :confirm-button-text="$t('yes')"
                                        :title="$t('delete_question')"
                                        @confirm="destroy(scope.row)"
                                >
                                    <el-button v-if="$RoleIs('director')" slot="reference" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('interview.columns.user_id')" class-name="image-text">
                            <template slot-scope="scope">
                                <img :src="$Avatar(scope.row.user)" alt="">
                                <span>{{ $FullName(scope.row.user) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('interview.columns.job_title')">
                            <template slot-scope="scope">
                                <span>{{ $t('interview.job_titles.' + scope.row.job_title) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('interview.columns.date')">
                            <template slot-scope="scope">
                                <span>{{ moment(scope.row.date, 'YYYY-MM-DD').format('DD-MM-YYYY') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('interview.columns.range')">
                            <template slot-scope="scope">
                                <span>{{ moment(scope.row.start, 'YYYY-MM-DD').format('DD-MMM-YYYY') }}</span>
                                <span> {{ $t('interview.until') }} </span>
                                <span>{{ moment(scope.row.end, 'YYYY-MM-DD').format('DD-MMM-YYYY') }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <el-pagination
                        v-if="filtered.length > pagination.limit"
                        background
                        layout="pager"
                        @current-change="paginate"
                        :pagerCount="5"
                        :current-page="pagination.page"
                        :page-size="pagination.limit"
                        :total="filtered.length">
                </el-pagination>
            </div>
        </div>
        <transition name="fade">
            <popup v-if="popup.popup"></popup>
        </transition>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import moment from "moment";
    import {Message} from "element-ui";
    import Crud from "./Crud";
    import Popup from "./Popup";

    export default {
        mixins: [helper],
        components: {Crud, Popup},
        async beforeMount() {
            await this.getInterviews();
            await this.getInterviewDetails();
        },
        data(){
            return {
                moment: moment,
                filters: {
                    year: 'all_years'
                },
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                interviews: [],
                details: {},
                users: [],
                pagination: {
                    page: 1,
                    limit: 10
                },
                popup: {
                    popup: false,
                    model: null
                }
            }
        },
        methods: {
            async getInterviews(){
                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/interview').then(({data}) => {
                    this.interviews = data.interviews;
                    this.users = data.users;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            async getInterviewDetails(preloader = true) {
                this.$store.commit('setPreloader', preloader);
                await $http().get(base_url + '/api/client/interview-details').then(({data}) => {
                    this.details = data.details;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            paginate(page) {
                this.pagination.page = page;
            },
            update(interview){
                let model = {
                    id: interview.id,
                    user_id: interview.user_id,
                    job_title: interview.job_title,
                    range: [interview.start, interview.end],
                    date: interview.date,
                    performance_1: interview.performance_1,
                    performance_2: interview.performance_2,
                    performance_3: interview.performance_3,
                    performance_4: interview.performance_4,
                    future: interview.future,
                    after: interview.after,
                    shared: Boolean(interview.shared)
                };

                if (interview.user.first_interview_id === interview.id){
                    model.before = interview.before;
                } else {
                    model.before = interview.previous;
                }

                this.toggleCrud('update', model);
            },
            destroy(interview){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/interview/' + interview.id).then(async ({data}) => {
                    if (data.success){
                        await this.getInterviews();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        user_id: null,
                        job_title: null,
                        range: null,
                        date: null,
                        performance_1: null,
                        performance_2: null,
                        performance_3: null,
                        performance_4: null,
                        future: null,
                        before: null,
                        after: null
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        model: {}
                    };
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        model: model
                    };
                }
            },
            toggleInterview(model = null){
                this.popup = {
                    popup: !this.popup.popup,
                    model: model
                };
            }
        },
        computed: {
            years: function () {
                let years = [
                    {
                        label: this.$t('interview.all_years'),
                        value: 'all_years'
                    }
                ];

                this.interviews.map(interview => {
                    let year = moment(interview.date, 'YYYY-MM-DD').format('YYYY');

                    if (years.findIndex(item => item.value === year) === -1){
                        years.push({
                            label: year,
                            value: year
                        });
                    }
                });

                return years;
            },
            filtered: function () {
                return this.interviews.filter(interview => {
                    if (this.filters.year === 'all_years'){
                        return true;
                    }
                    return moment(interview.date, 'YYYY-MM-DD').format('YYYY') === this.filters.year;
                });
            },
            data: function () {
                return this.filtered.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            }
        }
    }
</script>