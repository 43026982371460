<template>
    <div class="popup crud-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('copy') + ' ' + room.name }}</h1>
                <img @click="($event) => close($event)" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="crud-form">
                    <div :class="`form-field ${$HasError(errors, 'name') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('name') }}</div>
                        <el-input size="medium" v-model="model.name"></el-input>
                        <div v-if="$HasError(errors, 'name')" class="field-error">
                            <span>{{ $GetError(errors, 'name') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="($event) => close($event)" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="($event) => submit($event)" size="medium" type="success" round>{{ $t('submit') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data() {
            return {
                room: this.$parent.copy_room.room,
                model: this.$parent.copy_room.model,
                errors: {}
            }
        },
        methods: {
            close(event){
                this.$parent.copyRoom(event);
            },
            submit(event){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/checklist-room/' + this.room.id + '/copy', this.model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getRooms();

                        this.close(event);

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>