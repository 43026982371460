<template>
    <div class="popup show-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ event.title }}</h1>
                <div class="event-actions">
                    <el-popconfirm
                            v-if="$RoleIs('director') || $RoleIs('manager')"
                            icon-color="red"
                            icon="el-icon-info"
                            :cancel-button-text="$t('no')"
                            :confirm-button-text="$t('yes')"
                            :title="$t('delete_question')"
                            @confirm="destroy()"
                    >
                        <i slot="reference" class="el-icon-delete"></i>
                    </el-popconfirm>
                    <i v-if="$RoleIs('director') || $RoleIs('manager')" @click="update()" class="el-icon-edit"></i>
                    <i @click="close()" class="el-icon-close"></i>
                </div>
            </div>
            <div class="popup-body scroll-grey">
                <div class="event-content">
                    <div>
                        <p>{{ $t('date') }}</p>
                        <p>{{ $DisplayDatesRange(event.start, event.end) }}</p>
                    </div>
                    <div v-if="event.properties.description">
                        <p>{{ $t('description') }}</p>
                        <p>{{ event.properties.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {base_url} from "../../../../constants/api";
    import {$http} from "../../../../hooks/api";
    import {Message} from "element-ui";
    import moment from "moment";

    export default {
        mixins: [helper],
        data() {
            return {
                event: this.$parent.show.event
            }
        },
        methods: {
            close(){
                this.$parent.show = {
                    popup: false,
                    event: {}
                };
            },
            update(){
                this.close();

                this.$parent.crud = {
                    popup: true,
                    action: 'update',
                    model: {
                        id: this.event.id,
                        title: this.event.title,
                        start_date: moment(this.event.start).format('YYYY-MM-DD'),
                        end_date: moment(this.event.end).format('YYYY-MM-DD'),
                        start_time: moment(this.event.start).format('HH:mm'),
                        end_time: moment(this.event.end).format('HH:mm'),
                        whole_day: this.event.start.search(':') === -1,
                        description: this.event.properties.description
                    }
                };
            },
            destroy(){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/event/' + this.event.id).then(({data}) => {
                    if (data.success){
                        this.$parent.options.initialEvents.splice(this.$parent.options.initialEvents.findIndex(e => e.id === +this.event.id), 1);

                        this.$parent.refresh++;

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>