<template>
    <div class="sidebar scroll-grey">
        <div v-if="$RoleIs('admin')" class="sidebar-items">
            <router-link :to="{name: 'companies'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/companies.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/companies.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('companies') }}</div>
            </router-link>
            <router-link :to="{name: 'change-password'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/settings.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/settings.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('change_password') }}</div>
            </router-link>
        </div>
        <div v-else class="sidebar-items">
            <div :class="`sidebar-group sidebar-group-settings ${$route.name === 'settings' ? 'is-active opened' : ''}`">
                <div class="sidebar-group-item">
                    <div class="sidebar-group-item-left">
                        <div class="sidebar-group-item-left-images">
                            <img class="dark-img" src="../images/sidebar/dark/settings.svg" alt="">
                            <img class="light-img" src="../images/sidebar/light/settings.svg" alt="">
                        </div>
                        <div class="sidebar-group-item-left-name">{{ $t('settings') }}</div>
                    </div>
                    <div class="sidebar-group-item-right">
                        <div class="sidebar-group-item-right-action">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="sidebar-group-dropdown">
                    <router-link
                            v-for="screen in settings"
                            :key="'settings-dropdown-' + screen"
                            :to="{name: 'settings', params: {prefix: screen}}"
                            :class="`sidebar-group-dropdown-item ${$route.params.prefix === screen ? 'current' : ''}`"
                            tag="div"
                    >
                        <hr>
                        <span>{{ $t(screen) }}</span>
                    </router-link>
                </div>
            </div>
            <div v-if="$AccessToRouteByRoles('staff')" :class="`sidebar-group sidebar-group-staff ${$route.name === 'staff' ? 'is-active opened' : ''}`">
                <div class="sidebar-group-item">
                    <div class="sidebar-group-item-left">
                        <div class="sidebar-group-item-left-images">
                            <img class="dark-img" src="../images/sidebar/dark/staff.svg" alt="">
                            <img class="light-img" src="../images/sidebar/light/staff.svg" alt="">
                        </div>
                        <div class="sidebar-group-item-left-name">{{ $t('staff') }}</div>
                    </div>
                    <div class="sidebar-group-item-right">
                        <div class="sidebar-group-item-right-action">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="sidebar-group-dropdown">
                    <router-link
                            v-if="staff.includes('staff')"
                            :to="{name: 'staff', params: {prefix: 'staff'}}"
                            :class="`sidebar-group-dropdown-item ${$route.params.prefix === 'staff' ? 'current' : ''}`"
                            tag="div"
                    >
                        <hr>
                        <span>{{ $t('staff') }}</span>
                    </router-link>
                    <router-link
                            v-if="staff.includes('functional-interviews')"
                            :to="{name: 'staff', params: {prefix: 'functional-interviews'}}"
                            :class="`sidebar-group-dropdown-item ${$route.params.prefix === 'functional-interviews' ? 'current' : ''}`"
                            tag="div"
                    >
                        <hr>
                        <span>{{ $t('functional_interviews') }}</span>
                    </router-link>
                </div>
            </div>
            <router-link v-if="$AccessToRouteByRoles('personnel')" :to="{name: 'personnel'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/staff.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/staff.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('staff') }}</div>
            </router-link>
            <router-link v-if="$HasPermission('view-graphic')" :to="{name: 'graphic'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/graphic.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/graphic.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('graphic') }}</div>
            </router-link>
            <router-link v-if="$HasPermission('view-notes')" :to="{name: 'notes'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/notes.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/notes.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('notes') }}</div>
            </router-link>
            <router-link v-if="$HasPermission('view-protocols')" :to="{name: 'protocols'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/protocols.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/protocols.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('protocols') }}</div>
            </router-link>
            <div v-if="$RoleIs('director') || $RoleIs('manager')" :class="`sidebar-group sidebar-group-checklist ${$route.name === 'checklist' ? 'is-active opened' : ''}`">
                <div class="sidebar-group-item">
                    <div class="sidebar-group-item-left">
                        <div class="sidebar-group-item-left-images">
                            <img class="dark-img" src="../images/sidebar/dark/checklist.svg" alt="">
                            <img class="light-img" src="../images/sidebar/light/checklist.svg" alt="">
                        </div>
                        <div class="sidebar-group-item-left-name">{{ $t('checklist') }}</div>
                    </div>
                    <div class="sidebar-group-item-right">
                        <div class="sidebar-group-item-right-action">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="sidebar-group-dropdown">
                    <router-link
                            v-for="screen in checklist"
                            :key="'checklist-dropdown-' + screen"
                            :to="{name: 'checklist', params: {prefix: screen}}"
                            :class="`sidebar-group-dropdown-item ${$route.params.prefix === screen ? 'current' : ''}`"
                            tag="div"
                    >
                        <hr>
                        <span>{{ $t('checklist_' + screen + '.title') }}</span>
                    </router-link>
                </div>
            </div>
            <router-link v-else-if="$AccessToRouteByRoles('checklist')" :to="{name: 'checklist', params: {prefix: 'overview'}}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/checklist.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/checklist.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('checklist') }}</div>
            </router-link>
            <router-link v-if="$AccessToRouteByRoles('log-hours')" :to="{name: 'log-hours'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/log-hours.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/log-hours.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('log_hours') }}</div>
            </router-link>
            <router-link v-if="$AccessToRouteByRoles('free-days')" :to="{name: 'free-days'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/free-days.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/free-days.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('free_days') }}</div>
            </router-link>
            <router-link v-if="$AccessToRouteByRoles('salary')" :to="{name: 'salary'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/salary.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/salary.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('salary') }}</div>
            </router-link>
            <div v-if="$AccessToRouteByRoles('incidents-and-complaints')" :class="`sidebar-group sidebar-group-incidents-and-complaints ${$route.name === 'incidents-and-complaints' ? 'is-active opened' : ''}`">
                <div class="sidebar-group-item">
                    <div class="sidebar-group-item-left">
                        <div class="sidebar-group-item-left-images">
                            <img class="dark-img" src="../images/sidebar/dark/incidents-and-complaints.svg" alt="">
                            <img class="light-img" src="../images/sidebar/light/incidents-and-complaints.svg" alt="">
                        </div>
                        <div class="sidebar-group-item-left-name">{{ $t('incidents_and_complaints') }}</div>
                    </div>
                    <div class="sidebar-group-item-right">
                        <div class="sidebar-group-item-right-action">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="sidebar-group-dropdown">
                    <router-link
                            :to="{name: 'incidents-and-complaints', params: {prefix: 'safe-reporting-incident'}}"
                            :class="`sidebar-group-dropdown-item ${$route.params.prefix === 'safe-reporting-incident' ? 'current' : ''}`"
                            tag="div"
                    >
                        <hr>
                        <span>{{ $t('safe_reporting_incident') }}</span>
                    </router-link>
                    <router-link
                            :to="{name: 'incidents-and-complaints', params: {prefix: 'complaints'}}"
                            :class="`sidebar-group-dropdown-item ${$route.params.prefix === 'complaints' ? 'current' : ''}`"
                            tag="div"
                    >
                        <hr>
                        <span>{{ $t('complaints') }}</span>
                    </router-link>
                </div>
            </div>
            <router-link v-if="$AccessToRouteByRoles('agenda')" :to="{name: 'agenda'}" class="sidebar-item" active-class="active" tag="div">
                <div class="sidebar-item-images">
                    <img class="dark-img" src="../images/sidebar/dark/agenda.svg" alt="">
                    <img class="light-img" src="../images/sidebar/light/agenda.svg" alt="">
                </div>
                <div class="sidebar-item-name">{{ $t('agenda') }}</div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import helper from "../mixins/helper";

    export default {
        mixins: [helper],
        beforeMount() {
            $(function () {
                $('.sidebar-group-item').click(function () {
                    $(this).parents('.sidebar-group').toggleClass('opened');
                    $(this).next().slideToggle();
                });
            });
        },
        methods: {
            openDropdown(){
                $(function () {
                    $('.sidebar-group.is-active .sidebar-group-dropdown').slideDown();
                });
            },
            closeDropDown(name){
                let sidebar_group = $('.sidebar-group-' + name);
                sidebar_group.removeClass('is-active');
                sidebar_group.removeClass('opened');
                sidebar_group.find('.sidebar-group-dropdown').slideUp();
                sidebar_group.find('.sidebar-group-dropdown-item').removeClass('current');
            }
        },
        computed: {
            settings: function () {
                let screens = {
                    profile: true,
                    change_password: true,
                    company: this.$RoleIs('director'),
                    protocol_categories: this.$HasPermission('view-categories'),
                    checklist: this.$HasPermission('manage-checklists'),
                    files: true
                };

                Object.keys(screens).map((screen) => {
                    if (!screens[screen]){
                        delete screens[screen];
                    }
                });

                return Object.keys(screens);
            },
            checklist: function () {
                let screens = {
                    overview: true,
                    history: true
                };

                Object.keys(screens).map((screen) => {
                    if (!screens[screen]){
                        delete screens[screen];
                    }
                });

                return Object.keys(screens);
            },
            staff: function () {
                let screens = {
                    'staff': this.$RoleIs('director'),
                    'functional-interviews': true
                };

                Object.keys(screens).map((screen) => {
                    if (!screens[screen]){
                        delete screens[screen];
                    }
                });

                return Object.keys(screens);
            }
        },
        watch: {
            $route(route){
                this.openDropdown();
                if (route.name !== 'settings'){
                    this.closeDropDown('settings');
                }
                if (route.name !== 'checklist'){
                    this.closeDropDown('checklist');
                }
                if (route.name !== 'incidents-and-complaints'){
                    this.closeDropDown('incidents-and-complaints');
                }
                if (route.name !== 'staff'){
                    this.closeDropDown('staff');
                }
            }
        }
    }
</script>