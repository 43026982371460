<template>
    <div class="popup range-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ title }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="range-form">
                    <div class="form-field">
                        <div class="field-label">{{ $t('start') }}</div>
                        <el-time-select
                                v-model="model.start"
                                :picker-options="{
                                    start: '00:00',
                                    step: '00:05',
                                    end: '23:55'
                                }"
                                placeholder="--:--">
                        </el-time-select>
                    </div>
                    <div class="form-field">
                        <div class="field-label">{{ $t('end') }}</div>
                        <el-time-select
                                v-model="model.end"
                                :picker-options="{
                                    start: '00:00',
                                    step: '00:05',
                                    end: '23:55'
                                }"
                                placeholder="--:--">
                        </el-time-select>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="set()" :disabled="!adjustable" size="medium" type="success" round>{{ $t('set') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        data() {
            return {
                model: JSON.parse(JSON.stringify(this.$parent.current.live[this.$parent.range.index]))
            }
        },
        methods: {
            close(){
                this.$parent.toggleRange();
            },
            set(){
                let start = moment(this.model.start, 'HH:mm');
                let end = moment(this.model.end, 'HH:mm');

                this.$parent.updateLogHour({
                    start: this.model.start,
                    end: this.model.end,
                    normal: end.diff(start, 'minutes') / 60
                });
            }
        },
        computed: {
            title: function () {
                return moment(this.model.day, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY');
            },
            adjustable: function () {
                let start = this.model.start ? moment(this.model.start, 'HH:mm') : null;
                let end = this.model.end ? moment(this.model.end, 'HH:mm') : null;
                return start && end && end.diff(start, 'minutes') > 0;
            }
        }
    }
</script>