<template>
    <div class="notification-item-content">
        <span>{{ $t('notifications.log_hours_passed.title', {user: $FullName(notification.user), month: moment(notification.date, 'YYYY-MM').format('MMMM')}) }}</span>
        <el-button v-if="!notification.is_read" @click="markRead()" type="primary" size="mini" icon="el-icon-view" circle></el-button>
    </div>
</template>

<script>
    import helper from "../../mixins/helper";
    import moment from 'moment';

    export default {
        mixins: [helper],
        props: {
            notification: {
                type: Object
            }
        },
        data(){
            return {
                moment: moment
            }
        },
        methods: {
            markRead(){
                this.$parent.markRead(this.notification.id);
            }
        }
    }
</script>