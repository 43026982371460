<template>
    <section>
        <safe-reporting-incident v-if="screen === 'safe-reporting-incident'"></safe-reporting-incident>
        <complaints v-if="screen === 'complaints'"></complaints>
    </section>
</template>

<script>
    import SafeReportingIncident from "./safe-reporting-incident/SafeReportingIncident";
    import Complaints from "./complaints/Complaints";

    export default {
        components: {
            SafeReportingIncident,
            Complaints
        },
        beforeMount() {
            this.check();
        },
        methods: {
            check(){
                if (this.screens.indexOf(this.screen) === -1){
                    this.$router.push({name: 'incidents-and-complaints', params: {prefix: 'safe-reporting-incident'}});
                }
            }
        },
        computed: {
            screen: function () {
                return this.$route.params.prefix;
            },
            screens: function () {
                let screens = {
                    'safe-reporting-incident': true,
                    'complaints': true
                };

                Object.keys(screens).map((screen) => {
                    if (!screens[screen]){
                        delete screens[screen];
                    }
                });

                return Object.keys(screens);
            }
        },
        watch: {
            $route(){
                this.check();
            }
        }
    }
</script>