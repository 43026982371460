<template>
    <div class="popup crud-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t(action) }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <el-tabs v-model="tab">
                    <el-tab-pane :label="$t('company')" name="company">
                        <div class="crud-form">
                            <div :class="`form-field ${$HasError(errors.company, 'name') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('name') }} </div>
                                <el-input size="medium" v-model="company.name"></el-input>
                                <div v-if="$HasError(errors.company, 'name')" class="field-error">
                                    <span>{{ $GetError(errors.company, 'name') }}</span>
                                </div>
                            </div>
                            <div class="form-field">
                                <div class="field-label">{{ $t('pickers') }} </div>
                                <div :class="`picker-item ${$HasError(errors.company, 'director') ? 'invalid-picker' : ''}`" >
                                    <el-color-picker v-model="company.director" size="small"></el-color-picker>
                                    <div class="picker-role">{{ $t('male.director') }}</div>
                                </div>
                                <div :class="`picker-item ${$HasError(errors.company, 'manager') ? 'invalid-picker' : ''}`" >
                                    <el-color-picker v-model="company.manager" size="small"></el-color-picker>
                                    <div class="picker-role">{{ $t('male.manager') }}</div>
                                </div>
                                <div :class="`picker-item ${$HasError(errors.company, 'accountant') ? 'invalid-picker' : ''}`" >
                                    <el-color-picker v-model="company.accountant" size="small"></el-color-picker>
                                    <div class="picker-role">{{ $t('male.accountant') }}</div>
                                </div>
                                <div :class="`picker-item ${$HasError(errors.company, 'dentist') ? 'invalid-picker' : ''}`" >
                                    <el-color-picker v-model="company.dentist" size="small"></el-color-picker>
                                    <div class="picker-role">{{ $t('male.dentist') }}</div>
                                </div>
                                <div :class="`picker-item ${$HasError(errors.company, 'assistent') ? 'invalid-picker' : ''}`" >
                                    <el-color-picker v-model="company.assistent" size="small"></el-color-picker>
                                    <div class="picker-role">{{ $t('male.assistent') }}</div>
                                </div>
                                <div :class="`picker-item ${$HasError(errors.company, 'hygienist') ? 'invalid-picker' : ''}`" >
                                    <el-color-picker v-model="company.hygienist" size="small"></el-color-picker>
                                    <div class="picker-role">{{ $t('male.hygienist') }}</div>
                                </div>
                            </div>
                            <div class="start-and-end">
                                <div :class="`form-field ${$HasError(errors.company, 'start') ? 'field-invalid' : ''}`">
                                    <div class="field-label">{{ $t('start') }} </div>
                                    <el-time-select
                                            v-model="company.start"
                                            :picker-options="{
                                                start: '00:00',
                                                step: '00:05',
                                                end: '23:55'
                                            }"
                                            placeholder="--:--">
                                    </el-time-select>
                                    <div v-if="$HasError(errors.company, 'start')" class="field-error">
                                        <span>{{ $GetError(errors.company, 'start') }}</span>
                                    </div>
                                </div>
                                <div :class="`form-field ${$HasError(errors.company, 'end') ? 'field-invalid' : ''}`">
                                    <div class="field-label">{{ $t('end') }} </div>
                                    <el-time-select
                                            v-model="company.end"
                                            :picker-options="{
                                                start: '00:00',
                                                step: '00:05',
                                                end: '23:55'
                                            }"
                                            placeholder="--:--">
                                    </el-time-select>
                                    <div v-if="$HasError(errors.company, 'end')" class="field-error">
                                        <span>{{ $GetError(errors.company, 'end') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('male.director')" name="director">
                        <div class="crud-form">
                            <div class="form-field flex-center">
                                <div class="avatar-area">
                                    <div class="file-area">
                                        <input type="file" @change="changeAvatar"/>
                                    </div>
                                    <div class="image-area">
                                        <img :src="avatar" alt="">
                                    </div>
                                    <div class="shadow-area">
                                        <img src="../../../../images/upload.png" alt="">
                                    </div>
                                </div>
                                <div v-if="$HasError(errors.director, 'avatar')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'avatar') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'name') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('name') }} </div>
                                <el-input size="medium" v-model="director.name"></el-input>
                                <div v-if="$HasError(errors.director, 'name')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'name') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'surname') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('surname') }} </div>
                                <el-input size="medium" v-model="director.surname"></el-input>
                                <div v-if="$HasError(errors.director, 'surname')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'surname') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'gender') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('gender') }} </div>
                                <el-radio-group v-model="director.gender" size="medium">
                                    <el-radio label="male" border>{{ $t('genders.male') }}</el-radio>
                                    <el-radio label="female" border>{{ $t('genders.female') }}</el-radio>
                                </el-radio-group>
                                <div v-if="$HasError(errors.director, 'gender')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'gender') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'address') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('address') }} </div>
                                <el-input size="medium" v-model="director.address"></el-input>
                                <div v-if="$HasError(errors.director, 'address')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'address') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'postal_code') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('postal_code') }} </div>
                                <el-input size="medium" v-model="director.postal_code"></el-input>
                                <div v-if="$HasError(errors.director, 'postal_code')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'postal_code') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'place') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('place') }} </div>
                                <el-input size="medium" v-model="director.place"></el-input>
                                <div v-if="$HasError(errors.director, 'place')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'place') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'bsn_number') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('bsn_number') }} </div>
                                <el-input size="medium" v-model="director.bsn_number"></el-input>
                                <div v-if="$HasError(errors.director, 'bsn_number')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'bsn_number') }}</span>
                                </div>
                            </div>
                            <div v-if="director.birthday !== undefined" :class="`form-field ${$HasError(errors.director, 'birthday') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('birthday') }} </div>
                                <el-date-picker
                                        v-model="director.birthday"
                                        format="dd-MM-yyyy"
                                        value-format="yyyy-MM-dd"
                                        type="date">
                                </el-date-picker>
                                <div v-if="$HasError(errors.director, 'birthday')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'birthday') }}</span>
                                </div>
                            </div>
                            <div :class="`form-field ${$HasError(errors.director, 'email') ? 'field-invalid' : ''}`">
                                <div class="field-label">{{ $t('email') }} </div>
                                <el-input size="medium" v-model="director.email"></el-input>
                                <div v-if="$HasError(errors.director, 'email')" class="field-error">
                                    <span>{{ $GetError(errors.director, 'email') }}</span>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import objectToFormData from "../../../../mixins/objectToFormData";

    export default {
        mixins: [helper],
        data() {
            return {
                tab: 'company',
                company: this.$parent.crud.company,
                director: this.$parent.crud.director,
                errors: {
                    company: {},
                    director: {}
                }
            }
        },
        methods: {
            close(){
                this.$parent.toggleCrud(this.action);
            },
            submit(){
                let data = objectToFormData({
                    company: this.company,
                    director: this.director
                });

                if (this.action === 'create'){
                    this.create(data);
                } else{
                    data.append("_method", "put");
                    this.update(data, this.company.id);
                }
            },
            create(data){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/admin/company', data).then(({data}) => {
                    if (data.success){
                        this.errors = {
                            company: {},
                            director: {}
                        };

                        this.$parent.companies.unshift(data.company);

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                        this.tab = Object.keys(this.errors.company).length ? 'company' : 'director';
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(data, id){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/admin/company/' + id, data).then(({data}) => {
                    if (data.success){
                        this.errors = {
                            company: {},
                            director: {}
                        };

                        this.$parent.companies.splice(this.$parent.companies.findIndex(c => c.id === id), 1, data.company);

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                        this.tab = Object.keys(this.errors.company).length ? 'company' : 'director';
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            changeAvatar(e) {
                this.director.avatar = e.target.files[0];
            }
        },
        computed: {
            action: function () {
                return this.$parent.crud.action;
            },
            avatar: function () {
                if (this.director.avatar){
                    if (typeof this.director.avatar === 'string'){
                        return this.director.avatar;
                    }

                    return URL.createObjectURL(this.director.avatar);
                }

                if (this.director.gender === 'female'){
                    return require('../../../../images/female.png');
                }

                return require('../../../../images/male.png');
            }
        }
    }
</script>