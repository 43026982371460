<template>
    <section class="settings-section default-section">
        <div class="section-title">
            <h1>{{ $t('settings') }} | {{ $t(screen) }}</h1>
        </div>

        <profile v-if="screen === 'profile'"></profile>
        <change-password v-if="screen === 'change_password'"></change-password>
        <company v-if="screen === 'company'"></company>
        <categories v-if="screen === 'protocol_categories'"></categories>
        <checklist v-if="screen === 'checklist'"></checklist>
        <files v-if="screen === 'files'"></files>
    </section>
</template>

<script>
    import helper from "../../../mixins/helper";
    import Profile from "./user/Profile";
    import ChangePassword from "./user/ChangePassword";
    import Company from "./director/Company";
    import Categories from "./categories/Categories";
    import Checklist from "./checklist/Manage";
    import Files from "./files/Files";

    export default {
        mixins: [helper],
        components: {
            Profile,
            ChangePassword,
            Company,
            Categories,
            Checklist,
            Files
        },
        beforeMount() {
            this.check();
        },
        methods: {
            check(){
                if (this.screens.indexOf(this.screen) === -1){
                    this.$router.push({name: 'settings', params: {prefix: 'profile'}});
                }
            }
        },
        computed: {
            screen: function () {
                return this.$route.params.prefix;
            },
            screens: function () {
                let screens = {
                    profile: true,
                    change_password: true,
                    company: this.$RoleIs('director'),
                    protocol_categories: this.$HasPermission('view-categories'),
                    checklist: this.$HasPermission('manage-checklists'),
                    files: true
                };

                Object.keys(screens).map((screen) => {
                    if (!screens[screen]){
                        delete screens[screen];
                    }
                });

                return Object.keys(screens);
            }
        },
        watch: {
            $route(){
                this.check();
            }
        }
    }
</script>