<template>
    <section class="staff-section default-section">
        <div v-if="files.display" class="staff-area files-side">
            <Files :user="files.user"></Files>
        </div>

        <div v-else class="staff-area content-side">
            <div class="section-title">
                <h1>{{ $t('staff') }}</h1>
                <el-button @click="toggleCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
            </div>

            <div class="staff-content">
                <div class="table-parent scroll-grey">
                    <el-table border :empty-text="$t('no_staff')" :data="data">
                        <el-table-column :label="$t('actions')" class-name="table-actions">
                            <template slot-scope="scope">
                                <el-button @click="update(scope.row)" size="medium" type="primary" icon="el-icon-key" circle></el-button>
                                <el-button @click="toggleFiles(scope.row)" size="medium" type="warning" icon="el-icon-folder-opened" circle></el-button>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('name') + ' ' + $t('surname')" class-name="image-text">
                            <template slot-scope="scope">
                                <img :src="$Avatar(scope.row)" alt="">
                                <span :class="{'inactive': scope.row.inactive}">{{ $FullName(scope.row) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('email')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ scope.row.email }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('role')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ $GetRoleName(scope.row.role.name, scope.row.gender) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('gender')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ $t('genders.' + scope.row.gender) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('address')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ scope.row.address }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('postal_code')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ scope.row.postal_code }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('place')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ scope.row.place }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('bsn_number')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ scope.row.bsn_number }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('birthday')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ $Date(scope.row['birthday'], 'DD-MM-YYYY') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('created_at')">
                            <template slot-scope="scope">
                                <span :class="{'inactive': scope.row.inactive}">{{ $Date(scope.row['created_at'], 'DD-MM-YYYY') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('verified_at')" class-name="flex-direction">
                            <template slot-scope="scope">
                                <span class="success-text" v-if="scope.row['email_verified']">{{ $Date(scope.row['email_verified_at'], 'DD-MM-YYYY') }}</span>
                                <span class="warning-text" v-else>-------------------</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <el-pagination
                        v-if="staff.length > pagination.limit"
                        background
                        layout="pager"
                        @current-change="paginate"
                        :pagerCount="5"
                        :current-page="pagination.page"
                        :page-size="pagination.limit"
                        :total="staff.length">
                </el-pagination>
            </div>

            <transition name="fade">
                <Crud v-if="crud.popup"></Crud>
            </transition>
        </div>
    </section>
</template>

<script>
    import Crud from "./Crud";
    import Files from "./Files";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import helper from "../../../../../mixins/helper";

    export default {
        mixins: [helper],
        components: {Crud, Files},
        beforeMount() {
            this.getStaff();
        },
        data() {
            return {
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                files: {
                    display: false,
                    user: {}
                },
                staff: [],
                roles: [],
                permissions: [],
                pagination: {
                    page: 1,
                    limit: 10
                }
            }
        },
        methods: {
            async getStaff() {
                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/director/staff').then(({data}) => {
                    this.staff = data.staff;
                    this.roles = data.roles;
                    this.permissions = data.permissions;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            paginate(page) {
                this.pagination.page = page;
            },
            update(user){
                let model = {
                    id: user.id,
                    role: user.role.name,
                    permissions: user.permissions.map(permission => {
                        return permission.name;
                    }),
                    free_hours: user.free_hours,
                    remaining_free_hours: user.remaining_free_hours,
                    inactive: Boolean(user.inactive)
                };

                this.toggleCrud('update', model);
            },
            toggleCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        name: null,
                        surname: null,
                        role: null,
                        permissions: [],
                        gender: null,
                        address: null,
                        postal_code: null,
                        place: null,
                        bsn_number: null,
                        birthday: null,
                        email: null,
                        avatar: null
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        model: {}
                    }
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        model: model
                    }
                }
            },
            toggleFiles(user){
                if (this.files.display){
                    this.files = {
                        display: false,
                        user: {}
                    };
                } else {
                    this.files = {
                        display: true,
                        user: user
                    };
                }
            }
        },
        computed: {
            data: function () {
                return this.staff.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            }
        }
    }
</script>