<template>
    <div class="popup time-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ displayDay() }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="time-form">
                    <div :class="`form-field ${$HasError(errors, 'user_id') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('user') }} </div>
                        <el-select
                                v-model="model.user_id"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    class="option-with-image"
                                    v-for="user in users"
                                    :key="'user-' + user.id"
                                    :label="$FullName(user)"
                                    :value="user.id">
                                <img :src="$Avatar(user)" alt="">
                                <span>{{ $FullName(user) }}</span>
                            </el-option>
                        </el-select>
                        <div v-if="$HasError(errors, 'user_id')" class="field-error">
                            <span>{{ $GetError(errors, 'user_id') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'room_id') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('room') }} </div>
                        <el-select
                                v-model="model.room_id"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="room in rooms"
                                    :key="'room-' + room.id"
                                    :label="room.name"
                                    :value="room.id">
                            </el-option>
                        </el-select>
                        <div v-if="$HasError(errors, 'room_id')" class="field-error">
                            <span>{{ $GetError(errors, 'room_id') }}</span>
                        </div>
                    </div>
                    <div class="start-and-end">
                        <div :class="`form-field ${$HasError(errors, 'start') ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('start') }} </div>
                            <el-time-select
                                    v-model="model.start"
                                    :picker-options="{
                                        start: settings.start,
                                        step: '00:05',
                                        end: settings.end
                                    }"
                                    placeholder="--:--">
                            </el-time-select>
                            <div v-if="$HasError(errors, 'start')" class="field-error">
                                <span>{{ $GetError(errors, 'start') }}</span>
                            </div>
                        </div>
                        <div :class="`form-field ${$HasError(errors, 'end') ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('end') }} </div>
                            <el-time-select
                                    v-model="model.end"
                                    :picker-options="{
                                        start: settings.start,
                                        step: '00:05',
                                        end: settings.end
                                    }"
                                    placeholder="--:--">
                            </el-time-select>
                            <div v-if="$HasError(errors, 'end')" class="field-error">
                                <span>{{ $GetError(errors, 'end') }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="action === 'create'" class="repeat-container">
                        <el-checkbox v-model="repeat.show">{{ $t('repeat') }}</el-checkbox>
                        <div v-if="repeat.show" class="repeat-section">
                            <div class="repeat-section-area">
                                <el-radio-group v-model="repeat.type">
                                    <el-radio label="every">{{ $t('repeat_types.every', {day: displayDayName()}) }}</el-radio>
                                    <el-radio label="workday">{{ $t('repeat_types.workday') }}</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="repeat-section-area" v-if="repeat.type === 'every'">
                                <el-radio-group v-model="repeat.types.every.type">
                                    <el-radio label="date">{{ $t('repeat_actions.date') }}</el-radio>
                                    <el-radio label="count">{{ $t('repeat_actions.count') }}</el-radio>
                                </el-radio-group>
                                <el-date-picker
                                        v-if="repeat.types.every.type === 'date'"
                                        v-model="repeat.types.every.values.date"
                                        :picker-options="picker_options"
                                        size="mini"
                                        format="dd-MM-yyyy"
                                        value-format="yyyy-MM-dd"
                                        type="date">
                                </el-date-picker>
                                <el-input-number
                                        v-if="repeat.types.every.type === 'count'"
                                        v-model="repeat.types.every.values.count"
                                        controls-position="right"
                                        size="mini"
                                        :min="1"
                                ></el-input-number>
                            </div>
                            <div class="repeat-section-area" v-if="repeat.type === 'workday'">
                                <div class="blue-text">{{ $t('repeat_actions.date') }}</div>
                                <el-date-picker
                                        v-model="repeat.types.workday.values.date"
                                        :picker-options="picker_options"
                                        size="mini"
                                        format="dd-MM-yyyy"
                                        value-format="yyyy-MM-dd"
                                        type="date">
                                </el-date-picker>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="model.combo" class="update-tooltip">
                        <p>{{ $t('tooltip_on_updating_repeat') }}</p>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import moment from "moment";

    export default {
        mixins: [helper],
        data() {
            return {
                model: this.$parent.time.model,
                errors: {},
                repeat: {
                    show: false,
                    type: 'every',
                    types: {
                        every: {
                            type: 'date',
                            values: {
                                date: null,
                                count: 1
                            }
                        },
                        workday: {
                            type: 'date',
                            values: {
                                date: null
                            }
                        }
                    }
                },
                picker_options: {
                    disabledDate: (time) => {
                        return moment.duration(moment(time, 'DD-MM-YYYY').diff(moment(this.model.day, 'DD-MM-YYYY'))).asHours() <= 0;
                    }
                }
            }
        },
        methods: {
            close(){
                this.$parent.toggleTime(this.action);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else{
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/graphic', {...model, repeat: this.repeat}).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getGraphic();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else if (data.errors){
                        this.errors = data.errors;
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/graphic/' + id, model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getGraphic();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            displayDayName(){
                return moment(this.model.day, 'DD-MM-YYYY').format('dddd');
            },
            displayDay(){
                return moment(this.model.day, 'DD-MM-YYYY').format('dddd, DD MMMM YYYY');
            }
        },
        computed: {
            settings: function() {
                return this.$parent.settings;
            },
            action: function () {
                return this.$parent.time.action;
            },
            users: function () {
                return this.$parent.users;
            },
            rooms: function () {
                return this.$parent.rooms;
            }
        }
    }
</script>