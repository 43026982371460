<template>
    <div class="notification-item-content">
        <span>{{ $t('notifications.log_hours_declined.title') }}</span>
        <el-button v-if="!notification.is_read" @click="markRead()" type="primary" size="mini" icon="el-icon-view" circle></el-button>
    </div>
</template>

<script>
    export default {
        props: {
            notification: {
                type: Object
            }
        },
        methods: {
            markRead(){
                this.$parent.markRead(this.notification.id);
            }
        }
    }
</script>