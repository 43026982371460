<template>
    <div class="complain-section">
        <div class="header">
            <router-link tag="div" :to="{name: 'login'}" class="header-logo">
                <img src="../../images/logo.svg" alt="">
            </router-link>
        </div>
        <div class="complain-form">
            <div :class="`form-field ${$HasError(errors, 'company_code') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('company_code') }} </div>
                <el-input size="medium" v-model="model.company_code"></el-input>
                <div v-if="$HasError(errors, 'company_code')" class="field-error">
                    <span>{{ $GetError(errors, 'company_code') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'name') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('your_name') }} </div>
                <el-input size="medium" v-model="model.name"></el-input>
                <div v-if="$HasError(errors, 'name')" class="field-error">
                    <span>{{ $GetError(errors, 'name') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'treatment_at') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('treatment_at') }} </div>
                <el-date-picker
                        v-model="model.treatment_at"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        type="date">
                </el-date-picker>
                <div v-if="$HasError(errors, 'treatment_at')" class="field-error">
                    <span>{{ $GetError(errors, 'treatment_at') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'email') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('your_email') }} </div>
                <el-input size="medium" v-model="model.email"></el-input>
                <div v-if="$HasError(errors, 'email')" class="field-error">
                    <span>{{ $GetError(errors, 'email') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'phone') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('your_phone') }} </div>
                <el-input size="medium" v-model="model.phone"></el-input>
                <div v-if="$HasError(errors, 'phone')" class="field-error">
                    <span>{{ $GetError(errors, 'phone') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'topic') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('topic') }} </div>
                <el-input size="medium" v-model="model.topic"></el-input>
                <div v-if="$HasError(errors, 'topic')" class="field-error">
                    <span>{{ $GetError(errors, 'topic') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'content') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('content') }}</div>
                <vue-editor
                        :editorOptions="editorOptions"
                        @image-added="$Upload"
                        useCustomImageHandler
                        v-model="model.content"
                ></vue-editor>
                <div v-if="$HasError(errors, 'content')" class="field-error">
                    <span>{{ $GetError(errors, 'content') }}</span>
                </div>
            </div>
            <div class="submit-area">
                <el-button @click="submit()" size="medium" type="success" round>{{ $t('submit') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../mixins/helper";
    import {$http} from "../../hooks/api";
    import {base_url} from "../../constants/api";
    import {Message} from "element-ui";
    import { VueEditor } from "vue2-editor";

    export default {
        mixins: [helper],
        components: {VueEditor},
        data() {
            return {
                editorOptions: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                },
                model: {
                    company_code: null,
                    name: null,
                    email: null,
                    phone: null,
                    topic: null,
                    treatment_at: null,
                    content: null
                },
                errors: {}
            }
        },
        methods: {
            submit(){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/complain', this.model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.model = {
                            company_code: null,
                            name: null,
                            email: null,
                            phone: null,
                            topic: null,
                            treatment_at: null,
                            content: null
                        };

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>