<template>
    <section class="checklist-overview-section default-section">
        <div v-if="active" class="back-to-overview" @click="back()">
            <img src="../../../../images/back.svg" alt="">
            <span>{{ $t('checklist_overview.back') }}</span>
        </div>
        <div v-if="!active" class="section-title">
            <h1>{{ $t('checklist_overview.description') }}</h1>
        </div>
        <div v-if="!active" class="submits-area">
            <div class="submits-area-title">
                <p>{{ moment().format('dddd, D MMMM YYYY') }}</p>
            </div>
            <div class="submits-area-content">
                <div v-if="submits.length" class="submits-full">
                    <div v-for="(submit, key) in submits" :key="'submit-' + key">
                        <p @click="setActive(submit)" v-html="getTitle(submit)"></p>
                    </div>
                </div>
                <div v-else class="submits-empty">
                    <p>{{ $t('checklist_overview.empty') }}</p>
                </div>
            </div>
        </div>
        <div v-else class="submit-area">
            <div class="submit-area-title">
                <p>{{ submitTitle() }}</p>
            </div>
            <div class="submit-area-content">
                <div class="submit-area-step">
                    <h1>{{ $t('checklist_submit.step_one') }}</h1>
                    <el-checkbox-group v-model="fields" @change="chooseField">
                        <el-checkbox
                                v-for="field in active.type.fields"
                                :key="'field-' + field.id"
                                :label="field.id"
                        >{{ field.name }}</el-checkbox>
                    </el-checkbox-group>
                    <el-checkbox :indeterminate="indeterminate" v-model="all" @change="chooseAll">{{ all ? $t('checklist_submit.unselect_all') : $t('checklist_submit.select_all') }}</el-checkbox>
                </div>
                <div class="submit-area-step">
                    <h1>{{ $t('checklist_submit.step_two') }}</h1>
                    <el-checkbox v-model="agree">{{ $t('checklist_submit.agree', {full_name: $FullName($User()), date: moment(active.generated_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}) }}</el-checkbox>
                    <el-button :disabled="!agree" @click="store()" size="medium" type="primary" round>{{ $t('submit') }}</el-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import moment from 'moment';
    import {Message} from 'element-ui';

    export default {
        mixins: [helper],
        beforeMount() {
            this.getSubmits();
        },
        data(){
            return {
                moment: moment,
                submits: [],
                active: null,
                fields: [],
                indeterminate: false,
                all: false,
                agree: false
            }
        },
        methods: {
            getSubmits(){
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/checklist-submit').then(({data}) => {
                    this.submits = data.submits;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            getTitle(submit){
                if (submit.generated_at === moment().format('YYYY-MM-DD')){
                    return submit.room.name;
                }

                let generated_at = moment(submit.generated_at, 'YYYY-MM-DD');
                let delayed_days = moment().diff(generated_at, 'days');
                let class_name = delayed_days === 1 ? 'delay-orange' : 'delay-red';

                return submit.room.name + ' - <span class="' + class_name + '">' + generated_at.format('DD/MM') + '</span>';
            },
            submitTitle(){
                return this.$t('checklist_submit.title', {
                    type: this.$t('frequency_types.' + this.active.type.name),
                    room: this.active.room.name,
                    date: moment(this.active.generated_at, 'YYYY-MM-DD').format('D MMMM YYYY')
                });
            },
            setActive(value){
                this.active = value;
            },
            back(){
                this.active = null;
                this.fields = [];
                this.agree = false;
            },
            chooseAll(value){
                this.fields = value ? this.active.type.fields.map((field) => {
                    return field.id;
                }) : [];
                this.indeterminate = false;
            },
            chooseField(value){
                let count = value.length;
                this.indeterminate = count > 0 && count < this.active.type.fields.length;
                this.all = count === this.active.type.fields.length;
            },
            store(){
                let data = {
                    id: this.active.id,
                    fields: this.fields
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/checklist-submit', data).then(({data}) => {
                    if (data.success){
                        this.back();
                        this.getSubmits();
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>