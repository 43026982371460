<template>
    <section class="files-section default-section">
        <div class="section-title">
            <h1>{{ $t('files_and_folders') }}</h1>
        </div>
        <div v-if="!category" class="first-area">
            <div class="breadcrumb">
                <span @click="back()" class="active">{{ $t('staff') }}</span>
                <span>></span>
                <span>{{ $FullName(user) }}</span>
                <span>></span>
                <span>{{ $t('files_and_folders') }}</span>
            </div>
            <hr>
            <div class="categories">
                <div class="category" v-for="(item, key) in categories" :key="'category-' + key">
                    <div class="category-content" @click="setCategory(item.value)">
                        <img src="../../../../../images/folder.svg" alt="">
                        <span>{{ item.label }}</span>
                    </div>
                </div>
            </div>
            <Upload
                    ref="uploader"
                    :action="() => reload()"
                    :uploaded="[]"
                    :upload_errors="upload_errors"
                    :category="true"
                    :key="refresh"
            ></Upload>
            <el-button @click="upload()" size="medium" type="primary" round>{{ $t('upload') }}</el-button>
        </div>
        <div v-else class="second-area">
            <div class="breadcrumb">
                <span @click="back()" class="active">{{ $t('staff') }}</span>
                <span>></span>
                <span>{{ $FullName(user) }}</span>
                <span>></span>
                <span @click="setCategory(null)" class="active">{{ $t('files_and_folders') }}</span>
                <span>></span>
                <span>{{ $t('uploader.categories.' + category) }}</span>
            </div>
            <hr>
            <div class="items-and-action">
                <div :class="`action-area ${edit_mode ? 'active' : ''}`" @click="toggleEditMode()">
                    <i class="el-icon-edit"></i>
                    <button>{{ $t('edit_mode') }}</button>
                </div>
                <div class="items-area">
                    <div class="file-item" v-for="(file, key) in category_files" :key="'file-item-' + key">
                        <div class="file-item-content" @click="view(file)">
                            <div class="left-area">
                                <img src="../../../../../images/file.svg" alt="">
                                <el-input v-if="edit_mode && uploadedByMe(file)" size="small" v-model="getFile(file.id).name"></el-input>
                                <span v-else>{{ file.name }}</span>
                            </div>
                            <div class="right-area">
                                <span>{{ $GetFileExtension(file.path) }}</span>
                            </div>
                        </div>
                        <div class="file-item-more" v-if="edit_mode && uploadedByMe(file)">
                            <el-select
                                    v-model="getFile(file.id).category"
                                    filterable
                                    size="small"
                                    remote
                                    reserve-keyword
                                    :placeholder="$t('uploader.category_placeholder')">
                                <el-option
                                        v-for="category in categories"
                                        :key="'updated-category-' + category.value"
                                        :label="category.label"
                                        :value="category.value"
                                ></el-option>
                            </el-select>
                            <el-button @click="update(getFile(file.id))" size="small" type="primary" icon="el-icon-check" circle></el-button>
                            <el-popconfirm
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('delete_question')"
                                    @confirm="destroy(getFile(file.id))"
                            >
                                <el-button slot="reference" size="small" type="danger" icon="el-icon-delete" circle></el-button>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
            </div>
            <Upload
                    ref="uploader"
                    :action="() => reload()"
                    :uploaded="[]"
                    :upload_errors="upload_errors"
                    :category="false"
                    :key="refresh"
            ></Upload>
            <el-button @click="upload()" size="medium" type="primary" round>{{ $t('upload') }}</el-button>
        </div>
    </section>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import Upload from "../../../../../components/Upload";
    import objectToFormData from "../../../../../mixins/objectToFormData";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        props: {
            user: {
                type: Object
            }
        },
        mixins: [helper],
        components: {Upload},
        data(){
            return {
                refresh: 0,
                category: null,
                upload_errors: {},
                edit_mode: false,
                cloned_files: []
            }
        },
        methods: {
            back(){
                this.$parent.toggleFiles();
            },
            view(file){
                if (!this.edit_mode){
                    window.open(file.fullPath,'_blank');
                }
            },
            uploadedByMe(file){
                return file['uploaded_by'] === this.$User().id;
            },
            setCategory(category){
                this.category = category;

                if (category === null){
                    this.edit_mode = false;
                    this.cloned_files = [];
                }
            },
            toggleEditMode(){
                if (this.edit_mode){
                    this.edit_mode = false;
                    this.cloned_files = [];
                } else {
                    this.edit_mode = true;
                    this.cloned_files = JSON.parse(JSON.stringify(this.category_files));
                }
            },
            getFile(id){
                return this.cloned_files.find(f => f.id === id);
            },
            async reload(){
                await this.$parent.getStaff();
                this.$parent.files.user = this.$parent.staff.find(u => u.id === this.user.id);
                if (this.cloned_files.length){
                    this.cloned_files = JSON.parse(JSON.stringify(this.$parent.files.user.files.filter(file => file.category === this.category)));
                }
                this.refresh++;
            },
            upload(){
                let files = this.$refs.uploader.files.new;

                if (files.length) {
                    if (this.category){
                        files = files.map(file => {
                            return {
                                ...file,
                                category: this.category
                            };
                        });
                    }

                    let data = objectToFormData({
                        files: files,
                        user_id: this.user.id
                    });

                    this.$store.commit('setPreloader', true);
                    $http().post(base_url + '/api/client/director/staff-file', data).then(({data}) => {
                        if (data.success){
                            this.reload();

                            Message({
                                message: data.message,
                                showClose: true,
                                type: 'success'
                            });
                        } else {
                            this.upload_errors = data.upload_errors;
                        }
                        this.$store.commit('setPreloader', false);
                    }).catch(() => {
                        this.$store.commit('setPreloader', false);
                    });
                }
            },
            update(file){
                let data = {
                    files: [file],
                    has_category: true
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/upload', data).then(({data}) => {
                    if (data.success){
                        this.reload();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Object.keys(data.errors).map(id => {
                            Message({
                                message: data.errors[id],
                                showClose: true,
                                duration: 10000,
                                type: 'warning'
                            });
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            destroy(file){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/upload/' + file.id).then(({data}) => {
                    if (data.success){
                        this.reload();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            categories: function () {
                let categories = this.$t('uploader.categories');
                let result = [];

                Object.keys(categories).map((value) => {
                    result.push({
                        value: value,
                        label: categories[value]
                    });
                });

                return result;
            },
            category_files: function () {
                return this.user.files.filter(file => file.category === this.category);
            }
        }
    }
</script>