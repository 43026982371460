<template>
    <div class="popup crud-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ action === 'create' ? $t('create') : $t('role_and_permissions') }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="crud-form" v-if="action === 'create'">
                    <div class="form-field flex-center">
                        <div class="avatar-area">
                            <div class="file-area">
                                <input type="file" @change="changeAvatar"/>
                            </div>
                            <div class="image-area">
                                <img :src="avatar" alt="">
                            </div>
                            <div class="shadow-area">
                                <img src="../../../../../images/upload.png" alt="">
                            </div>
                        </div>
                        <div v-if="$HasError(errors, 'avatar')" class="field-error">
                            <span>{{ $GetError(errors, 'avatar') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'name') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('name') }} </div>
                        <el-input size="medium" v-model="model.name"></el-input>
                        <div v-if="$HasError(errors, 'name')" class="field-error">
                            <span>{{ $GetError(errors, 'name') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'surname') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('surname') }} </div>
                        <el-input size="medium" v-model="model.surname"></el-input>
                        <div v-if="$HasError(errors, 'surname')" class="field-error">
                            <span>{{ $GetError(errors, 'surname') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'role') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('role') }} </div>
                        <el-select
                                v-model="model.role"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="role in roles"
                                    :key="'role-' + role"
                                    :label="$GetRoleName(role, 'male')"
                                    :value="role">
                            </el-option>
                        </el-select>
                        <div v-if="$HasError(errors, 'role')" class="field-error">
                            <span>{{ $GetError(errors, 'role') }}</span>
                        </div>
                    </div>
                    <div class="form-field">
                        <div class="field-label">{{ $t('permissions') }} </div>
                        <el-select
                                v-model="model.permissions"
                                multiple
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="permission in permissions"
                                    :key="'permission-' + permission"
                                    :label="$t(permission)"
                                    :value="permission">
                            </el-option>
                        </el-select>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'gender') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('gender') }} </div>
                        <el-radio-group v-model="model.gender" size="medium">
                            <el-radio label="male" border>{{ $t('genders.male') }}</el-radio>
                            <el-radio label="female" border>{{ $t('genders.female') }}</el-radio>
                        </el-radio-group>
                        <div v-if="$HasError(errors, 'gender')" class="field-error">
                            <span>{{ $GetError(errors, 'gender') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'address') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('address') }} </div>
                        <el-input size="medium" v-model="model.address"></el-input>
                        <div v-if="$HasError(errors, 'address')" class="field-error">
                            <span>{{ $GetError(errors, 'address') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'postal_code') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('postal_code') }} </div>
                        <el-input size="medium" v-model="model.postal_code"></el-input>
                        <div v-if="$HasError(errors, 'postal_code')" class="field-error">
                            <span>{{ $GetError(errors, 'postal_code') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'place') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('place') }} </div>
                        <el-input size="medium" v-model="model.place"></el-input>
                        <div v-if="$HasError(errors, 'place')" class="field-error">
                            <span>{{ $GetError(errors, 'place') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'bsn_number') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('bsn_number') }} </div>
                        <el-input size="medium" v-model="model.bsn_number"></el-input>
                        <div v-if="$HasError(errors, 'bsn_number')" class="field-error">
                            <span>{{ $GetError(errors, 'bsn_number') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'birthday') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('birthday') }} </div>
                        <el-date-picker
                                v-model="model.birthday"
                                format="dd-MM-yyyy"
                                value-format="yyyy-MM-dd"
                                type="date">
                        </el-date-picker>
                        <div v-if="$HasError(errors, 'birthday')" class="field-error">
                            <span>{{ $GetError(errors, 'birthday') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'email') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('email') }} </div>
                        <el-input size="medium" v-model="model.email"></el-input>
                        <div v-if="$HasError(errors, 'email')" class="field-error">
                            <span>{{ $GetError(errors, 'email') }}</span>
                        </div>
                    </div>
                </div>
                <div class="crud-form" v-else>
                    <div :class="`form-field ${$HasError(errors, 'role') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('role') }} </div>
                        <el-select
                                v-model="model.role"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="role in roles"
                                    :key="'role-' + role"
                                    :label="$GetRoleName(role, 'male')"
                                    :value="role">
                            </el-option>
                        </el-select>
                        <div v-if="$HasError(errors, 'role')" class="field-error">
                            <span>{{ $GetError(errors, 'role') }}</span>
                        </div>
                    </div>
                    <div class="form-field">
                        <div class="field-label">{{ $t('permissions') }} </div>
                        <el-select
                                v-model="model.permissions"
                                multiple
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="permission in permissions"
                                    :key="'permission-' + permission"
                                    :label="$t(permission)"
                                    :value="permission">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="form-field">
                        <div class="field-label">{{ `${$t('free_hours.label')} (${moment().format('Y')})` }}</div>
                        <el-input-number
                            v-model="model.free_hours"
                            controls-position="right"
                            size="medium"
                            :min="0"
                        />
                    </div>
                    <div class="form-field">
                        <div class="field-label">{{ `${$t('free_hours.label')} (${moment().subtract(1, 'years').format('Y')})` }}</div>
                        <el-input-number
                            v-model="model.remaining_free_hours"
                            controls-position="right"
                            size="medium"
                            :min="0"
                        />
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'inactive') ? 'field-invalid' : ''}`">
                        <el-checkbox v-model="model.inactive">Inactive</el-checkbox>
                        <div v-if="$HasError(errors, 'inactive')" class="field-error">
                            <span>{{ $GetError(errors, 'inactive') }}</span>
                        </div>
                    </div>
                    <div class="form-field">
                        <el-popconfirm
                                icon-color="red"
                                icon="el-icon-info"
                                :cancel-button-text="$t('no')"
                                :confirm-button-text="$t('yes')"
                                :title="$t('delete_question_user')"
                                @confirm="destroy()"
                        >
                            <p class="remove-user-link" slot="reference">{{ $t('remove_user') }}</p>
                        </el-popconfirm>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";
    import objectToFormData from "../../../../../mixins/objectToFormData";
    import moment from "moment";

    export default {
        mixins: [helper],
        data() {
            return {
                model: this.$parent.crud.model,
                moment: moment,
                errors: {}
            }
        },
        methods: {
            close(){
                this.$parent.toggleCrud(this.action);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else{
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                let data = objectToFormData(model);

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/director/staff', data).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.staff.unshift(data.user);

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(data, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/director/staff/' + id, data).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.staff.splice(this.$parent.staff.findIndex(s => s.id === id), 1, data.user);

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            destroy(){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/director/staff/' + this.model.id).then(({data}) => {
                    if (data.success){
                        this.$parent.staff.splice(this.$parent.staff.findIndex(s => s.id === this.model.id), 1);
                        this.close();
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            changeAvatar(e) {
                this.model.avatar = e.target.files[0];
            }
        },
        computed: {
            action: function () {
                return this.$parent.crud.action;
            },
            avatar: function () {
                if (this.model.avatar){
                    if (typeof this.model.avatar === 'string'){
                        return this.model.avatar;
                    }

                    return URL.createObjectURL(this.model.avatar);
                }

                if (this.model.gender === 'female'){
                    return require('../../../../../images/female.png');
                }

                return require('../../../../../images/male.png');
            },
            roles: function () {
                return this.$parent.roles;
            },
            permissions: function () {
                return this.$parent.permissions;
            }
        }
    }
</script>