<template>
    <div class="popup edit-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ title }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="edit-form">
                    <div :class="`form-field ${$HasError(errors, 'amount') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('amount') }} (€)</div>
                        <el-input-number
                                v-model="edit.amount"
                                controls-position="right"
                                size="medium"
                                :step="100"
                                :min="0"
                        ></el-input-number>
                        <div v-if="$HasError(errors, 'amount')" class="field-error">
                            <span>{{ $GetError(errors, 'amount') }}</span>
                        </div>
                    </div>
                    <div class="form-field">
                        <div class="field-label">{{ $t('specification') }} </div>
                        <div class="specification-area">
                            <el-button icon="el-icon-upload" type="primary" size="medium">{{ specification }}</el-button>
                            <input type="file" @change="changeSpecification"/>
                        </div>
                        <div v-if="$HasError(errors, 'specification')" class="field-error">
                            <span>{{ $GetError(errors, 'specification') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="save()" size="medium" type="success" round>{{ $t('save') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import moment from "moment";
    import objectToFormData from "../../../../mixins/objectToFormData";

    export default {
        mixins: [helper],
        data() {
            return {
                edit: this.$parent.edit,
                errors: {}
            }
        },
        methods: {
            close(){
                this.$parent.toggleEdit();
            },
            changeSpecification(e){
                this.edit.specification = e.target.files[0];
            },
            save(){
                let data = objectToFormData({
                    date: moment(this.$parent.date).format('YYYY-MM'),
                    user_id: this.edit.user.id,
                    amount: this.edit.amount,
                    specification: this.edit.specification,
                    status: 'save'
                });

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.errors = {};

                        this.$parent.getEmployees();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else if (data.errors){
                        this.errors = data.errors;
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            title: function () {
                return this.$t('edit_title', {
                    month: moment(this.$parent.date).format('MMMM'),
                    user: this.$FullName(this.edit.user)
                });
            },
            specification: function () {
                if (this.edit.specification){
                    if (typeof this.edit.specification === 'string'){
                        return this.$t('change');
                    }
                    return this.edit.specification.name;
                }
                return this.$t('choose');
            }
        }
    }
</script>