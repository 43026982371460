<template>
    <div class="popup crud-popup frequency-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ title }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div v-if="step === 'frequency'" class="crud-form">
                    <div class="form-field">
                        <div class="field-label">{{ $t('frequency_label') }}</div>
                        <el-select
                                @change="changeName()"
                                v-model="model.name"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="frequency_type in frequency_types"
                                    :key="'frequency-type-' + frequency_type.value"
                                    :label="frequency_type.label"
                                    :value="frequency_type.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="separator">{{ frequencySeparator() }}</div>
                    <div class="form-field">
                        <div v-if="model.name === 'daily'" class="value-content">
                            <span>{{ $t('frequency_daily_description') }}</span>
                        </div>
                        <div v-else-if="['weekly', 'two_weekly'].includes(model.name)" class="value-content">
                            <el-select
                                    v-model="model.value"
                                    filterable
                                    remote
                                    reserve-keyword
                                    placeholder="">
                                <el-option
                                        v-for="week_day in week_days"
                                        :key="'week-day-' + week_day.value"
                                        :label="week_day.label"
                                        :value="week_day.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div v-else class="value-content">
                            <el-date-picker
                                    v-model="model.value"
                                    format="dd-MM-yyyy"
                                    value-format="yyyy-MM-dd"
                                    type="date">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div v-else class="crud-form fields-form">
                    <div class="form-field" v-for="(field, key) in model.fields" :key="'field-' + key">
                        <el-input size="medium" v-model="field.name" :placeholder="$t('name')"></el-input>
                        <el-button @click="removeField(key)" icon="el-icon-delete" type="danger" size="medium" circle></el-button>
                    </div>
                    <el-button @click="addField()" icon="el-icon-plus" type="primary" size="medium" circle></el-button>
                </div>
            </div>
            <div v-if="step === 'frequency'" class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="next()" :disabled="!can_next" size="medium" type="success" round>{{ $t('next') }}</el-button>
            </div>
            <div v-else class="popup-footer">
                <el-button @click="back()" size="medium" type="danger" round>{{ $t('back') }}</el-button>
                <el-button @click="submit()" :disabled="!can_submit" size="medium" type="success" round>{{ $t(action) }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        data() {
            return {
                model: this.$parent.type_crud.model,
                step: 'frequency'
            }
        },
        methods: {
            close(){
                this.$parent.toggleTypeCrud(this.action);
            },
            changeName(){
                this.model.value = null;
            },
            frequencySeparator(){
                if (['daily', 'weekly', 'two_weekly'].includes(this.model.name)){
                    return this.$t('frequency_separator_one');
                }
                return this.$t('frequency_separator_two');
            },
            next(){
                this.step = 'fields';
            },
            back(){
                this.step = 'frequency';
            },
            addField(){
                this.model.fields.push({name: null});
            },
            removeField(key){
                this.model.fields.splice(key, 1);
            },
            submit(){
                if (this.action === 'create'){
                    this.create(this.model);
                } else {
                    this.update(this.model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/checklist-room-type', model).then(({data}) => {
                    if (data.success){
                        this.$parent.getRooms();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.step = data.step;

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/checklist-room-type/' + id, model).then(({data}) => {
                    if (data.success){
                        this.$parent.getRooms();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.step = data.step;

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.type_crud.action;
            },
            title: function () {
                if (this.action === 'create'){
                    return this.$t('frequency_steps.' + this.step);
                }
                return this.$t('update');
            },
            can_next: function () {
                if (this.model.name === 'daily'){
                    return true;
                }
                return this.model.value !== null;
            },
            can_submit: function () {
                return this.model.fields.filter((field) => {
                    if (typeof field.name === 'string'){
                        return field.name.trim() !== '';
                    }
                    return field.name !== null;
                }).length === this.model.fields.length && this.model.fields.length > 0;
            },
            frequency_types: function () {
                let array = [];

                Object.keys(this.$t('frequency_types')).map(value => {
                    array.push({
                        value: value,
                        label: this.$t('frequency_types.' + value)
                    });
                });

                return array;
            },
            week_days: function () {
                let array = [];

                Object.keys(this.$t('week_days')).map(value => {
                    array.push({
                        value: value,
                        label: this.$t('week_days.' + value)
                    });
                });

                return array;
            }
        }
    }
</script>