import store from '../store';
import moment from "moment";
import {roles} from "../constants/roles";
import {$http} from "../hooks/api";
import {base_url} from "../constants/api";
import {Message} from "element-ui";

export default {
    methods: {
        $User() {
            return store.getters.getUser;
        },
        $Role() {
            return store.getters.getUser.role.name;
        },
        $Company() {
            return store.getters.getUser.company;
        },
        $Device() {
            return store.getters.getDevice;
        },
        $HasError(errors, field){
            return errors[field] !== undefined;
        },
        $GetError(errors, field){
            return errors[field][0];
        },
        $RemoveError(errors, field){
            if (this.$HasError(errors, field)){
                delete errors[field];
            }
        },
        $InvalidField(v, errors, field){
            return v[field].$error || this.$HasError(errors, field);
        },
        $Date(date, format){
            return moment(date).format(format) === 'Invalid date' ? null : moment(date).format(format);
        },
        $Avatar(user){
            return user.avatar ? user.avatar : require('../images/' + user.gender + '.png');
        },
        $FullName(user){
            return user.name + ' ' + user.surname;
        },
        $ShortName(user){
            return user.name[0] + '. ' + user.surname;
        },
        $RoleIs(role){
            return this.$Role() === roles[role];
        },
        $AccessToRouteByRoles(route){
            return this.$router.options.routes.find(r => r.name === route).meta.roles.indexOf(this.$Role()) !== -1;
        },
        $GetRoleName(role, gender){
            return this.$t(gender + '.' + roles[role]);
        },
        $HasPermission(permission){
            return this.$User().permissions.findIndex(p => p.name === permission) !== -1;
        },
        $GroupBy(xs, key){
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        $IsToday(day, format = 'DD-MM-YYYY'){
            return day === moment().format(format);
        },
        $Interval(start, end, format = 'HH:mm', unit = 'minutes'){
            let a = moment(start, format);
            let b = moment(end, format);
            return b.diff(a, unit);
        },
        $PasteArraysOfObject(object){
            let result = [];

            for (let value of object){
                if (Array.isArray(value)){
                    for (let element of value){
                        result.push(element);
                    }
                } else{
                    result.push(value);
                }
            }

            return result;
        },
        $TimeToMinutes(time) {
            return moment.duration(moment(time, 'HH:mm').diff(moment('00:00', 'HH:mm'))).asMinutes();
        },
        $DisplayRange(item){
            return item['start'] && item['end'] ? item['start'] + ' - ' + item['end'] : '00:00 - 00:00';
        },
        $DisplayDatesRange(start, end){
            let a = moment(start).format('YYYY-MM-DD');
            let b = moment(end).format('YYYY-MM-DD');
            let diff_in_days = moment(b).diff(moment(a), 'days');

            if (start.search(':') === -1){
                if (diff_in_days < 2){
                    return moment(start).format('dddd, DD MMMM');
                }

                if (moment(start).format('MM') === moment(end).format('MM')){
                    return moment(start).format('DD') +
                        ' ' + this.$t('to') + ' ' +
                        moment(end).subtract(1, 'days').format('DD') +
                        ' ' + this.$t('of') + ' ' +
                        moment(start).format('MMMM');
                }

                return moment(start).format('DD MMMM') +
                    ' ' + this.$t('to') + ' ' +
                    moment(end).subtract(1, 'days').format('DD MMMM');
            }

            if (diff_in_days < 1){
                return moment(start).format('dddd, DD MMMM HH:mm') +
                    ' - ' + moment(end).format('HH:mm');
            }

            return moment(start).format('dddd, DD MMMM HH:mm') +
                ' - ' + moment(end).format('dddd, DD MMMM HH:mm');
        },
        $DisplayID(id, prefix){
            if (id < 10){
                return prefix + '00' + id;
            } else if (id < 100){
                return prefix + '0' + id;
            } else {
                return prefix + id;
            }
        },
        $Hours(){
            let hours = [];

            for (let i = 0; i <= 11; i++){
                hours.push(i > 0 ? i + ' AM' : null);
            }

            hours.push('12 PM');

            for (let i = 1; i <= 11; i++){
                hours.push(i + ' PM');
            }

            return hours;
        },
        $GMT(){
            let gmt = moment().format().slice(-6);

            if (gmt.slice(-2) === '00'){
                gmt = gmt.slice(0, 3);
            }

            return 'GMT' + gmt;
        },
        $WorkingDays(){
            return [
                '1nd',
                '2nd',
                '3nd',
                '4nd',
                '5nd',
                '6nd',
                '7nd'
            ];
        },
        $Slug(text){
            text = text.toLowerCase();
            text = text.split(' ').join('-');
            text = text.split('/').join('-');
            return text;
        },
        $GetFileExtension(path){
            return path.split('.').reverse()[0];
        },
        $Upload(file, Editor, cursorLocation, resetUploader){
            let formData = new FormData();
            formData.append("image", file);

            store.commit('setPreloader', true);
            $http().post(base_url + '/api/upload', formData).then(({data}) => {
                if (data.success){
                    Editor.insertEmbed(cursorLocation, "image", data.path);
                    resetUploader();
                } else {
                    Message({
                        message: data.error,
                        showClose: true,
                        type: 'warning'
                    });
                }
                store.commit('setPreloader', false);
            }).catch(() => {
                store.commit('setPreloader', false);
            });
        }
    }
};