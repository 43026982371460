<template>
    <div class="interview-crud">
        <div class="back-area" @click="back()">
            <img src="../../../../images/back.svg" alt="">
            <span>{{ $t('interview.back') }}</span>
        </div>
        <div class="title-area">
            <h1>{{ $t('interview.create_button') }}</h1>
        </div>
        <div class="form-fields">
            <div :class="`form-field ${$HasError(errors, 'user_id') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('interview.fields.user_id') }}</div>
                <el-select
                        @change="changeUser"
                        v-model="model.user_id"
                        :disabled="action === 'update'"
                        filterable
                        remote
                        reserve-keyword>
                    <el-option
                            class="option-with-image"
                            v-for="user in users"
                            :key="'user-' + user.id"
                            :label="$FullName(user)"
                            :value="user.id">
                        <img :src="$Avatar(user)" alt="">
                        <span>{{ $FullName(user) }}</span>
                    </el-option>
                </el-select>
                <div v-if="$HasError(errors, 'user_id')" class="field-error">
                    <span>{{ $GetError(errors, 'user_id') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'job_title') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('interview.fields.job_title') }}</div>
                <el-select
                        v-model="model.job_title"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="">
                    <el-option
                            v-for="job_title in job_titles"
                            :key="'job-title-' + job_title.value"
                            :label="job_title.label"
                            :value="job_title.value">
                    </el-option>
                </el-select>
                <div v-if="$HasError(errors, 'job_title')" class="field-error">
                    <span>{{ $GetError(errors, 'job_title') }}</span>
                </div>
            </div>
            <div class="form-field">
                <div class="field-label">{{ $t('interview.fields.range') }}</div>
                <el-date-picker
                        v-model="model.range"
                        type="daterange"
                        format="dd-MMM-yyyy"
                        value-format="yyyy-MM-dd">
                </el-date-picker>
                <div v-if="$HasError(errors, 'start')" class="field-error">
                    <span>{{ $GetError(errors, 'start') }}</span>
                </div>
                <div v-if="$HasError(errors, 'end')" class="field-error">
                    <span>{{ $GetError(errors, 'end') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'date') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('interview.fields.date') }}</div>
                <el-date-picker
                        v-model="model.date"
                        type="date"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd">
                </el-date-picker>
                <div v-if="$HasError(errors, 'date')" class="field-error">
                    <span>{{ $GetError(errors, 'date') }}</span>
                </div>
            </div>
            <div v-for="({label, disabled}, field) in dynamic_fields" :key="field" class="form-field is-dynamic">
                <div class="field-label">
                    <span v-if="!active.field || active.field !== field">
                        {{ label }}
                    </span>
                    <i
                        @click="setActive(field, label)"
                        v-if="!active.field || active.field !== field"
                        :class="`el-icon-edit${active.field && active.field !== field ? ' disabled' : ''}`"
                    />
                    <el-input
                        v-else
                        size="small"
                        v-model="active.label"
                    />
                    <el-button
                        @click="setActive('', '')"
                        v-if="active.field === field"
                        :disabled="active.spinner"
                        icon="el-icon-close"
                        type="danger"
                        size="small"
                        circle
                    />
                    <el-button
                        v-if="active.field === field"
                        :disabled="active.spinner"
                        :loading="active.spinner"
                        @click="saveLabel()"
                        icon="el-icon-check"
                        type="primary"
                        size="small"
                        circle
                    />
                </div>
                <el-input
                    :rows="3"
                    type="textarea"
                    :disabled="disabled"
                    v-model="model[field]"
                />
            </div>
        </div>
        <div v-if="action === 'create'" class="form-action">
            <el-button @click="submit(false)" size="medium" type="primary" round>{{ $t('interview.actions.save') }}</el-button>
            <el-popconfirm
                    icon-color="red"
                    icon="el-icon-info"
                    :cancel-button-text="$t('no')"
                    :confirm-button-text="$t('yes')"
                    :title="$t('interview.share_question')"
                    @cancel="submit(false)"
                    @confirm="submit(true)"
            >
                <el-button slot="reference" size="medium" type="primary" round>{{ $t('interview.actions.save_and_share') }}</el-button>
            </el-popconfirm>
        </div>
        <div v-else class="form-action">
            <el-button @click="submit(model.shared)" size="medium" type="primary" round>{{ $t('interview.actions.save') }}</el-button>
            <el-button v-if="model.shared" @click="submit(false)" size="medium" type="primary" round>{{ $t('interview.actions.revoke_share') }}</el-button>
            <el-popconfirm
                    v-else
                    icon-color="red"
                    icon="el-icon-info"
                    :cancel-button-text="$t('no')"
                    :confirm-button-text="$t('yes')"
                    :title="$t('interview.share_question')"
                    @cancel="submit(false)"
                    @confirm="submit(true)"
            >
                <el-button slot="reference" size="medium" type="primary" round>{{ $t('interview.actions.save_and_share') }}</el-button>
            </el-popconfirm>
        </div>
    </div>
</template>

<script>
    import {locale} from "../../../../constants/localization";
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import $ from 'jquery';

    export default {
        mixins: [helper],
        beforeMount() {
            $(() => {
                $(".el-textarea__inner").each(function () {
                    this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
                }).on("input", function () {
                    this.style.height = "auto";
                    this.style.height = (this.scrollHeight) + "px";
                });
            });
        },
        data() {
            return {
                model: this.$parent.crud.model,
                active: {
                    field: '',
                    label: '',
                    spinner: false
                },
                errors: {}
            }
        },
        methods: {
            back(){
                this.$parent.toggleCrud(this.action);
            },
            setActive(field, label) {
                this.active.field = field;
                this.active.label = label;
            },
            async saveLabel() {
                const payload = {
                    ...this.active,
                    language: locale
                };

                this.active.spinner = true;

                await $http().post(base_url + '/api/client/interview-details', payload);

                await this.$parent.getInterviewDetails(false);

                this.active = {
                    field: '',
                    label: '',
                    spinner: false
                };
            },
            changeUser(id){
                let user = this.users.find(u => u.id === id);

                if (user.last_interview_id !== null){
                    this.model.before = this.$parent.interviews.find(i => i.id === user.last_interview_id).after;
                }

                $(() => {
                    $(".el-textarea__inner").each(function () {
                        this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
                    }).on("input", function () {
                        this.style.height = "auto";
                        this.style.height = (this.scrollHeight) + "px";
                    });
                });
            },
            submit(shared){
                if (this.action === 'create'){
                    this.create(shared);
                } else {
                    this.update(shared);
                }
            },
            create(shared){
                let data = {
                    user_id: this.model.user_id,
                    job_title: this.model.job_title,
                    start: this.model.range ? this.model.range[0] : null,
                    end: this.model.range ? this.model.range[1] : null,
                    date: this.model.date,
                    performance_1: this.model.performance_1,
                    performance_2: this.model.performance_2,
                    performance_3: this.model.performance_3,
                    performance_4: this.model.performance_4,
                    future: this.model.future,
                    after: this.model.after,
                    shared: shared
                };

                let user = this.users.find(u => u.id === this.model.user_id);

                if (user && user.first_interview_id === null){
                    data.before = this.model.before;
                }

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/interview', data).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getInterviews();

                        this.back();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(shared){
                let data = {
                    job_title: this.model.job_title,
                    start: this.model.range ? this.model.range[0] : null,
                    end: this.model.range ? this.model.range[1] : null,
                    date: this.model.date,
                    performance_1: this.model.performance_1,
                    performance_2: this.model.performance_2,
                    performance_3: this.model.performance_3,
                    performance_4: this.model.performance_4,
                    future: this.model.future,
                    after: this.model.after,
                    shared: shared
                };

                let user = this.users.find(u => u.id === this.model.user_id);

                if (user.first_interview_id === this.model.id){
                    data.before = this.model.before;
                }

                this.$store.commit('setPreloader', true);
                $http().put(base_url + '/api/client/interview/' + this.model.id, data).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.getInterviews();

                        this.back();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.crud.action;
            },
            job_titles: function () {
                let job_titles = [];

                Object.keys(this.$t('interview.job_titles')).map(value => {
                    job_titles.push({
                        value: value,
                        label: this.$t('interview.job_titles.' + value)
                    });
                });

                return job_titles;
            },
            before_editable: function () {
                let user = this.users.find(u => u.id === this.model.user_id);

                return user === undefined || [null, this.model.id].includes(user.first_interview_id);
            },
            dynamic_fields: function () {
                const {labels} = this.$parent.details[locale];

                return {
                    performance_1: {
                        label: labels.performance_1 || this.$t('interview.fields.performance_1', {company: this.$Company().name}),
                        disabled: false
                    },
                    performance_2: {
                        label: labels.performance_2 || this.$t('interview.fields.performance_2'),
                        disabled: false
                    },
                    performance_3: {
                        label: labels.performance_3 || this.$t('interview.fields.performance_3'),
                        disabled: false
                    },
                    performance_4: {
                        label: labels.performance_4 || this.$t('interview.fields.performance_4'),
                        disabled: false
                    },
                    future: {
                        label: labels.future || this.$t('interview.fields.future'),
                        disabled: false
                    },
                    before: {
                        label: labels.before || this.$t('interview.fields.before'),
                        disabled: !this.before_editable
                    },
                    after: {
                        label: labels.after || this.$t('interview.fields.after'),
                        disabled: false
                    }
                };
            },
            users: function () {
                return this.$parent.users;
            }
        }
    }
</script>