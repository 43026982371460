<template>
    <section class="companies-section default-section">
        <div class="section-title">
            <h1>{{ $t('companies') }}</h1>
            <el-button @click="toggleCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
        </div>

        <div class="companies-content">
            <div class="table-parent scroll-grey">
                <el-table border :empty-text="$t('no_companies')" :data="data">
                    <el-table-column :label="$t('actions')" class-name="table-actions">
                        <template slot-scope="scope">
                            <el-button @click="update(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
                            <el-popconfirm
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('delete_question')"
                                    @confirm="destroy(scope.row)"
                            >
                                <el-button slot="reference" type="danger" icon="el-icon-delete" circle></el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('name')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('code')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.code }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('male.director')" class-name="image-text">
                        <template slot-scope="scope">
                            <img :src="$Avatar(scope.row.director)" alt="">
                            <span>{{ $FullName(scope.row.director) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('counts.manager')">
                        <template slot-scope="scope">
                            <span>{{ scope.row['team'].manager }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('counts.accountant')">
                        <template slot-scope="scope">
                            <span>{{ scope.row['team'].accountant }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('counts.dentist')">
                        <template slot-scope="scope">
                            <span>{{ scope.row['team'].dentist }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('counts.assistent')">
                        <template slot-scope="scope">
                            <span>{{ scope.row['team'].assistent }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('counts.hygienist')">
                        <template slot-scope="scope">
                            <span>{{ scope.row['team'].hygienist }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('created_at')">
                        <template slot-scope="scope">
                            <span>{{ $Date(scope.row['created_at'], 'DD-MM-YYYY') }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <el-pagination
                    v-if="companies.length > pagination.limit"
                    background
                    layout="pager"
                    @current-change="paginate"
                    :pagerCount="5"
                    :current-page="pagination.page"
                    :page-size="pagination.limit"
                    :total="companies.length">
            </el-pagination>
        </div>

        <transition name="fade">
            <Crud v-if="crud.popup"></Crud>
        </transition>
    </section>
</template>

<script>
    import Crud from "./Crud";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import helper from "../../../../mixins/helper";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        components: {Crud},
        beforeMount() {
            this.getCompanies();
        },
        data() {
            return {
                crud: {
                    popup: false,
                    action: null,
                    company: {},
                    director: {}
                },
                companies: [],
                pagination: {
                    page: 1,
                    limit: 10
                }
            }
        },
        methods: {
            getCompanies() {
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/admin/company').then(({data}) => {
                    this.companies = data.companies;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            paginate(page) {
                this.pagination.page = page;
            },
            update(item){
                let company = {
                    id: item.id,
                    name: item.name,
                    director: item.pickers.director,
                    manager: item.pickers.manager,
                    accountant: item.pickers.accountant,
                    dentist: item.pickers.dentist,
                    assistent: item.pickers.assistent,
                    hygienist: item.pickers.hygienist,
                    start: item.start,
                    end: item.end
                };

                let director = {
                    id: item.director.id,
                    name: item.director.name,
                    surname: item.director.surname,
                    gender: item.director.gender,
                    avatar: item.director.avatar,
                    address: item.director.address,
                    postal_code: item.director.postal_code,
                    place: item.director.place,
                    bsn_number: item.director.bsn_number,
                    email: item.director.email
                };

                this.toggleCrud('update', company, director);
            },
            destroy(item){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/admin/company/' + item.id).then(({data}) => {
                    if (data.success){
                        this.companies.splice(this.companies.findIndex(c => c.id === item.id), 1);
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleCrud(action, company = {}, director = {}) {
                if (action === 'create') {
                    company = {
                        name: null,
                        director: null,
                        manager: null,
                        accountant: null,
                        dentist: null,
                        assistent: null,
                        hygienist: null,
                        start: null,
                        end: null
                    };

                    director = {
                        name: null,
                        surname: null,
                        gender: null,
                        avatar: null,
                        address: null,
                        postal_code: null,
                        place: null,
                        bsn_number: null,
                        birthday: null,
                        email: null
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        company: {},
                        director: {}
                    }
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        company: company,
                        director: director
                    }
                }
            }
        },
        computed: {
            data: function () {
                return this.companies.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            }
        }
    }
</script>