<template>
    <section class="safe-reporting-incident-section default-section">
        <incident-crud v-if="incident_crud.popup"/>
        <div v-else class="safe-reporting-incident-view">
            <div class="safe-reporting-incident-header">
                <h1>{{ $t(tab + '.tab') }}</h1>
                <div v-if="tab === 'incident'" class="tab-actions">
                    <div class="tab-action">
                        <p>{{ $t('incident.year_label') }}</p>
                        <el-select
                                v-model="filters.year"
                                filterable
                                size="small"
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="year in years"
                                    :key="'year-' + year.value"
                                    :label="year.label"
                                    :value="year.value"
                            ></el-option>
                        </el-select>
                    </div>
                    <div class="tab-action">
                        <el-button @click="toggleIncidentCrud('create')" type="primary" size="small" icon="el-icon-plus" circle></el-button>
                        <p>{{ $t('incident.create_button') }}</p>
                    </div>
                </div>
                <div v-else class="tab-actions">
                    <div v-if="$RoleIs('director') || $RoleIs('manager')" class="tab-action">
                        <el-button @click="toggleEvaluationCrud('create')" type="primary" size="small" icon="el-icon-plus" circle></el-button>
                        <p>{{ $t('evaluation.create_button') }}</p>
                    </div>
                </div>
            </div>
            <div class="safe-reporting-incident-tabs">
                <div :class="`safe-reporting-incident-tab ${tab === 'incident' ? 'active' : ''}`" @click="changeTab('incident')">
                    <span>{{ $t('incident.tab') }}</span>
                </div>
                <div :class="`safe-reporting-incident-tab ${tab === 'evaluation' ? 'active' : ''}`" @click="changeTab('evaluation')">
                    <span>{{ $t('evaluation.tab') }}</span>
                </div>
            </div>
            <div v-if="tab === 'incident'" class="safe-reporting-incident-content tab-incident">
                <div class="table-parent scroll-grey">
                    <el-table border :empty-text="$t('no_incidents')" :data="incidents_data">
                        <el-table-column :label="$t('actions')" class-name="table-actions-center">
                            <template slot-scope="scope">
                                <el-button @click="toggleIncident(scope.row)" icon="el-icon-view" type="success" size="mini" circle></el-button>
                                <el-button v-if="$RoleIs('director') || $User().id === scope.row.user_id" @click="updateIncident(scope.row)" icon="el-icon-edit" type="primary" size="mini" circle></el-button>
                                <el-popconfirm
                                        icon-color="red"
                                        icon="el-icon-info"
                                        :cancel-button-text="$t('no')"
                                        :confirm-button-text="$t('yes')"
                                        :title="$t('delete_question')"
                                        @confirm="destroyIncident(scope.row)"
                                >
                                    <el-button v-if="$RoleIs('director') || $User().id === scope.row.user_id" slot="reference" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.label')">
                            <template slot-scope="scope">
                                <span>{{ $DisplayID(scope.row.id, 'INC') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.date')">
                            <template slot-scope="scope">
                                <span>{{ moment(scope.row.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YY') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.timestamp')">
                            <template slot-scope="scope">
                                <span>{{ moment(scope.row.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.room')">
                            <template slot-scope="scope">
                                <span>{{ scope.row.room }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.reason')">
                            <template slot-scope="scope">
                                <span>{{ scope.row.reason }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.category')">
                            <template slot-scope="scope">
                                <span>{{ $t('incident.categories.' + scope.row.category) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.implications')">
                            <template slot-scope="scope">
                                <span>{{ scope.row.implications }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.classification')">
                            <template slot-scope="scope">
                                <span>{{ $t('incident.classifications.' + scope.row.classification) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.user_id')" class-name="image-text">
                            <template slot-scope="scope">
                                <div v-if="scope.row.applicant">
                                    <img :src="$Avatar(scope.row.applicant)" alt="">
                                    <span>{{ $FullName(scope.row.applicant) }}</span>
                                </div>
                                <div v-else>
                                    <span>{{ $t('incident.anonymous') }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <el-pagination
                        v-if="incidents_filtered.length > incidents_pagination.limit"
                        background
                        layout="pager"
                        @current-change="incidentsPaginate"
                        :pagerCount="5"
                        :current-page="incidents_pagination.page"
                        :page-size="incidents_pagination.limit"
                        :total="incidents_filtered.length">
                </el-pagination>
            </div>
            <div v-else class="safe-reporting-incident-content tab-evaluation">
                <div class="table-parent scroll-grey">
                    <el-table border :empty-text="$t('no_evaluations')" :data="evaluations_data">
                        <el-table-column :label="$t('actions')" class-name="table-actions-center">
                            <template slot-scope="scope">
                                <el-button @click="toggleEvaluation(scope.row)" icon="el-icon-view" type="success" size="mini" circle></el-button>
                                <el-button v-if="$RoleIs('director') || $RoleIs('manager')" @click="updateEvaluation(scope.row)" icon="el-icon-edit" type="primary" size="mini" circle></el-button>
                                <el-popconfirm
                                        icon-color="red"
                                        icon="el-icon-info"
                                        :cancel-button-text="$t('no')"
                                        :confirm-button-text="$t('yes')"
                                        :title="$t('delete_question')"
                                        @confirm="destroyEvaluation(scope.row)"
                                >
                                    <el-button v-if="$RoleIs('director') || $RoleIs('manager')" slot="reference" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('evaluation.label')">
                            <template slot-scope="scope">
                                <span>{{ $DisplayID(scope.row.id, 'EVA') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.date')">
                            <template slot-scope="scope">
                                <span>{{ moment(scope.row.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YY') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('incident.columns.timestamp')">
                            <template slot-scope="scope">
                                <span>{{ moment(scope.row.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('evaluation.created_by')" class-name="image-text">
                            <template slot-scope="scope">
                                <img :src="$Avatar(scope.row.user)" alt="">
                                <span>{{ $FullName(scope.row.user) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('evaluation.year_label')">
                            <template slot-scope="scope">
                                <span>{{ scope.row.year }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <el-pagination
                        v-if="evaluations.length > evaluations_pagination.limit"
                        background
                        layout="pager"
                        @current-change="evaluationsPaginate"
                        :pagerCount="5"
                        :current-page="evaluations_pagination.page"
                        :page-size="evaluations_pagination.limit"
                        :total="evaluations.length">
                </el-pagination>
            </div>
        </div>
        <transition name="fade">
            <evaluation-crud v-if="evaluation_crud.popup"></evaluation-crud>
        </transition>
        <transition name="fade">
            <incident-popup v-if="incident_popup.popup"></incident-popup>
        </transition>
        <transition name="fade">
            <evaluation-popup v-if="evaluation_popup.popup"></evaluation-popup>
        </transition>
    </section>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import {$http} from "../../../../../hooks/api";
    import {base_url} from "../../../../../constants/api";
    import moment from "moment";
    import {Message} from "element-ui";
    import IncidentCrud from "./IncidentCrud";
    import EvaluationCrud from "./EvaluationCrud";
    import IncidentPopup from "./IncidentPopup";
    import EvaluationPopup from "./EvaluationPopup";

    export default {
        mixins: [helper],
        components: {IncidentCrud, EvaluationCrud, IncidentPopup, EvaluationPopup},
        async beforeMount() {
            await this.getIncidents();
            await this.getEvaluations();
        },
        data(){
            return {
                moment: moment,
                tab: 'incident',
                filters: {
                    year: 'all_years'
                },
                incident_crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                incidents: [],
                incidents_pagination: {
                    page: 1,
                    limit: 10
                },
                incident_popup: {
                    popup: false,
                    model: null
                },
                evaluation_crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                evaluations: [],
                evaluations_pagination: {
                    page: 1,
                    limit: 10
                },
                evaluation_popup: {
                    popup: false,
                    model: null
                }
            }
        },
        methods: {
            async getIncidents(){
                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/incident').then(({data}) => {
                    this.incidents = data.incidents;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            async getEvaluations(){
                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/evaluate').then(({data}) => {
                    this.evaluations = data.evaluations;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            changeTab(tab){
                this.tab = tab;
            },
            incidentsPaginate(page) {
                this.incidents_pagination.page = page;
            },
            updateIncident(incident){
                let model = {
                    id: incident.id,
                    room: incident.room,
                    reason: incident.reason,
                    category: incident.category,
                    facts_and_possible_causes: incident.facts_and_possible_causes,
                    implications: incident.implications,
                    classification: incident.classification,
                    short_description: incident.short_description,
                    measurement: incident.measurement
                };

                if (this.$RoleIs('director') || this.$User().id === incident.user_id){
                    model.user_id = incident.user_id;
                }

                this.toggleIncidentCrud('update', model);
            },
            destroyIncident(incident){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/incident/' + incident.id).then(async ({data}) => {
                    if (data.success){
                        await this.getIncidents();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleIncidentCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        room: null,
                        reason: null,
                        category: null,
                        facts_and_possible_causes: null,
                        implications: null,
                        classification: 'moderate',
                        short_description: null,
                        measurement: null,
                        user_id: this.$User().id
                    };
                }

                if (this.incident_crud.popup) {
                    this.incident_crud = {
                        popup: false,
                        action: null,
                        model: {}
                    };
                } else {
                    this.incident_crud = {
                        popup: true,
                        action: action,
                        model: model
                    };
                }
            },
            toggleIncident(model = null){
                this.incident_popup = {
                    popup: !this.incident_popup.popup,
                    model: model
                };
            },
            evaluationsPaginate(page) {
                this.evaluations_pagination.page = page;
            },
            updateEvaluation(evaluation){
                let model = {
                    id: evaluation.id,
                    content: evaluation.content
                };

                this.toggleEvaluationCrud('update', model);
            },
            destroyEvaluation(evaluation){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/evaluate/' + evaluation.id).then(async ({data}) => {
                    if (data.success){
                        await this.getEvaluations();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleEvaluationCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        year: this.filters.year === 'all_years' ? null : this.filters.year,
                        content: null
                    };
                }

                if (this.evaluation_crud.popup) {
                    this.evaluation_crud = {
                        popup: false,
                        action: null,
                        model: {}
                    };
                } else {
                    this.evaluation_crud = {
                        popup: true,
                        action: action,
                        model: model
                    };
                }
            },
            toggleEvaluation(model = null){
                this.evaluation_popup = {
                    popup: !this.evaluation_popup.popup,
                    model: model
                };
            }
        },
        computed: {
            years: function () {
                let years = [
                    {
                        label: this.$t('incident.all_years'),
                        value: 'all_years'
                    }
                ];

                this.incidents.map(incident => {
                    let year = moment(incident.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY');

                    if (years.findIndex(item => item.value === year) === -1){
                        years.push({
                            label: year,
                            value: year
                        });
                    }
                });

                return years;
            },
            incidents_filtered: function () {
                return this.incidents.filter(incident => {
                    if (this.filters.year === 'all_years'){
                        return true;
                    }
                    return moment(incident.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY') === this.filters.year;
                });
            },
            incidents_data: function () {
                return this.incidents_filtered.slice(this.incidents_pagination.limit * this.incidents_pagination.page - this.incidents_pagination.limit, this.incidents_pagination.limit * this.incidents_pagination.page);
            },
            evaluations_data: function () {
                return this.evaluations.slice(this.evaluations_pagination.limit * this.evaluations_pagination.page - this.evaluations_pagination.limit, this.evaluations_pagination.limit * this.evaluations_pagination.page);
            }
        },
        watch: {
            incidents_data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.incidents_pagination.page > 1){
                        this.incidents_pagination.page--;
                    }
                }
            },
            evaluations_data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.evaluations_pagination.page > 1){
                        this.evaluations_pagination.page--;
                    }
                }
            }
        }
    }
</script>