import axios from "axios";
import store from "../store";
import i18n from "../plugins/i18n";
import {Message} from "element-ui";

export const $http = () => {
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getAccessToken;
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';

    axios.interceptors.response.use(response => {
        return response;
    }, error => {
        const status = error.response && error.response.status ? error.response.status : 0;

        if (status === 401) {
            Message({
                showClose: true,
                message: i18n.t('unauthenticated'),
                type: 'warning'
            });
        }

        if (status === 404) {
            Message({
                showClose: true,
                message: i18n.t('not_found'),
                type: 'warning'
            });
        }

        if (status === 405) {
            Message({
                showClose: true,
                message: i18n.t('access_denied'),
                type: 'warning'
            });
        }

        if (status === 500) {
            Message({
                showClose: true,
                message: i18n.t('server_failure'),
                type: 'error'
            });
        }

        return error;
    });

    return axios
};