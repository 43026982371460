import Echo from "laravel-echo";
import store from "../store";

export const $echo = () => {
    window.Echo = new Echo({
        authEndpoint: process.env.VUE_APP_BASE_URL + '/api/broadcasting/auth',
        auth: {
            headers: {
                Authorization: 'Bearer ' + store.getters.getAccessToken
            }
        },
        broadcaster: 'pusher',
        key: process.env.VUE_APP_MIX_PUSHER_APP_KEY,
        cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
        encrypted: true
    });

    return window.Echo;
};