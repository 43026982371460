<template>
    <section class="salary-section default-section">
        <div class="section-title">
            <h1>{{ $t('salary') }}</h1>
        </div>

        <div class="salary-top">
            <el-button @click="back()" type="primary" size="mini" icon="el-icon-arrow-left" circle></el-button>
            <p>{{ date.format('MMMM YYYY') }}</p>
            <el-button @click="next()" type="primary" size="mini" icon="el-icon-arrow-right" circle></el-button>
        </div>

        <div class="salary-content">
            <div class="table-parent scroll-grey">
                <el-table border :empty-text="$t('no_staff')" :data="data">
                    <el-table-column :label="$t('actions')" class-name="table-actions-center">
                        <template slot-scope="scope">
                            <el-button
                                    v-if="can(scope.row, 'reject')"
                                    @click="toggleReject(scope.row)"
                                    icon="el-icon-error"
                                    type="danger"
                                    size="mini"
                                    circle
                            />

                            <el-popconfirm
                                    v-if="can(scope.row, 'approve')"
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('do_you_want', {status: $t('approve')})"
                                    @confirm="approve(scope.row)"
                            >
                                <el-button slot="reference" size="mini" type="success" icon="el-icon-success" circle/>
                            </el-popconfirm>

                            <el-popconfirm
                                    v-if="can(scope.row, 'accept')"
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('do_you_want', {status: $t('decline')})"
                                    @confirm="declineApproved(scope.row)"
                            >
                                <el-button slot="reference" size="mini" type="danger" icon="el-icon-arrow-left" circle/>
                            </el-popconfirm>

                            <el-button
                                    v-if="can(scope.row, 'accept')"
                                    @click="toggleEdit(scope.row)"
                                    icon="el-icon-edit"
                                    type="primary"
                                    size="mini"
                                    circle
                            />

                            <el-popconfirm
                                    v-if="can(scope.row, 'accept')"
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('do_you_want', {status: $t('accept')})"
                                    @confirm="accept(scope.row)"
                            >
                                <el-button slot="reference" size="mini" type="success" icon="el-icon-success" circle/>
                            </el-popconfirm>

                            <el-popconfirm
                                    v-if="can(scope.row, 'pay')"
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('do_you_want', {status: $t('decline')})"
                                    @confirm="declineAccepted(scope.row)"
                            >
                                <el-button slot="reference" size="mini" type="danger" icon="el-icon-arrow-left" circle/>
                            </el-popconfirm>

                            <el-popconfirm
                                    v-if="can(scope.row, 'pay')"
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('do_you_want', {status: $t('pay')})"
                                    @confirm="pay(scope.row)"
                            >
                                <el-button slot="reference" size="mini" type="success" icon="el-icon-success" circle/>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('specification')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-link v-if="document(scope.row.salary)" :href="document(scope.row.salary)" target="_blank">
                                {{ $t('uploaded') }}
                                <i class="el-icon-view el-icon--right"></i>
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('salary_amount')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ displayAmount(scope.row.salary) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('name')" class-name="image-text" header-align="center">
                        <template slot-scope="scope">
                            <img :src="$Avatar(scope.row)" alt="">
                            <span>{{ $FullName(scope.row) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('salary_total')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.salary['total'].toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('pause')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.salary['pause'].toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="$Company()['show_log_hours_over']" :label="$t('over')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.salary['over'].toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('absence')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.salary['absence'].toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sick_unused')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.salary['sick']['unused'].toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('sick_used')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.salary['sick']['used'].toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('vacation')" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.salary['vacation'].toFixed(2) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <el-pagination
                    v-if="employees.length > pagination.limit"
                    background
                    layout="pager"
                    @current-change="paginate"
                    :pagerCount="5"
                    :current-page="pagination.page"
                    :page-size="pagination.limit"
                    :total="employees.length">
            </el-pagination>
        </div>

        <extra-salary ref="extra-salary"></extra-salary>

        <transition name="fade">
            <Reject v-if="reject.popup"></Reject>
        </transition>
        <transition name="fade">
            <Edit v-if="edit.popup"></Edit>
        </transition>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import moment from "moment";
    import {roles} from "../../../../constants/roles";
    import Reject from "./Reject";
    import Edit from "./Edit";
    import ExtraSalary from "./ExtraSalary";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        components: {
            Reject,
            Edit,
            ExtraSalary
        },
        beforeMount() {
            this.getEmployees();
        },
        data() {
            return {
                date: moment().startOf('month'),
                employees: [],
                pagination: {
                    page: 1,
                    limit: 10
                },
                reject: {
                    popup: false,
                    user: null,
                    reason: null
                },
                edit: {
                    popup: false,
                    user: null,
                    amount: null,
                    specification: null
                }
            }
        },
        methods: {
            async getEmployees(){
                let date = moment(this.date).format('YYYY-MM');

                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/salary?date=' + date).then(({data}) => {
                    this.employees = data.employees;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            back(){
                this.date = moment(this.date.subtract(1, 'months'));
                this.getEmployees();
                this.$refs['extra-salary'].getExtraSalary();
            },
            next(){
                this.date = moment(this.date.add(1, 'months'));
                this.getEmployees();
                this.$refs['extra-salary'].getExtraSalary();
            },
            paginate(page) {
                this.pagination.page = page;
            },
            displayAmount(salary){
                let amount = null;

                if (this.$RoleIs(roles.director)){
                    if (['accepted', 'paid'].indexOf(salary.status) !== -1){
                        amount = '€' + salary.amount;
                    }
                } else {
                    if (salary.status !== null){
                        amount = '€' + salary.amount;
                    }
                }

                return amount;
            },
            document(salary){
                let specification = null;

                if (this.$RoleIs(roles.director)){
                    if (['accepted', 'paid'].indexOf(salary.status) !== -1){
                        specification = salary.specification;
                    }
                } else {
                    specification = salary.specification;
                }

                return specification;
            },
            can(user, status){
                if (status === 'reject' || status === 'approve'){
                    return this.$RoleIs(roles.director) && user.salary['passed'] && user.salary.status === null;
                } else if (status === 'accept'){
                    return this.$RoleIs(roles.accountant) && user.salary['passed'] && user.salary.status === 'approved';
                } else if (status === 'pay'){
                    return this.$RoleIs(roles.director) && user.salary['passed'] && user.salary.status === 'accepted';
                } else {
                    return false;
                }
            },
            toggleReject(user = null){
                if (this.reject.popup){
                    this.reject = {
                        popup: false,
                        user: null,
                        reason: null
                    };
                } else {
                    this.reject = {
                        popup: true,
                        user: user,
                        reason: null
                    };
                }
            },
            approve(user){
                let data = {
                    date: moment(this.date).format('YYYY-MM'),
                    user_id: user.id,
                    status: 'approve'
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.getEmployees();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            declineApproved(user){
                let data = {
                    date: moment(this.date).format('YYYY-MM'),
                    user_id: user.id,
                    status: 'decline-approved'
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.getEmployees();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleEdit(user = null){
                if (this.edit.popup){
                    this.edit = {
                        popup: false,
                        user: null,
                        amount: null,
                        specification: null
                    };
                } else {
                    this.edit = {
                        popup: true,
                        user: user,
                        amount: user.salary.amount,
                        specification: user.salary.specification
                    };
                }
            },
            accept(user){
                let data = {
                    date: moment(this.date).format('YYYY-MM'),
                    user_id: user.id,
                    status: 'accept'
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.getEmployees();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            declineAccepted(user){
                let data = {
                    date: moment(this.date).format('YYYY-MM'),
                    user_id: user.id,
                    status: 'decline-accepted'
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.getEmployees();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            pay(user){
                let data = {
                    date: moment(this.date).format('YYYY-MM'),
                    user_id: user.id,
                    status: 'pay'
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/salary-single', data).then(({data}) => {
                    if (data.success) {
                        this.getEmployees();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            data: function () {
                return this.employees.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            }
        }
    }
</script>