<template>
    <section class="checklist-section default-section">
        <div class="area">
            <div class="area-header">
                <p>{{ $t('checklist_areas.room.title') }}</p>
                <el-button @click="toggleRoomCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
            </div>
            <div class="area-row"></div>
            <div class="area-body">
                <div class="area-items" v-if="rooms.length">
                    <div @click="setActiveRoom(room)" :class="`area-item ${active.room && active.room.id === room.id ? 'active' : ''}`" v-for="(room, key) in rooms" :key="'area-room-' + key">
                        <div class="area-item-left">
                            <span>{{ room.name }}</span>
                        </div>
                        <div class="area-item-right">
                            <el-button @click="($event) => updateRoom($event, room)" icon="el-icon-edit" type="primary" size="mini" circle></el-button>
                            <el-button @click="($event) => copyRoom($event, room)" icon="el-icon-copy-document" type="warning" size="mini" circle></el-button>
                            <el-popconfirm
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('checklist_areas.room.are_you_sure')"
                                    @confirm="destroyRoom(room)"
                            >
                                <el-button @click="($event) => $event.stopPropagation()" slot="reference" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <div class="area-empty" v-else>
                    <p>{{ $t('checklist_areas.room.empty') }}</p>
                </div>
            </div>
        </div>
        <div class="area" v-if="active.room">
            <div class="area-header">
                <p>{{ $t('checklist_areas.type.title') }}</p>
                <el-button @click="toggleTypeCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
            </div>
            <div class="area-row"></div>
            <div class="area-body">
                <div class="area-items" v-if="active.room.types.length">
                    <div @click="setActiveType(type)" :class="`area-item ${active.type && active.type.id === type.id ? 'active' : ''}`" v-for="(type, key) in active.room.types" :key="'area-type-' + key">
                        <div class="area-item-left">
                            <span>{{ typeName(type) }}</span>
                        </div>
                        <div class="area-item-right">
                            <el-popconfirm
                                    icon-color="red"
                                    icon="el-icon-info"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('checklist_areas.type.are_you_sure')"
                                    @confirm="destroyType(type)"
                            >
                                <el-button @click="($event) => $event.stopPropagation()" slot="reference" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <div class="area-empty" v-else>
                    <p>{{ $t('checklist_areas.type.empty', {room: active.room.name}) }}</p>
                </div>
            </div>
        </div>
        <div class="area" v-if="active.type">
            <div class="area-header">
                <p>{{ $t('checklist_areas.field.title', {type: $t('frequency_types.' + active.type.name), room: active.room.name}) }}</p>
                <el-button @click="toggleFieldCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
            </div>
            <div class="area-row"></div>
            <div class="area-body">
                <div class="area-items" v-if="active.type.fields.length">
                    <draggable
                            class="area-item-fields"
                            :draggable="'.area-item-field'"
                            tag="div"
                            group="id"
                            ghostClass="ghost"
                            :list="active.type.fields"
                            @change="sorting()"
                    >
                        <div class="area-item area-item-field" v-for="(field, key) in active.type.fields" :key="'area-field-' + key">
                            <div class="area-item-left">
                                <span>{{ field.name }}</span>
                            </div>
                            <div class="area-item-right">
                                <el-button @click="updateField(field)" icon="el-icon-edit" type="primary" size="mini" circle></el-button>
                                <el-popconfirm
                                        v-if="active.type.fields.length > 1"
                                        icon-color="red"
                                        icon="el-icon-info"
                                        :cancel-button-text="$t('no')"
                                        :confirm-button-text="$t('yes')"
                                        :title="$t('delete_question')"
                                        @confirm="destroyField(field)"
                                >
                                    <el-button slot="reference" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                                </el-popconfirm>
                            </div>
                        </div>
                    </draggable>
                </div>
                <div class="area-empty" v-else>
                    <p>{{ $t('checklist_areas.field.empty', {type: $t('frequency_types.' + active.type.name)}) }}</p>
                </div>
            </div>
        </div>

        <transition name="fade">
            <room-crud v-if="room_crud.popup"></room-crud>
        </transition>
        <transition name="fade">
            <type-crud v-if="type_crud.popup"></type-crud>
        </transition>
        <transition name="fade">
            <field-crud v-if="field_crud.popup"></field-crud>
        </transition>
        <transition name="fade">
            <copy-room v-if="copy_room.popup"></copy-room>
        </transition>
    </section>
</template>

<script>
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import RoomCrud from "./cruds/RoomCrud";
    import TypeCrud from "./cruds/TypeCrud";
    import FieldCrud from "./cruds/FieldCrud";
    import CopyRoom from "./cruds/CopyRoom";
    import draggable from 'vuedraggable';

    export default {
        components: {
            RoomCrud,
            TypeCrud,
            FieldCrud,
            CopyRoom,
            draggable
        },
        beforeMount() {
            this.getRooms();
        },
        data() {
            return {
                rooms: [],
                active: {
                    room: null,
                    type: null
                },
                room_crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                type_crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                field_crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                copy_room: {
                    popup: false,
                    room: null,
                    model: {}
                }
            }
        },
        methods: {
            getRooms(){
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/checklist-room').then(({data}) => {
                    this.rooms = data.rooms;
                    this.updateActive();
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            sorting(){
                let data = {
                    checklist_room_id: this.active.room.id,
                    checklist_room_type_id: this.active.type.id,
                    ids: this.active.type.fields.map((field) => {return field.id})
                };
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/checklist-room-type-field-sorting', data).then(() => {
                    this.getRooms();
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            typeName(type){
                if (['weekly', 'two_weekly'].includes(type.name)){
                    return this.$t('frequency_types.' + type.name) + ' - ' + this.$t('week_days.' + type.value);
                }
                return this.$t('frequency_types.' + type.name);
            },
            setActiveRoom(room){
                this.active = {
                    room: room,
                    type: room.types.length ? room.types[0] : null
                };
            },
            setActiveType(type){
                this.active.type = type;
            },
            updateActive(){
                let active = {
                    room: null,
                    type: null
                };

                if (this.rooms.length){
                    if (this.active.room){
                        let current_room = this.rooms.find(r => r.id === this.active.room.id);
                        if (current_room){
                            active.room = current_room;

                            if (this.active.type){
                                let current_type = current_room.types.find(t => t.id === this.active.type.id);

                                if (current_type){
                                    active.type = current_type;
                                } else {
                                    active.type = current_room.types.length ? current_room.types[0] : null;
                                }
                            } else {
                                active.type = current_room.types.length ? current_room.types[0] : null;
                            }
                        } else {
                            active.room = this.rooms[0];
                            active.type = this.rooms[0].types.length ? this.rooms[0].types[0] : null;
                        }
                    } else {
                        active.room = this.rooms[0];
                        active.type = this.rooms[0].types.length ? this.rooms[0].types[0] : null;
                    }
                }

                this.active = active;
            },
            updateRoom(event, room){
                event.stopPropagation();

                let model = {
                    id: room.id,
                    name: room.name
                };

                this.toggleRoomCrud('update', model);
            },
            updateField(field){
                let model = {
                    id: field.id,
                    name: field.name
                };

                this.toggleFieldCrud('update', model);
            },
            destroyRoom(room){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/checklist-room/' + room.id).then(({data}) => {
                    if (data.success){
                        this.getRooms();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            destroyType(type){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/checklist-room-type/' + type.id).then(({data}) => {
                    if (data.success){
                        this.getRooms();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            destroyField(field){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/checklist-room-type-field/' + field.id).then(({data}) => {
                    if (data.success){
                        this.getRooms();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleRoomCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        name: null
                    };
                }

                if (this.room_crud.popup) {
                    this.room_crud = {
                        popup: false,
                        action: null,
                        model: {}
                    };
                } else {
                    this.room_crud = {
                        popup: true,
                        action: action,
                        model: model
                    };
                }
            },
            toggleTypeCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        name: 'daily',
                        value: null,
                        fields: [{name: null}],
                        checklist_room_id: this.active.room.id
                    };
                }

                if (this.type_crud.popup) {
                    this.type_crud = {
                        popup: false,
                        action: null,
                        model: {}
                    };
                } else {
                    this.type_crud = {
                        popup: true,
                        action: action,
                        model: model
                    };
                }
            },
            toggleFieldCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        fields: [{name: null}],
                        checklist_room_type_id: this.active.type.id
                    };
                }

                if (this.field_crud.popup) {
                    this.field_crud = {
                        popup: false,
                        action: null,
                        model: {}
                    };
                } else {
                    this.field_crud = {
                        popup: true,
                        action: action,
                        model: model
                    };
                }
            },
            copyRoom(event, room){
                event.stopPropagation();

                if (room){
                    this.copy_room = {
                        popup: true,
                        room: room,
                        model: {name: null}
                    };
                } else {
                    this.copy_room = {
                        popup: false,
                        room: null,
                        model: {}
                    };
                }
            }
        }
    }
</script>