<template>
    <section class="profile-section default-section">
        <div class="profile-title"></div>

        <div class="profile-form">
            <div class="form-field flex-center">
                <div class="avatar-area">
                    <div class="file-area">
                        <input type="file" @change="changeAvatar"/>
                    </div>
                    <div class="image-area">
                        <img :src="avatar" alt="">
                    </div>
                    <div class="shadow-area">
                        <img src="../../../../images/upload.png" alt="">
                    </div>
                </div>
                <div v-if="$HasError(errors, 'avatar')" class="field-error">
                    <span>{{ $GetError(errors, 'avatar') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'name') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('name') }} </div>
                <el-input size="medium" v-model="user.name"></el-input>
                <div v-if="$HasError(errors, 'name')" class="field-error">
                    <span>{{ $GetError(errors, 'name') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'surname') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('surname') }} </div>
                <el-input size="medium" v-model="user.surname"></el-input>
                <div v-if="$HasError(errors, 'surname')" class="field-error">
                    <span>{{ $GetError(errors, 'surname') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'gender') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('gender') }} </div>
                <el-radio-group v-model="user.gender" size="medium">
                    <el-radio label="male" border>{{ $t('genders.male') }}</el-radio>
                    <el-radio label="female" border>{{ $t('genders.female') }}</el-radio>
                </el-radio-group>
                <div v-if="$HasError(errors, 'gender')" class="field-error">
                    <span>{{ $GetError(errors, 'gender') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'address') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('address') }} </div>
                <el-input size="medium" v-model="user.address"></el-input>
                <div v-if="$HasError(errors, 'address')" class="field-error">
                    <span>{{ $GetError(errors, 'address') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'postal_code') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('postal_code') }} </div>
                <el-input size="medium" v-model="user.postal_code"></el-input>
                <div v-if="$HasError(errors, 'postal_code')" class="field-error">
                    <span>{{ $GetError(errors, 'postal_code') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'place') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('place') }} </div>
                <el-input size="medium" v-model="user.place"></el-input>
                <div v-if="$HasError(errors, 'place')" class="field-error">
                    <span>{{ $GetError(errors, 'place') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'bsn_number') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('bsn_number') }} </div>
                <el-input size="medium" v-model="user.bsn_number"></el-input>
                <div v-if="$HasError(errors, 'bsn_number')" class="field-error">
                    <span>{{ $GetError(errors, 'bsn_number') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'email') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('email') }} </div>
                <el-input size="medium" v-model="user.email"></el-input>
                <div v-if="$HasError(errors, 'email')" class="field-error">
                    <span>{{ $GetError(errors, 'email') }}</span>
                </div>
            </div>
        </div>

        <div class="profile-action">
            <el-button @click="update()" size="medium" type="success" round>{{ $t('update') }}</el-button>
        </div>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import objectToFormData from "../../../../mixins/objectToFormData";

    export default {
        mixins: [helper],
        beforeMount() {
            this.setUser(this.$User());
        },
        data() {
            return {
                user: {},
                errors: {}
            }
        },
        methods: {
            setUser(user){
                this.user = {
                    id: user.id,
                    name: user.name,
                    surname: user.surname,
                    gender: user.gender,
                    avatar: user.avatar,
                    address: user.address,
                    postal_code: user.postal_code,
                    place: user.place,
                    bsn_number: user.bsn_number,
                    email: user.email
                };
            },
            update(){
                let data = objectToFormData(this.user);

                this.$store.commit('setPreloader', true);

                $http().post(base_url + '/api/client/user-update', data).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.setUser(data.user);

                        this.$store.commit('setUser', data.user);

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else{
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            changeAvatar(e) {
                this.user.avatar = e.target.files[0];
            }
        },
        computed: {
            avatar: function () {
                if (this.user.avatar){
                    if (typeof this.user.avatar === 'string'){
                        return this.user.avatar;
                    }

                    return URL.createObjectURL(this.user.avatar);
                }

                if (this.user.gender === 'female'){
                    return require('../../../../images/female.png');
                }

                return require('../../../../images/male.png');
            }
        }
    }
</script>