<template>
    <section class="home-section">
        <div class="home-title">
            <h1>{{ greeting }} {{ $User().name }}, </h1>
            <h2>{{ $t('get_started') }}</h2>
        </div>
        <div class="home-list">
            <div class="home-list-item">
                <router-link :to="{name: 'settings', params: {prefix: 'profile'}}" tag="div">
                    <img src="../../../images/sidebar/dark/settings.svg" alt="">
                </router-link>
                <p>{{ $t('settings') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('staff')" class="home-list-item">
                <router-link :to="{name: 'staff', params: {prefix: $RoleIs('director') ? 'staff' : 'functional-interviews'}}" tag="div">
                    <img src="../../../images/sidebar/dark/staff.svg" alt="">
                </router-link>
                <p>{{ $t('staff') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('personnel')" class="home-list-item">
                <router-link :to="{name: 'personnel'}" tag="div">
                    <img src="../../../images/sidebar/dark/staff.svg" alt="">
                </router-link>
                <p>{{ $t('staff') }}</p>
            </div>
            <div v-if="$HasPermission('view-graphic')" class="home-list-item">
                <router-link :to="{name: 'graphic'}" tag="div">
                    <img src="../../../images/sidebar/dark/graphic.svg" alt="">
                </router-link>
                <p>{{ $t('graphic') }}</p>
            </div>
            <div v-if="$HasPermission('view-notes')" class="home-list-item">
                <router-link :to="{name: 'notes'}" tag="div">
                    <img src="../../../images/sidebar/dark/notes.svg" alt="">
                </router-link>
                <p>{{ $t('notes') }}</p>
            </div>
            <div v-if="$HasPermission('view-protocols')" class="home-list-item">
                <router-link :to="{name: 'protocols'}" tag="div">
                    <img src="../../../images/sidebar/dark/protocols.svg" alt="">
                </router-link>
                <p>{{ $t('protocols') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('checklist')" class="home-list-item">
                <router-link :to="{name: 'checklist', params: {prefix: 'overview'}}" tag="div">
                    <img src="../../../images/sidebar/dark/checklist.svg" alt="">
                </router-link>
                <p>{{ $t('checklist') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('log-hours')" class="home-list-item">
                <router-link :to="{name: 'log-hours'}" tag="div">
                    <img src="../../../images/sidebar/dark/log-hours.svg" alt="">
                </router-link>
                <p>{{ $t('log_hours') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('free-days')" class="home-list-item">
                <router-link :to="{name: 'free-days'}" tag="div">
                    <img src="../../../images/sidebar/dark/free-days.svg" alt="">
                </router-link>
                <p>{{ $t('free_days') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('salary')" class="home-list-item">
                <router-link :to="{name: 'salary'}" tag="div">
                    <img src="../../../images/sidebar/dark/salary.svg" alt="">
                </router-link>
                <p>{{ $t('salary') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('incidents-and-complaints')" class="home-list-item">
                <router-link :to="{name: 'incidents-and-complaints', params: {prefix: 'safe-reporting-incident'}}" tag="div">
                    <img src="../../../images/sidebar/dark/incidents-and-complaints.svg" alt="">
                </router-link>
                <p>{{ $t('incidents_and_complaints') }}</p>
            </div>
            <div v-if="$AccessToRouteByRoles('agenda')" class="home-list-item">
                <router-link :to="{name: 'agenda'}" tag="div">
                    <img src="../../../images/sidebar/dark/agenda.svg" alt="">
                </router-link>
                <p>{{ $t('agenda') }}</p>
            </div>
        </div>
    </section>
</template>

<script>
    import helper from "../../../mixins/helper";
    import moment from "moment";

    export default {
        mixins: [helper],
        beforeMount() {
            this.updateGreeting();
            setInterval(() => {
                this.updateGreeting();
            },1000);
        },
        data() {
            return {
                greeting: null
            }
        },
        methods: {
            updateGreeting(){
                let time = moment();

                let dawn = moment('06:00:00', 'hh:mm:ss');
                let midday = moment('12:00:00', 'hh:mm:ss');
                let evening = moment('18:00:00', 'hh:mm:ss');
                let noon = moment('23:59:59', 'hh:mm:ss');

                if (time.isBetween(dawn, midday)) {
                    this.greeting = this.$t('dawn');
                }
                else if (time.isBetween(midday, evening)) {
                    this.greeting = this.$t('midday');
                }
                else if (time.isBetween(evening, noon)) {
                    this.greeting = this.$t('evening');
                }
                else{
                    this.greeting = this.$t('noon');
                }
            }
        }
    }
</script>