import VueRouter from 'vue-router';
import store from '../store';
import moment from "moment";
import {$http} from "../hooks/api";
import {base_url} from "../constants/api";
import {users} from "../constants/users";
import {roles} from "../constants/roles";

// Auth
import Login from "../views/auth/Login";
import CreatePassword from "../views/auth/CreatePassword";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";

// Dashboard -> Admin
import Companies from "../views/dashboard/admin/companies/Companies";
import ChangePassword from "../views/dashboard/admin/ChangePassword";

// Dashboard -> Client
import Home from "../views/dashboard/client/Home";
import Settings from "../views/dashboard/client/Settings";
import Staff from "../views/dashboard/client/staff/Staff";
import Personnel from "../views/dashboard/client/personnel/Personnel";
import Graphic from "../views/dashboard/client/graphic/Graphic";
import Notes from "../views/dashboard/client/notes/Notes";
import Protocols from "../views/dashboard/client/protocols/Protocols";
import Checklist from "../views/dashboard/client/checklist/Checklist";
import LogHours from "../views/dashboard/client/log-hours/LogHours";
import FreeDays from "../views/dashboard/client/free-days/FreeDays";
import Salary from "../views/dashboard/client/salary/Salary";
import IncidentsAndComplaints from "../views/dashboard/client/incidents-and-complaints/IncidentsAndComplaints";
import Agenda from "../views/dashboard/client/agenda/Agenda";

// Others
import NotFound from "../views/others/NotFound";
import Complain from "../views/others/Complain";

const routes = [
    {
        name: 'login',
        path: '/',
        component: Login,
        meta: {auth: 'guest'}
    },
    {
        name: "create-password",
        path: "/create-password",
        component: CreatePassword,
        meta: {auth: 'guest'}
    },
    {
        name: "forgot-password",
        path: "/forgot-password",
        component: ForgotPassword,
        meta: {auth: 'guest'}
    },
    {
        name: "reset-password",
        path: "/reset-password",
        component: ResetPassword,
        meta: {auth: 'guest'}
    },
    {
        name: 'complain',
        path: '/complain',
        component: Complain,
        meta: {auth: 'guest'}
    },
    {
        name: 'companies',
        path: '/companies',
        component: Companies,
        meta: {
            auth: 'auth',
            roles: [roles.admin],
            permissions: []
        }
    },
    {
        name: 'change-password',
        path: '/change-password',
        component: ChangePassword,
        meta: {
            auth: 'auth',
            roles: [roles.admin],
            permissions: []
        }
    },
    {
        name: 'staff',
        path: '/staff/:prefix',
        component: Staff,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'home',
        path: '/home',
        component: Home,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.accountant,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'settings',
        path: '/settings/:prefix',
        component: Settings,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.accountant,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'personnel',
        path: '/personnel',
        component: Personnel,
        meta: {
            auth: 'auth',
            roles: [
                roles.accountant
            ],
            permissions: []
        }
    },
    {
        name: 'graphic',
        path: '/graphic',
        component: Graphic,
        meta: {
            auth: 'auth',
            roles: [],
            permissions: ['view-graphic']
        }
    },
    {
        name: 'notes',
        path: '/notes',
        component: Notes,
        meta: {
            auth: 'auth',
            roles: [],
            permissions: ['view-notes']
        }
    },
    {
        name: 'protocols',
        path: '/protocols',
        component: Protocols,
        meta: {
            auth: 'auth',
            roles: [],
            permissions: ['view-protocols']
        }
    },
    {
        name: 'checklist',
        path: '/checklist/:prefix',
        component: Checklist,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'log-hours',
        path: '/log-hours',
        component: LogHours,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'free-days',
        path: '/free-days',
        component: FreeDays,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.accountant,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'salary',
        path: '/salary',
        component: Salary,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.accountant
            ],
            permissions: []
        }
    },
    {
        name: 'incidents-and-complaints',
        path: '/incidents-and-complaints/:prefix',
        component: IncidentsAndComplaints,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'agenda',
        path: '/agenda',
        component: Agenda,
        meta: {
            auth: 'auth',
            roles: [
                roles.director,
                roles.manager,
                roles.dentist,
                roles.assistent,
                roles.hygienist
            ],
            permissions: []
        }
    },
    {
        name: 'not-found',
        path: "/:catchAll(.*)",
        component: NotFound,
        meta: {auth: 'each'}
    }
];

const router = new VueRouter({
    routes,
    mode: 'hash'
});

router.beforeEach(async(to, from, next) => {
    // Reset store
    store.commit("setSidebar", false);
    store.commit("setPopups", {
        notifications: false,
        profile: false
    });

    let user = null;

    let clearStorage = () => {
        store.commit("setAccessToken", null);
        store.commit("setExpiresAt", null);
        store.commit("setIsLogged", false);
        store.commit("setUser", null);
        next('/');
    };

    if (store.getters.getIsLogged) {
        let now = moment().format('YYYY-MM-DD HH:mm:ss');
        let expires_at = moment(store.getters.getExpiresAt);
        let is_expired = expires_at.diff(now) <= 0;

        if (is_expired) {
            clearStorage();
        } else {
            await $http().post(base_url + "/api/me").then(({ data }) => {
                if (data.success) {
                    user = data.user;
                    store.commit('setUser', user);
                } else {
                    clearStorage();
                }
            }).catch(() => {
                clearStorage();
            });
        }
    }

    if (to.meta.auth === 'guest') {
        if (user) {
            next(users[user.role.name].main);
        } else {
            next();
        }
    }
    else if (to.meta.auth === 'auth') {
        if (user) {
            let validated = true;

            if (to.meta.roles.length){
                if (to.meta.roles.indexOf(user.role.name) === -1){
                    validated = false;
                }
            }

            if (to.meta.permissions.length){
                for (let permission of to.meta.permissions){
                    if (user.permissions.findIndex(p => p.name === permission) === -1){
                        validated = false;
                    }
                }
            }

            if (validated) {
                next();
            } else {
                next(users[user.role.name].main);
            }
        } else {
            next('/');
        }
    }
    else {
        next();
    }
});

export default router;