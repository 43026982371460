<template>
    <section class="change-password-section default-section">
        <div class="section-title">
            <h1>{{ $t('change_password') }}</h1>
        </div>

        <div class="change-password-form">
            <div :class="`form-field ${$HasError(errors, 'current_password') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('current_password') }} </div>
                <el-input
                        v-model="form.current_password"
                        size="medium"
                        show-password
                        name="password"
                        class="with-append not-fill"
                >
                    <template slot="append">
                        <i class="el-icon-view"></i>
                    </template>
                </el-input>
                <div v-if="$HasError(errors, 'current_password')" class="field-error">
                    <span>{{ $GetError(errors, 'current_password') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'password') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('password') + ' ' + $t('password_placeholder')}} </div>
                <el-input
                        v-model="form.password"
                        size="medium"
                        show-password
                        name="password"
                        class="with-append"
                >
                    <template slot="append">
                        <i class="el-icon-view"></i>
                    </template>
                </el-input>
                <div v-if="$HasError(errors, 'password')" class="field-error">
                    <span>{{ $GetError(errors, 'password') }}</span>
                </div>
            </div>
            <div :class="`form-field ${$HasError(errors, 'password_confirmation') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('password_confirmation') }} </div>
                <el-input
                        v-model="form.password_confirmation"
                        size="medium"
                        show-password
                        name="password"
                        class="with-append"
                >
                    <template slot="append">
                        <i class="el-icon-view"></i>
                    </template>
                </el-input>
                <div v-if="$HasError(errors, 'password_confirmation')" class="field-error">
                    <span>{{ $GetError(errors, 'password_confirmation') }}</span>
                </div>
            </div>
        </div>

        <div class="change-password-action">
            <el-button @click="change()" size="medium" type="success" round>{{ $t('change') }}</el-button>
        </div>
    </section>
</template>

<script>
    import helper from "../../../mixins/helper";
    import {$http} from "../../../hooks/api";
    import {base_url} from "../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data() {
            return {
                form: {
                    current_password: null,
                    password: null,
                    password_confirmation: null
                },
                errors: {}
            }
        },
        methods: {
            change(){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/admin/change-password', this.form).then(({data}) => {
                    if (data.success){
                        this.form = {
                            current_password: null,
                            password: null,
                            password_confirmation: null
                        };

                        this.errors = {};

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else if (data.errors){
                        this.errors = data.errors;
                    } else {
                        this.errors = {};
                        Message({
                            message: data.error,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>