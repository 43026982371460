<template>
    <div class="popup request-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ title }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div v-if="step === 1" class="popup-body scroll-grey step-one">
                <div class="form-field">
                    <div class="field-label">{{ $t('request_type') }}</div>
                    <el-select
                            v-model="type"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="">
                        <el-option
                                v-for="type in types"
                                :key="'type-' + type.value"
                                :label="type.label"
                                :value="type.value"
                        ></el-option>
                    </el-select>
                </div>
            </div>
            <div v-else class="popup-body scroll-grey step-two">
                <div v-if="type === 'sick'" class="first-area">
                    <div class="fields-group">
                        <div :class="`form-field ${model.errors.day ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('date') }}</div>
                            <el-date-picker
                                    v-model="model.day"
                                    format="dd-MM-yyyy"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="">
                            </el-date-picker>
                        </div>
                        <div :class="`form-field ${model.errors.time ? 'field-invalid' : ''}`">
                            <div class="field-label">{{ $t('free_day_hours') }}</div>
                            <el-time-select
                                    v-model="model.time"
                                    :picker-options="{
                                        start: '00:00',
                                        step: '00:15',
                                        end: $Company().distance
                                    }"
                                    placeholder="--:--">
                            </el-time-select>
                        </div>
                    </div>
                    <div class="fields-group">
                        <div class="form-field">
                            <div class="field-label">{{ $t('notes') }}</div>
                            <el-input type="textarea" :rows="5" v-model="model.notes"></el-input>
                        </div>
                    </div>
                    <div v-if="sick_checkbox" class="fields-group">
                        <div class="form-field">
                            <div class="field-label">{{ available_data }}</div>
                            <el-checkbox v-model="model.use_free_hours">{{ $t('free_hours.sick_checkbox') }}</el-checkbox>
                        </div>
                    </div>
                </div>
                <div v-else class="second-area">
                    <div class="request-dates">
                        <div class="form-field">
                            <div class="field-label">{{ $t('select_the_date_range') }}</div>
                            <el-date-picker
                                    @change="change()"
                                    v-model="dates"
                                    format="dd-MM-yyyy"
                                    type="daterange"
                                    :range-separator="$t('to')"
                                    :start-placeholder="$t('start_date')"
                                    :end-placeholder="$t('end_date')">
                            </el-date-picker>
                        </div>
                    </div>
                    <div v-if="model.length" class="request-content">
                        <div class="placeholder-area">
                            <span>{{ $t('free_day_hours') }}</span>
                        </div>
                        <div class="requested-days">
                            <div class="requested-day" v-for="(item, key) in model" :key="'requested-day-' + key">
                                <div class="form-field">
                                    <span>{{ moment(item.day, 'YYYY-MM-DD').format('dddd') }}</span>
                                    <span>{{ moment(item.day, 'YYYY-MM-DD').format('D MMMM') }}</span>
                                </div>
                                <div :class="`form-field ${item.errors.time ? 'field-invalid' : ''}`">
                                    <el-time-select
                                            v-model="item.time"
                                            :picker-options="{
                                                start: '00:00',
                                                step: '00:15',
                                                end: $Company().distance
                                            }"
                                            placeholder="--:--">
                                    </el-time-select>
                                </div>
                            </div>
                        </div>
                        <div class="requested-days-total">
                            <span>{{ $t('total_hours') }}</span>
                            <span>{{ total_hours }}</span>
                        </div>
                        <div class="bottom-info">
                            <div class="summary">
                                <span>{{ $t('summary') }}</span>
                            </div>
                            <div class="bottom-info-items">
                                <span>{{ $t('request_type') }}: {{ $t(type) }}</span>
                                <span>{{ $t('requested_hours') }}: {{ total_hours }}</span>
                                <span>{{ available_data }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="step === 1" class="popup-footer">
                <el-button @click="next()" size="medium" type="primary" round>{{ next_button }}</el-button>
            </div>
            <div v-else class="popup-footer">
                <span @click="back()">{{ $t('back_to_request_type') }}</span>
                <el-button @click="submit()" size="medium" type="primary" round>{{ submit_button }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import moment from "moment";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import {roles} from "../../../../constants/roles";

    export default {
        mixins: [helper],
        data(){
            return {
                moment: moment,
                step: 1,
                type: 'absence',
                model: null,
                types: [
                    {value: 'absence', label: this.$t('absence')},
                    {value: 'sick', label: this.$t('sick')},
                    {value: 'vacation', label: this.$t('vacation')}
                ],
                dates: []
            }
        },
        methods: {
            close(){
                this.$parent.toggleRequest();
            },
            next(){
                if (this.type === 'sick'){
                    this.model = {
                        day: null,
                        time: null,
                        notes: null,
                        use_free_hours: false,
                        errors: {}
                    };
                } else {
                    this.model = [];
                }
                this.step = 2;
            },
            back(){
                this.step = 1;
                this.model = null;
                this.dates = [];
            },
            change(){
                if (this.dates && this.dates.length === 2){
                    let dates = {
                        start: moment(this.dates[0]),
                        end: moment(this.dates[1])
                    };

                    let days = moment(dates.end).diff(dates.start, 'days') + 1;

                    this.model = [];

                    for (let i = 0; i < days; i++){
                        let day = moment(dates.start).add(i, 'days');
                        this.model.push({
                            day: day.format('YYYY-MM-DD'),
                            time: '00:00',
                            errors: {}
                        });
                    }
                } else {
                    this.model = [];
                }
            },
            submit(){
                let data = {
                    type: this.type,
                    model: this.model
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/free-day', data).then(async ({data}) => {
                    if (data.success){
                        this.$parent.getFreeDays();

                        await $http().post(base_url + '/api/me').then(({data}) => {
                            this.$store.commit('setUser', data.user);
                        });

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else if (data.model){
                        this.model = data.model;
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            title: function () {
                if (this.step === 1){
                    return this.$t('new_request');
                }
                return this.type === 'sick' ? this.$t('reporting_sick') : this.$t('new_request');
            },
            next_button: function () {
                if (this.type === 'sick'){
                    return this.$t('call_in_sick_now');
                }
                return this.$t('select_the_days');
            },
            total_hours: function () {
                return this.model.reduce((acc, item) => {
                    return acc + this.$TimeToMinutes(item.time);
                }, 0) / 60;
            },
            available_data: function () {
                if (this.$RoleIs(roles.director)) {
                    return '';
                }

                const hours = this.$User()['available_free_hours'];

                const days = Math.floor((hours / 8) * 10) / 10;

                return this.$t('free_hours.available', {days, hours});
            },
            sick_checkbox: function () {
                if (this.$RoleIs(roles.director) || !this.model) {
                    return false;
                }

                const hours = this.$User()['available_free_hours'];

                if (this.model.time) {
                    return hours >= this.$TimeToMinutes(this.model.time) / 60;
                }

                return false;
            },
            submit_button: function () {
                return this.type === 'sick' ? this.$t('send_reporting_sick') : this.$t('send_request');
            }
        },
        watch: {
            sick_checkbox(value) {
                if (!value) {
                    this.model.use_free_hours = false;
                }
            }
        }
    }
</script>