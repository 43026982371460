<template>
    <div class="popup seers-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ note.title }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="seers-parts">
                    <div v-if="getUsers('read').length" class="seers-part">
                        <p><i class="fa fa-eye"></i> {{ $t('read') }}</p>
                        <div class="see-items">
                            <div class="see-item" v-for="(user, key) in getUsers('read')" :key="'read-user-' + key">
                                <img :src="$Avatar(user)" alt="">
                                <span>{{ $FullName(user) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="getUsers('unread').length" class="seers-part">
                        <p><i class="fa fa-eye-slash"></i> {{ $t('unread') }}</p>
                        <div class="see-items">
                            <div class="see-item" v-for="(user, key) in getUsers('unread')" :key="'unread-user-' + key">
                                <img :src="$Avatar(user)" alt="">
                                <span>{{ $FullName(user) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";

    export default {
        mixins: [helper],
        data() {
            return {
                note: this.$parent.seers.note
            }
        },
        methods: {
            close(){
                this.$parent.toggleSeers();
            },
            getUsers(option){
                let users = {
                    read: [],
                    unread: []
                };

                this.$parent.staff.map((user) => {
                    if (user.id !== this.$User().id){
                        if (this.note.seers.find(s => s.user_id === user.id)){
                            users.read.push(user);
                        } else {
                            users.unread.push(user);
                        }
                    }
                });

                return users[option];
            }
        }
    }
</script>