import Vue from 'vue';
import App from './App';
import './js/main.js';
import './scss/main.scss';

import {locale} from "./constants/localization";
import uiEn from 'element-ui/lib/locale/lang/en';
import uiNl from 'element-ui/lib/locale/lang/nl';

import router from './routes/routes';
import store from './store';
import i18n from "./plugins/i18n";
import moment from "moment";

import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import ElementUI from 'element-ui';
import onlyInt from 'vue-input-only-number';

import { Quill } from 'vue2-editor';
import ImageResize from 'quill-image-resize-vue';
import { ImageDrop } from 'quill-image-drop-module';
import Vuetify from 'vuetify';
import DaySpanVuetify from 'dayspan-vuetify';

window.Pusher = require('pusher-js');
window.Pusher.logToConsole = process.env.NODE_ENV !== 'production';

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(ElementUI, {locale: locale === 'en' ? uiEn : uiNl});
Vue.use(onlyInt);
Vue.use(Vuetify);
Vue.use(DaySpanVuetify, {
    methods: {
        getDefaultEventColor: () => '#1976d2'
    }
});

moment.locale(locale);

new Vue({
    el: '#app',
    i18n,
    store,
    router,
    render: app => app(App)
});