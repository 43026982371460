<template>
    <div class="notification-item-content">
        <span>{{ title }}</span>
        <el-button v-if="!notification.is_read" @click="markRead()" type="primary" size="mini" icon="el-icon-view" circle></el-button>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        props: {
            notification: {
                type: Object
            }
        },
        methods: {
            markRead(){
                this.$parent.markRead(this.notification.id);
            }
        },
        computed: {
            title: function () {
                let start = moment(this.notification.user.birthday, 'YYYY-MM-DD');
                let end = moment(this.notification.date, 'YYYY-MM-DD');

                return this.$t('notifications.birthday.title', {
                    anniversary_name: this.notification.user.name,
                    gender: this.$t('genders_basic.' + this.notification.user.gender),
                    years: end.diff(start, 'years')
                });
            }
        }
    }
</script>