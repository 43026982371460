<template>
    <section class="log-hours-section default-section">
        <div class="section-title">
            <h1>{{ title }}</h1>
            <el-link v-if="current.specification" :href="current.specification" target="_blank" :underline="false">
                <el-button type="primary" size="medium" round>
                    <span class="white--text">{{ $t('specification') }}</span>
                    <i class="el-icon-view el-icon--right white--text"/>
                </el-button>
            </el-link>
        </div>

        <div :class="`log-hours-top side-${side}`">
            <div class="empty-area"></div>
            <div class="calendar-area">
                <el-button @click="back()" type="primary" size="mini" icon="el-icon-arrow-left" circle></el-button>
                <p>{{ calendar }}</p>
                <el-button @click="next()" type="primary" size="mini" icon="el-icon-arrow-right" circle></el-button>
            </div>
            <div class="employee-area">
                <div v-if="side === 'all'" class="employee-field">
                    <p class="employee-label">{{ $t('choose_the_employee') }}</p>
                    <el-select
                            :value="all.user ? all.user.id : null"
                            @change="changeUser($event)"
                            filterable
                            remote
                            reserve-keyword
                            placeholder="">
                        <el-option
                                class="option-with-image"
                                v-for="employee in all.employees"
                                :key="'employee-' + employee.id"
                                :label="$FullName(employee)"
                                :value="employee.id">
                            <img :src="$Avatar(employee)" alt="">
                            <span>{{ $FullName(employee) }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div :class="`log-hours-content side-${side}`">
            <vue-editable-grid
                    :key="'week-log-hours-' + keys.week_log_hours"
                    @cell-updated="cellUpdated"
                    :column-defs="table.header"
                    :row-data="table.rows"
                    :enableFilters="false"
                    row-data-key="type"
                    id="week-log-hours"
            ></vue-editable-grid>

            <div class="log-hours-output log-hours-range" v-if="actionable">
                <div class="placeholder-title">
                    <p>{{ $t('set_start_and_end') }}</p>
                </div>
                <div v-for="(log_hour, index) in current.live" :key="'log-hour-range-' + index">
                    <el-button
                               v-if="[null, 'pending', 'declined'].indexOf(log_hour.status) !== -1 && $Company()['working_days'].includes((index + 1) + 'nd')"
                               @click="toggleRange(index)"
                               type="primary"
                               size="small"
                               icon="el-icon-time"
                               circle
                    ></el-button>
                </div>
                <div></div>
            </div>

            <hr class="log-hours-hr">
        </div>

        <div class="log-hours-bottom" v-if="actionable">
            <div class="log-hours-bottom-right">
                <div class="log-hours-actions">
                    <p :class="`${savable ? '' : 'disable'}`" @click="cancel()">{{ $t('cancel') }}</p>
                    <el-button :disabled="!savable" @click="save()" type="primary" size="medium" icon="el-icon-check">{{ $t('save') }}</el-button>
                    <div class="passable-actions" v-if="has_passable_days">
                        <el-popconfirm
                                v-if="passable.type === 'single'"
                                icon-color="red"
                                icon="el-icon-info"
                                :cancel-button-text="$t('no')"
                                :confirm-button-text="$t('yes')"
                                :title="$t('are_you_sure')"
                                @confirm="pass('multiple', 'week')"
                        >
                            <el-button slot="reference" type="success" size="medium" icon="el-icon-upload">{{ $t('pass_this_week') }}</el-button>
                        </el-popconfirm>
                        <el-popconfirm
                                v-if="passable.type === 'multiple' && passable.days.first.length > 0"
                                icon-color="red"
                                icon="el-icon-info"
                                :cancel-button-text="$t('no')"
                                :confirm-button-text="$t('yes')"
                                :title="$t('are_you_sure')"
                                @confirm="pass('multiple', 'first')"
                        >
                            <el-button slot="reference" type="success" size="medium" icon="el-icon-upload">{{ passTitle(passable.days.first) }}</el-button>
                        </el-popconfirm>
                        <el-popconfirm
                                v-if="passable.type === 'multiple' && passable.days.second.length > 0"
                                icon-color="red"
                                icon="el-icon-info"
                                :cancel-button-text="$t('no')"
                                :confirm-button-text="$t('yes')"
                                :title="$t('are_you_sure')"
                                @confirm="pass('multiple', 'second')"
                        >
                            <el-button slot="reference" type="success" size="medium" icon="el-icon-upload">{{ passTitle(passable.days.second) }}</el-button>
                        </el-popconfirm>
                    </div>
                </div>
                <div class="log-hours-pass-description" v-if="has_passable_days">
                    <p>{{ $t('can_not_change_passed_hours') }}</p>
                </div>
            </div>
        </div>

        <transition name="fade">
            <range v-if="range.popup"></range>
        </transition>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {roles} from "../../../../constants/roles";
    import moment from 'moment';
    import VueEditableGrid from 'vue-editable-grid';
    import {Message} from "element-ui";
    import $ from 'jquery';
    import Range from "./Range";

    export default {
        mixins: [helper],
        components: {VueEditableGrid, Range},
        beforeMount() {
            this.getWeekLogHours();
        },
        data() {
            return {
                moment: moment,
                monday: moment().isoWeekday(1).startOf('isoWeek'),
                all: {
                    employees: [],
                    user: null
                },
                current: {
                    user: null,
                    core: [],
                    live: [],
                    specification: null
                },
                range: {
                    popup: false,
                    index: null
                },
                keys: {
                    week_log_hours: 0
                }
            }
        },
        methods: {
            async getWeekLogHours(){
                let monday = moment(this.monday).format('YYYY-MM-DD');

                this.$store.commit('setPreloader', true);
                await $http().get(base_url + '/api/client/log-hour?monday=' + monday).then(({data}) => {
                    if (this.side === 'all'){
                        let employees = data.employees;
                        let user = null;

                        if (employees.length){
                            if (this.all.user && employees.findIndex(e => e.id === this.all.user.id) !== -1){
                                user = employees.find(e => e.id === this.all.user.id);
                            } else {
                                user = employees[0];
                            }
                        }

                        this.all = {
                            employees: employees,
                            user: user
                        };
                    } else {
                        this.current = {
                            user: this.$User(),
                            core: JSON.parse(JSON.stringify(data.week_log_hours)),
                            live: JSON.parse(JSON.stringify(data.week_log_hours)),
                            specification: data.specification
                        }
                    }

                    this.normalize();

                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            changeUser(id){
                this.all.user = this.all.employees.find(e => e.id === id);
            },
            back(){
                this.monday = moment(this.monday.subtract(7, 'days'));
                this.getWeekLogHours();
            },
            next(){
                this.monday = moment(this.monday.add(7, 'days'));
                this.getWeekLogHours();
            },
            passTitle(days){
                let separator = process.env.VUE_APP_LOCALE === 'en' ? ' - ' : ' t/m ';

                if (days.length === 1){
                    return this.$t('pass_title', {
                        days: moment(days[0].day, 'YYYY-MM-DD').format('D'),
                        month: moment(days[0].day, 'YYYY-MM-DD').format('MMMM')
                    });
                }

                return this.$t('pass_title', {
                    days: [
                        moment(days[0].day, 'YYYY-MM-DD').format('D'),
                        moment(days[days.length - 1].day, 'YYYY-MM-DD').format('D')
                    ].join(separator),
                    month: moment(days[0].day, 'YYYY-MM-DD').format('MMMM')
                });
            },
            toggleRange(index = null){
                this.range = {
                    popup: !this.range.popup,
                    index: index
                };
            },
            updateLogHour(updates){
                this.current.live[this.range.index].start = updates.start;
                this.current.live[this.range.index].end = updates.end;
                this.current.live[this.range.index].normal = updates.normal;
                this.toggleRange();
            },
            cellUpdated(item){
                item.preventDefault();

                let log_hour = this.current.live[item.columnIndex - 1];
                let type = '';

                if (item.rowIndex === 3){
                    type = 'pause';
                }

                if (item.rowIndex === 5 && this.$Company()['show_log_hours_over']){
                    type = 'over';
                }

                if (log_hour !== undefined && type){
                    if ([null, 'pending', 'declined'].indexOf(log_hour.status) !== -1){
                        if (type === 'pause'){
                            let value = +item.value;
                            if (!isNaN(value) && value >= 0){
                                log_hour[type] = +value;
                            }
                        } else {
                            if (item.value.search(':') !== -1){
                                let start = moment('00:00', 'HH:mm');
                                let end = moment(item.value, 'HH:mm');

                                if (end.format() !== 'Invalid date'){
                                    log_hour[type] = end.diff(start, 'minutes') / 60;
                                }
                            } else {
                                let value = item.value.toString().split(',').join('.');
                                if (!isNaN(+value) && value.trim() !== '' && +value >= 0){
                                    log_hour[type] = +value;
                                }
                            }
                        }

                        item.confirm();
                    }
                }
            },
            cancel(){
                this.current.live = JSON.parse(JSON.stringify(this.current.core));
            },
            save(){
                if (this.actionable){
                    let week_log_hours = this.current.live.filter((item) => {
                        return [null, 'pending', 'declined'].indexOf(item.status) !== -1;
                    });

                    this.$store.commit('setPreloader', true);
                    $http().post(base_url + '/api/client/log-hour', {week_log_hours: week_log_hours}).then(({data}) => {
                        if (data.success){
                            this.getWeekLogHours();
                        } else {
                            Message({
                                message: data.message,
                                showClose: true,
                                type: 'warning'
                            });
                        }
                        this.$store.commit('setPreloader', false);
                    }).catch(() => {
                        this.$store.commit('setPreloader', false);
                    });
                }
            },
            pass(type, argument){
                if (this.actionable){
                    let week_log_hours;

                    if (type === 'multiple'){
                        week_log_hours = argument === 'week' ? this.passable.days : this.passable.days[argument];
                    } else {
                        week_log_hours = [argument];
                    }

                    this.$store.commit('setPreloader', true);
                    $http().post(base_url + '/api/client/pass-log-hours', {week_log_hours: week_log_hours}).then(({data}) => {
                        if (data.success){
                            this.getWeekLogHours();

                            Message({
                                message: data.message,
                                showClose: true,
                                type: 'success'
                            });
                        } else {
                            Message({
                                message: data.message,
                                showClose: true,
                                type: 'warning'
                            });
                        }
                        this.$store.commit('setPreloader', false);
                    }).catch(() => {
                        this.$store.commit('setPreloader', false);
                    });
                }
            },
            normalize(){
                $(() => {
                    let log_hours_statuses = $('.log-hours-statuses');
                    let log_hours_range = $('.log-hours-range');

                    if (log_hours_statuses.length){
                        log_hours_statuses.animate({scrollLeft: 0}, 0);
                        $('.grid-table tbody').scroll(function() {
                            log_hours_statuses.animate({scrollLeft: $(this).scrollLeft()}, 0);
                        });
                    }

                    if (log_hours_range.length){
                        log_hours_range.animate({scrollLeft: 0}, 0);
                        $('.grid-table tbody').scroll(function() {
                            log_hours_range.animate({scrollLeft: $(this).scrollLeft()}, 0);
                        });
                    }

                    if (this.side === 'current'){
                        this.current.live.map((item, index) => {
                            if ([null, 'pending', 'declined'].indexOf(item.status) === -1 || !this.$Company()['working_days'].includes((index + 1) + 'nd')){
                                $('.grid-table tbody > div tr:nth-child(4) td:nth-child(' + (index + 2) + ')').addClass('disabled');
                                $('.grid-table tbody > div tr:nth-child(6) td:nth-child(' + (index + 2) + ')').addClass('disabled');
                            }
                        });
                    }
                });
            }
        },
        computed: {
            side: function () {
                return this.$RoleIs(roles.director) ? 'all' : 'current';
            },
            title: function () {
                if (this.side === 'all'){
                    if (this.all.user){
                        return this.$t('logged_hours') + ' ' + this.$FullName(this.all.user);
                    }

                    return this.$t('no_logged_hours');
                }

                return this.$t('log_hours');
            },
            calendar: function () {
                let week = moment(this.monday).week();
                let start = moment(this.monday).format('D');
                let end = moment(this.monday).add(6, 'days').format('D');
                let month = moment(this.monday).add(6, 'days').format('MMM');

                return this.$t('week') + ' ' + week + ' (' + start + this.$t('match_calendar') + end + ' ' + month + '.)';
            },
            savable: function() {
                let core = JSON.stringify(this.current.core);
                let live = JSON.stringify(this.current.live);
                return core !== live;
            },
            passable: function () {
                let start = moment(this.monday);
                let end = moment(this.monday).add(6, 'days');
                let type = start.format('MMM') === end.format('MMM') ? 'single' : 'multiple';
                let days;

                if (type === 'single'){
                    days = this.current.live.filter((item) => {
                        return [null, 'pending', 'declined'].indexOf(item.status) !== -1;
                    });
                } else {
                    let coordinates = {
                        first: {
                            start: moment(this.monday),
                            end: moment(this.monday).add(6, 'days').startOf('month').subtract(1, 'days')
                        },
                        second: {
                            start: moment(this.monday).add(6, 'days').startOf('month'),
                            end: moment(this.monday).add(6, 'days')
                        }
                    };

                    let first = this.current.live.filter((item) => {
                        if (moment(item.day, 'YYYY-MM-DD').isBetween(coordinates.first.start, coordinates.first.end, undefined, '[]')){
                            return [null, 'pending', 'declined'].indexOf(item.status) !== -1;
                        }
                        return false;
                    });

                    let second = this.current.live.filter((item) => {
                        if (moment(item.day, 'YYYY-MM-DD').isBetween(coordinates.second.start, coordinates.second.end, undefined, '[]')){
                            return [null, 'pending', 'declined'].indexOf(item.status) !== -1;
                        }
                        return false;
                    });

                    days = {
                        first: first,
                        second: second
                    };
                }

                return {
                    type: type,
                    days: days
                };
            },
            has_passable_days: function () {
                if (this.passable.type === 'single'){
                    return this.passable.days.length > 0;
                }
                return this.passable.days.first.length > 0 || this.passable.days.second.length > 0;
            },
            actionable: function () {
                let count = this.current.live.filter((item) => {
                    return [null, 'pending', 'declined'].indexOf(item.status) !== -1;
                }).length;
                return count > 0;
            },
            table: function () {
                let header = [
                    {field: 'type', headerName: this.$t('hour_type'), editable: false},
                    {field: 'mo', headerName: this.$t('week_names.mo'), editable: this.$Company()['working_days'].includes('1nd')},
                    {field: 'tu', headerName: this.$t('week_names.tu'), editable: this.$Company()['working_days'].includes('2nd')},
                    {field: 'we', headerName: this.$t('week_names.we'), editable: this.$Company()['working_days'].includes('3nd')},
                    {field: 'th', headerName: this.$t('week_names.th'), editable: this.$Company()['working_days'].includes('4nd')},
                    {field: 'fr', headerName: this.$t('week_names.fr'), editable: this.$Company()['working_days'].includes('5nd')},
                    {field: 'sa', headerName: this.$t('week_names.sa'), editable: this.$Company()['working_days'].includes('6nd')},
                    {field: 'su', headerName: this.$t('week_names.su'), editable: this.$Company()['working_days'].includes('7nd')},
                    {field: 'total', headerName: this.$t('total'), editable: false}
                ];

                let hours;

                if (this.side === 'all'){
                    hours = this.all.user ? this.all.user.week_log_hours : [];
                } else {
                    hours = this.current.live;
                }

                let rows = [];

                if (hours.length){
                    let days = {
                        type: null,
                        mo: moment(hours[0]['day'], 'YYYY-MM-DD').format('D/MM'),
                        tu: moment(hours[1]['day'], 'YYYY-MM-DD').format('D/MM'),
                        we: moment(hours[2]['day'], 'YYYY-MM-DD').format('D/MM'),
                        th: moment(hours[3]['day'], 'YYYY-MM-DD').format('D/MM'),
                        fr: moment(hours[4]['day'], 'YYYY-MM-DD').format('D/MM'),
                        sa: moment(hours[5]['day'], 'YYYY-MM-DD').format('D/MM'),
                        su: moment(hours[6]['day'], 'YYYY-MM-DD').format('D/MM'),
                        total: null
                    };

                    let ranges = {
                        type: this.$t('start') + ' & ' + this.$t('end').toLowerCase(),
                        mo: this.$DisplayRange(hours[0]),
                        tu: this.$DisplayRange(hours[1]),
                        we: this.$DisplayRange(hours[2]),
                        th: this.$DisplayRange(hours[3]),
                        fr: this.$DisplayRange(hours[4]),
                        sa: this.$DisplayRange(hours[5]),
                        su: this.$DisplayRange(hours[6]),
                        total: null
                    };

                    let normals = {
                        type: this.$t('normal'),
                        mo: hours[0]['normal'].toFixed(2),
                        tu: hours[1]['normal'].toFixed(2),
                        we: hours[2]['normal'].toFixed(2),
                        th: hours[3]['normal'].toFixed(2),
                        fr: hours[4]['normal'].toFixed(2),
                        sa: hours[5]['normal'].toFixed(2),
                        su: hours[6]['normal'].toFixed(2),
                        total: hours.reduce((total, hour) => { return total + hour['normal'] }, 0).toFixed(2)
                    };

                    let pauses = {
                        type: this.$t('pause_in_minutes'),
                        mo: hours[0]['pause'],
                        tu: hours[1]['pause'],
                        we: hours[2]['pause'],
                        th: hours[3]['pause'],
                        fr: hours[4]['pause'],
                        sa: hours[5]['pause'],
                        su: hours[6]['pause'],
                        total: (hours.reduce((total, hour) => { return total + hour['pause'] }, 0) / 60).toFixed(2)
                    };

                    let totals = {
                        type: this.$t('total'),
                        mo: (hours[0]['normal'] - (hours[0]['pause'] / 60)).toFixed(2),
                        tu: (hours[1]['normal'] - (hours[1]['pause'] / 60)).toFixed(2),
                        we: (hours[2]['normal'] - (hours[2]['pause'] / 60)).toFixed(2),
                        th: (hours[3]['normal'] - (hours[3]['pause'] / 60)).toFixed(2),
                        fr: (hours[4]['normal'] - (hours[4]['pause'] / 60)).toFixed(2),
                        sa: (hours[5]['normal'] - (hours[5]['pause'] / 60)).toFixed(2),
                        su: (hours[6]['normal'] - (hours[6]['pause'] / 60)).toFixed(2),
                        total: (normals['total'] - pauses['total']).toFixed(2)
                    };

                    let overs = {
                        type: this.$t('over'),
                        mo: hours[0]['over'].toFixed(2),
                        tu: hours[1]['over'].toFixed(2),
                        we: hours[2]['over'].toFixed(2),
                        th: hours[3]['over'].toFixed(2),
                        fr: hours[4]['over'].toFixed(2),
                        sa: hours[5]['over'].toFixed(2),
                        su: hours[6]['over'].toFixed(2),
                        total: hours.reduce((total, hour) => { return total + hour['over'] }, 0).toFixed(2)
                    };

                    let numbersWithComma = (object) => {
                        Object.keys(object).map(property => {
                            if (property !== 'type'){
                                object[property] = object[property].toString().split('.').join(',');
                            }
                        });
                        return object;
                    };

                    normals = numbersWithComma(normals);
                    pauses = numbersWithComma(pauses);
                    totals = numbersWithComma(totals);
                    overs = numbersWithComma(overs);

                    rows.push(days, ranges, normals, pauses, totals);
                    if (this.$Company()['show_log_hours_over']) {
                        rows.push(overs);
                    }
                }

                return {
                    header: header,
                    rows: rows
                }
            }
        },
        watch: {
            table: {
                deep: true,
                immediate: true,
                handler: function () {
                    this.keys.week_log_hours++;
                }
            }
        }
    }
</script>