<template>
    <section class="agenda-section default-section">
        <FullCalendar :key="refresh" class='agenda-calendar' :options='options'></FullCalendar>

        <transition name="fade">
            <crud v-if="crud.popup"></crud>
        </transition>

        <transition name="fade">
            <show v-if="show.popup"></show>
        </transition>
    </section>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import {locale} from "../../../../constants/localization";
    import {base_url} from "../../../../constants/api";
    import {$http} from "../../../../hooks/api";
    import helper from "../../../../mixins/helper";
    import moment from 'moment';
    import Crud from "./Crud";
    import Show from "./Show";

    export default {
        mixins: [helper],
        components: {FullCalendar, Crud, Show},
        beforeMount() {
            this.getEvents();
        },
        data() {
            return {
                refresh: 0,
                options: {
                    locale,
                    firstDay: 1,
                    plugins: [
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin
                    ],
                    buttonText: {
                        today: this.$t('today'),
                        month: this.$t('month'),
                        week: this.$t('week'),
                        day: this.$t('day')
                    },
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    },
                    initialView: 'dayGridMonth',
                    initialEvents: [],
                    selectable: this.$RoleIs('director') || this.$RoleIs('manager'),
                    selectMirror: true,
                    dayMaxEvents: true,
                    weekNumbers: true,
                    weekends: true,
                    select: this.handleSelect,
                    eventClick: this.handleClick,
                    eventsSet: this.handleEvents
                },
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                },
                show: {
                    popup: false,
                    event: {}
                }
            }
        },
        methods: {
            getEvents(){
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/event').then(({data}) => {
                    this.options.initialEvents = data.events;
                    this.refresh++;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            handleSelect(info) {
                this.options.initialView = info.view.getCurrentData().currentViewType;

                this.crud = {
                    popup: true,
                    action: 'create',
                    model: {
                        title: null,
                        start_date: moment(info.start).format('YYYY-MM-DD'),
                        end_date: moment(info.end).format('YYYY-MM-DD'),
                        start_time: moment(info.start).format('HH:mm'),
                        end_time: moment(info.end).format('HH:mm'),
                        whole_day: info.allDay,
                        description: null
                    }
                };
            },
            handleClick(info) {
                this.options.initialView = info.view.getCurrentData().currentViewType;

                const event = this.options.initialEvents.find(e => {
                    return e.id === +info.event.id && e.type === 'custom';
                });

                if (event){
                    this.show = {
                        popup: true,
                        event
                    };
                }
            }
        }
    }
</script>
