<template>
    <div class="header">
        <router-link tag="div" :to="{name: main}" class="header-logo">
            <img src="../images/logo.svg" alt="">
        </router-link>
        <div class="header-actions">
            <div @click="togglePopup('notifications')" :class="`notification-action ${activities.notifications ? 'active' : ''}`">
                <i class="far fa-bell"></i>
                <span>{{ unreadNotificationsCount }}</span>
            </div>
            <div @click="togglePopup('profile')" :class="`profile-action ${activities.profile ? 'active' : ''}`">
                <span>{{ name }}</span>
                <i class="fas fa-sort-down"></i>
            </div>
            <div @click="toggleSidebar()" :class="`burger-action ${sidebar ? 'active' : ''}`">
                <div></div>
            </div>
        </div>
        <div class="header-popups">
            <notifications ref="notifications" :show="popups.notifications"></notifications>
            <profile :show="popups.profile"></profile>
        </div>
    </div>
</template>

<script>
    import Notifications from "./Notifications";
    import Profile from "./Profile";
    import {users} from "../constants/users";
    import helper from "../mixins/helper";

    export default {
        mixins: [helper],
        components: {Notifications, Profile},
        data() {
            return {
                unreadNotificationsCount: null
            }
        },
        methods: {
            togglePopup(name){
                this.$store.commit('setPopups', {
                    notifications: name === 'notifications' ? !this.popups.notifications: false,
                    profile: name === 'profile' ? !this.popups.profile: false
                });
            },
            toggleSidebar() {
                this.$store.commit('setSidebar', !this.sidebar);
            },
            updateUnreadNotificationsCount(value){
                this.unreadNotificationsCount = value > 0 ? value : null;
            }
        },
        computed: {
            main: function () {
                return users[this.$Role()].main;
            },
            name: function () {
                if (this.$Device() === 'web'){
                    return this.$User()['name'][0] + '. ' + this.$User()['surname'];
                }
                return this.$User()['name'][0] + '. ' + this.$User()['surname'][0] + '.';
            },
            popups: function() {
                return this.$store.getters.getPopups;
            },
            sidebar: function () {
                return this.$store.getters.getSidebar;
            },
            activities: function () {
                return {
                    notifications: this.unreadNotificationsCount > 0 || this.popups.notifications,
                    profile: this.popups.profile
                };
            }
        }
    }
</script>