<template>
    <section class="auth-section">
        <h3 class="auth-title">{{ $t('reset_password.title') }}</h3>
        <div class="auth-form">
            <div :class="`form-field ${$HasError(errors, 'password') ? 'field-invalid' : ''}`">
                <div class="field-label">{{ $t('password') }}</div>
                <el-input
                        v-model="form.password"
                        @keyup.enter.native="submit()"
                        size="medium"
                        show-password
                        name="password"
                        class="with-append"
                >
                    <template slot="append">
                        <i class="el-icon-view"></i>
                    </template>
                </el-input>
                <div v-if="$HasError(errors, 'password')" class="field-error">
                    <span>{{ $GetError(errors, 'password') }}</span>
                </div>
            </div>
            <div class="form-field">
                <div class="field-label">{{ $t('password_confirmation') }}</div>
                <el-input
                        v-model="form.password_confirmation"
                        @keyup.enter.native="submit()"
                        size="medium"
                        show-password
                        name="password"
                        class="with-append"
                >
                    <template slot="append">
                        <i class="el-icon-view"></i>
                    </template>
                </el-input>
            </div>
        </div>
        <el-button @click="submit()">{{ $t('reset_password.submit') }}</el-button>
        <router-link :to="{name: 'login'}" class="auth-link" tag="a">{{ $t('reset_password.link') }}</router-link>
    </section>
</template>

<script>
    import helper from "../../mixins/helper";
    import {$http} from "../../hooks/api";
    import {base_url} from "../../constants/api";
    import {users} from "../../constants/users";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data(){
            return {
                form: {
                    password: null,
                    password_confirmation: null
                },
                errors: {}
            }
        },
        methods: {
            submit(){
                let data = {
                    token: this.$route.query.token,
                    password: this.form.password,
                    password_confirmation: this.form.password_confirmation
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/reset-password', data).then(({data}) => {
                    if (data.success) {
                        this.$store.commit("setAccessToken", data.access_token);
                        this.$store.commit("setExpiresAt", data.expires_at);
                        this.$store.commit("setIsLogged", true);
                        this.$store.commit("setUser", data.user);
                        this.$router.push({name: users[data.user.role.name].main});
                    } else if (data.errors) {
                        this.errors = data.errors;
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>
