<template>
    <div class="popup interview-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $FullName(model.user) }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="columns-area">
                    <div>
                        <p>{{ $t('interview.fields.job_title') }}</p>
                        <p>{{ $t('interview.job_titles.' + model.job_title) }}</p>
                    </div>
                    <div>
                        <p>{{ $t('interview.fields.date') }}</p>
                        <p>{{ moment(model.date, 'YYYY-MM-DD').format('DD-MM-YYYY') }}</p>
                    </div>
                    <div>
                        <p>{{ $t('interview.fields.range') }}</p>
                        <p>
                            <span>{{ moment(model.start, 'YYYY-MM-DD').format('DD-MMM-YYYY') }}</span>
                            <span> {{ $t('interview.until') }} </span>
                            <span>{{ moment(model.end, 'YYYY-MM-DD').format('DD-MMM-YYYY') }}</span>
                        </p>
                    </div>
                    <div v-if="model.performance_1">
                        <p>{{ labels.performance_1 || $t('interview.fields.performance_1', {company: $Company().name}) }}</p>
                        <p>{{ model.performance_1 }}</p>
                    </div>
                    <div v-if="model.performance_2">
                        <p>{{ labels.performance_2 || $t('interview.fields.performance_2') }}</p>
                        <p>{{ model.performance_2 }}</p>
                    </div>
                    <div v-if="model.performance_3">
                        <p>{{ labels.performance_3 || $t('interview.fields.performance_3') }}</p>
                        <p>{{ model.performance_3 }}</p>
                    </div>
                    <div v-if="model.performance_4">
                        <p>{{ labels.performance_4 || $t('interview.fields.performance_4') }}</p>
                        <p>{{ model.performance_4 }}</p>
                    </div>
                    <div v-if="model.future">
                        <p>{{ labels.future || $t('interview.fields.future') }}</p>
                        <p>{{ model.future }}</p>
                    </div>
                    <div v-if="model.before">
                        <p>{{ labels.before || $t('interview.fields.before') }}</p>
                        <p>{{ model.before }}</p>
                    </div>
                    <div v-if="model.after">
                        <p>{{ labels.after || $t('interview.fields.after') }}</p>
                        <p>{{ model.after }}</p>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {locale} from "../../../../constants/localization";
    import helper from "../../../../mixins/helper";
    import moment from 'moment';

    export default {
        mixins: [helper],
        data(){
            return {
                moment: moment,
                model: this.$parent.popup.model
            }
        },
        methods: {
            close(){
                this.$parent.toggleInterview();
            }
        },
        computed: {
            labels: function () {
                return this.$parent.details[locale].labels;
            }
        }
    }
</script>