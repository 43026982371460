export const users = {
    admin: {
        main: 'companies'
    },
    director: {
        main: 'home'
    },
    manager: {
        main: 'home'
    },
    accountant: {
        main: 'home'
    },
    dentist: {
        main: 'home'
    },
    assistent: {
        main: 'home'
    },
    hygienist: {
        main: 'home'
    }
};