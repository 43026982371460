<template>
    <div class="popup reject-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('reject') }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="reject-form">
                    <div :class="`form-field ${$HasError(errors, 'reason') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('reason') }}</div>
                        <el-input type="textarea" :rows="5" v-model="reject.reason"></el-input>
                        <div v-if="$HasError(errors, 'reason')" class="field-error">
                            <span>{{ $GetError(errors, 'reason') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t('submit') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        mixins: [helper],
        data(){
            return {
                reject: this.$parent.reject,
                errors: {}
            }
        },
        methods: {
            close(){
                this.$parent.toggleReject();
            },
            submit(){
                let data = {
                    group: this.reject.free_day.group,
                    reason: this.reject.reason
                };

                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/free-day-reject', data).then(({data}) => {
                    if (data.success) {
                        this.errors = {};

                        this.$parent.getFreeDays();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else {
                        this.errors = data.errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>