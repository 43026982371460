<template>
    <div class="popup destroy-time-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('delete') }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="destroy-time-form">
                    <el-radio-group v-model="destroy_time.type">
                        <el-radio label="current">{{ $t('graphic_delete.current') }}</el-radio>
                        <el-radio label="full">{{ $t('graphic_delete.full') }}</el-radio>
                        <el-radio label="next">{{ $t('graphic_delete.next') }}</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <el-button @click="submit()" size="medium" type="success" round>{{ $t('submit') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";

    export default {
        data() {
            return {
                destroy_time: this.$parent.destroy_time
            }
        },
        methods: {
            close(){
                this.$parent.destroyTimePopup();
            },
            submit(){
                let id = this.destroy_time.time.id;
                let type = this.destroy_time.type;

                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/graphic/' + id + '?type=' + type).then(({data}) => {
                    if (data.success) {
                        this.close();
                        this.$parent.getGraphic();
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>