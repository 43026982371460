<template>
    <section class="categories-section default-section">
        <div class="section-title">
            <el-button v-if="$HasPermission('crud-categories')" @click="toggleCrud('create')" size="medium" type="primary" round>{{ $t('create') }}</el-button>
        </div>

        <div class="categories-content">
            <el-tree :data="tree" :empty-text="$t('no_categories')" node-key="id" :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{node}">
                    <span>{{ node.data.name }}</span>
                    <span class="category-actions">
                        <el-button v-if="$HasPermission('crud-categories')" @click="update(node.data)" icon="el-icon-edit" type="primary" size="mini" circle></el-button>
                        <el-popconfirm
                                icon-color="red"
                                icon="el-icon-info"
                                :cancel-button-text="$t('no')"
                                :confirm-button-text="$t('yes')"
                                :title="$t('delete_question')"
                                @confirm="destroy(node.data)"
                        >
                            <el-button slot="reference" v-if="$HasPermission('crud-categories')" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                        </el-popconfirm>
                    </span>
                </span>
            </el-tree>
        </div>

        <transition name="fade">
            <Crud v-if="crud.popup"></Crud>
        </transition>
    </section>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import Crud from "./Crud";

    export default {
        mixins: [helper],
        components: {Crud},
        beforeMount() {
            this.getCategories();
        },
        data() {
            return {
                categories: [],
                tree: [],
                crud: {
                    popup: false,
                    action: null,
                    model: {}
                }
            }
        },
        methods: {
            getCategories(){
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/category').then(({data}) => {
                    this.categories = data.categories;
                    this.tree = data.tree;
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(category){
                let model = {
                    id: category.id,
                    name: category.name,
                    parent_id: category.parent_id
                };

                this.toggleCrud('update', model);
            },
            destroy(category){
                this.$store.commit('setPreloader', true);
                $http().delete(base_url + '/api/client/category/' + category.id).then(({data}) => {
                    if (data.success){
                        this.getCategories();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            toggleCrud(action, model = {}) {
                if (action === 'create') {
                    model = {
                        name: null,
                        parent_id: null
                    };
                }

                if (this.crud.popup) {
                    this.crud = {
                        popup: false,
                        action: null,
                        model: {}
                    }
                } else {
                    this.crud = {
                        popup: true,
                        action: action,
                        model: model
                    }
                }
            }
        }
    };
</script>