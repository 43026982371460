<template>
    <div class="popup crud-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t(action) }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="crud-form">
                    <div :class="`form-field ${$HasError(errors, 'category_id') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('category') }} </div>
                        <el-select
                                v-model="model.category_id"
                                filterable
                                remote
                                reserve-keyword
                                placeholder="">
                            <el-option
                                    v-for="category in categories"
                                    :key="'category-' + category.id"
                                    :label="category.path"
                                    :value="category.id"
                            ></el-option>
                        </el-select>
                        <div v-if="$HasError(errors, 'category_id')" class="field-error">
                            <span>{{ $GetError(errors, 'category_id') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'title') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('title') }} </div>
                        <el-input size="medium" v-model="model.title"></el-input>
                        <div v-if="$HasError(errors, 'title')" class="field-error">
                            <span>{{ $GetError(errors, 'title') }}</span>
                        </div>
                    </div>
                    <div :class="`form-field ${$HasError(errors, 'content') ? 'field-invalid' : ''}`">
                        <div class="field-label">{{ $t('content') }} </div>
                        <vue-editor
                                :editorOptions="editorOptions"
                                @image-added="$Upload"
                                useCustomImageHandler
                                v-model="model.content"
                        ></vue-editor>
                        <div v-if="$HasError(errors, 'content')" class="field-error">
                            <span>{{ $GetError(errors, 'content') }}</span>
                        </div>
                    </div>
                    <div class="form-field">
                        <Upload
                                ref="uploader"
                                :action="() => reload()"
                                :uploaded="model.files"
                                :upload_errors="upload_errors"
                                :category="false"
                                :key="refresh"
                        ></Upload>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
                <div class="any-actions">
                    <el-button v-if="changed_files" @click="updateFiles()" size="medium" type="warning" round>{{ $t('uploader.update_files') }}</el-button>
                    <el-button @click="submit()" size="medium" type="success" round>{{ $t(action) }}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {base_url} from "../../../../constants/api";
    import {Message} from "element-ui";
    import { VueEditor } from "vue2-editor";
    import objectToFormData from "../../../../mixins/objectToFormData";
    import Upload from "../../../../components/Upload";

    export default {
        mixins: [helper],
        components: {VueEditor, Upload},
        data() {
            return {
                model: this.$parent.crud.model,
                errors: {},
                refresh: 0,
                changed_files: false,
                upload_errors: {},
                editorOptions: {
                    modules: {
                        imageDrop: true,
                        imageResize: {}
                    }
                }
            }
        },
        methods: {
            close(){
                this.$parent.toggleCrud(this.action);
            },
            async reload(){
                await this.$parent.fetchData();
                this.$parent.crud.model.files = this.$parent.most_read.find(p => p.id === this.model.id).files;
                this.refresh++;
            },
            updateFiles(){
                this.$refs.uploader.update();
            },
            submit(){
                let model = objectToFormData({
                    ...this.model,
                    files: this.$refs.uploader.files.new
                });

                if (this.action === 'create'){
                    this.create(model);
                } else {
                    model.append("_method", "put");
                    this.update(model, this.model.id);
                }
            },
            create(model){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/protocol', model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        this.$parent.fetchData();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else if (data.errors){
                        this.errors = data.errors;
                    } else {
                        this.upload_errors = data.upload_errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            update(model, id){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/protocol/' + id, model).then(({data}) => {
                    if (data.success){
                        this.errors = {};

                        if (this.$parent.active){
                            this.$parent.active = data['protocol'];
                        }

                        this.$parent.fetchData();

                        this.close();

                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                    } else if (data.errors){
                        this.errors = data.errors;
                    } else {
                        this.upload_errors = data.upload_errors;
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            action: function () {
                return this.$parent.crud.action;
            },
            categories: function () {
                return this.$parent.categories;
            }
        }
    }
</script>