<template>
    <div class="popup incident-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('incident.label') + ' - ' + $DisplayID(model.id, 'INC') }}</h1>
                <img @click="close()" src="../../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <div class="columns-area">
                    <div>
                        <p>{{ $t('made_on') }}</p>
                        <p>{{ moment(model.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YY') }} {{ $t('at') }} {{ moment(model.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.room') }}</p>
                        <p>{{ model.room }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.category') }}</p>
                        <p>{{ $t('incident.categories.' + model.category) }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.reason') }}</p>
                        <p>{{ model.reason }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.facts_and_possible_causes') }}</p>
                        <p>{{ model.facts_and_possible_causes }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.implications') }}</p>
                        <p>{{ model.implications }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.classification') }}</p>
                        <p>{{ $t('incident.classifications.' + model.classification) }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.short_description') }}</p>
                        <p>{{ model.short_description }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.measurement') }}</p>
                        <p>{{ model.measurement }}</p>
                    </div>
                    <div>
                        <p>{{ $t('incident.columns.user_id') }}</p>
                        <p>{{ model.applicant ? $FullName(model.applicant) : $t('incident.anonymous') }}</p>
                    </div>
                </div>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from "../../../../../mixins/helper";
    import moment from 'moment';

    export default {
        mixins: [helper],
        data(){
            return {
                moment: moment,
                model: this.$parent.incident_popup.model
            }
        },
        methods: {
            close(){
                this.$parent.toggleIncident();
            }
        }
    }
</script>