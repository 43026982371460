const en = {
    // Greeting
    dawn : 'Goedemorgen',
    midday: 'Goedemiddag',
    evening: 'Goedenavond',
    noon: 'Goedenacht',
    get_started: 'Choose an item below to get started',

    // Alerts
    unauthenticated: 'Unauthenticated.',
    not_found: 'Oops! Please reload the page.',
    access_denied: 'Access denied.',
    server_failure: 'There has been server failure.',

    // Empty
    no_companies: 'No companies',
    no_staff: 'No staff',
    no_categories: 'No categories',
    no_complaints: 'No complaints',
    no_free_days: 'No free days',
    no_files: 'No files',
    no_incidents: 'No incidents',
    no_evaluations: 'No evaluations',

    // Tooltips
    graphic_delete: {
        current: 'Remove the current item',
        full: 'Remove the current item and all the future schedule for this specific repeat',
        next: 'Remove all the future schedule for this specific repeat'
    },

    // Popovers
    no: 'No',
    yes: 'Yes',
    delete_question: 'Are you sure to delete this ?',
    delete_question_user: 'Are you sure to delete this user ?',
    remove_user: 'Remove user',
    are_you_sure: 'Are you sure ?',
    do_you_want: 'Do you want to {status}?',
    send_email_question: 'Are you sure you want to send the message to {email}?',

    // Counts
    counts: {
        manager: 'Managers',
        accountant: 'Accountants',
        dentist: 'Dentists',
        assistent: 'Assistentes',
        hygienist: 'Hygienistes'
    },

    // Roles
    male: {
        admin: 'Admin',
        director: 'Director',
        manager: 'Manager',
        accountant: 'Accountant',
        dentist: 'Dentist',
        assistent: 'Assistent',
        hygienist: 'Hygienist'
    },
    female: {
        admin: 'Admin',
        director: 'Director',
        manager: 'Manager',
        accountant: 'Accountant',
        dentist: 'Dentist',
        assistent: 'Assistent',
        hygienist: 'Hygienist'
    },

    // Permissions
    'view-graphic': 'View Graphic',
    'change-graphic': 'Change Graphic',
    'crud-room': 'Crud Room',
    'view-notes': 'View Notes',
    'crud-notes': 'Crud Notes',
    'view-categories': 'View Categories',
    'crud-categories': 'Crud Categories',
    'view-protocols': 'View Protocols',
    'crud-protocols': 'Crud Protocols',
    'manage-checklists': 'Manage Checklists',

    // Header
    my_profile: 'My Profile',
    logout: 'Logout',

    // Sidebar
    companies: 'Companies',
    company: 'Company',
    settings: 'Settings',
    staff: 'Staff',
    functional_interviews: 'Functional interviews',
    graphic: 'Graphic',
    notes: 'Notes',
    categories: 'Categories',
    protocols: 'Protocols',
    log_hours: 'Log hours',
    free_days: 'Absence, sick & vacation',
    salary: 'Salary',
    incidents_and_complaints: 'Incidents & complaints',
    safe_reporting_incident: 'Safe reporting incident',
    complaints: 'Complaints',
    agenda: 'Agenda',

    // Incident
    incident: {
        label: 'Incident',
        tab: 'Submitted incidents',
        back: 'Back to Submitted incidents',
        year_label: 'Show the year',
        all_years: 'All years',
        create_button: 'Report an incident',
        fields: {
            room: 'Room',
            reason: 'Reason of incident',
            category: 'Select a category',
            facts_and_possible_causes: 'Facts and possible causes of incident',
            implications: 'Implications for the patient / employee',
            classification: 'Classification priority',
            short_description: 'Short description of the incident',
            measurement: 'Measurement to avoid repeating',
            user_id: 'Name of applicant (may be Anonymous too)'
        },
        columns: {
            date: 'Date',
            timestamp: 'Timestamp',
            room: 'Room',
            reason: 'Reason of incident',
            category: 'Category',
            facts_and_possible_causes: 'Facts and possible causes of incident',
            implications: 'Implications for the patient / employee',
            classification: 'Classification priority',
            short_description: 'Short description of the incident',
            measurement: 'Measurement to avoid repeating',
            user_id: 'Name of applicant'
        },
        categories: {
            treatments: 'Treatments',
            prick_accident: 'Prick accident',
            hygiene: 'Hygiene',
            file: 'File',
            prescriptions: 'Prescriptions',
            medical_history: 'Medical history',
            x_ray: 'X-ray',
            appointments: 'Appointments',
            other: 'Other'
        },
        classifications: {
            mild: 'Mild (least severe)',
            moderate: 'Moderate (fairly serious)',
            severe: 'Severe',
            very_serious: 'Very serious',
            fatal: 'Fatal (most serious)'
        },
        anonymous: 'Anonymous',
        submit: 'Save report'
    },

    // Interview
    interview: {
        empty: 'No functional interviews',
        back: 'Back to Functional interviews',
        all_years: 'All years',
        year_label: 'Show the year',
        create_button: 'Submit a functional interview',
        until: 'until',
        share_question: 'Are you sure you want to share the functional interview with the employee?',
        job_titles: {
            dentist: 'Dentist',
            dental_assistant: 'Dental assistant',
            dental_hygienist: 'Dental hygienist',
            prevention_assistant: 'Prevention assistant',
            office_manager: 'Office manager',
            clinic_manager: 'Clinic manager',
            dental_technician: 'Dental Technician',
            prosthodontist: 'Prosthodontist'
        },
        group_labels: {
            your_performance: 'Your performance',
            future: 'Future'
        },
        fields: {
            user_id: 'Name employee',
            job_title: 'Job title',
            range: 'Date range this meeting is about',
            date: 'Date of meeting',
            performance_1: 'Do you like your job and why do you want to continue working at {company}?',
            performance_2: 'How is the cooperation between you and your colleagues?',
            performance_3: 'What things are you satisfied about, what do you think are going well?',
            performance_4: 'What are you less satisfied with, what can you get better at? How can that be improved?',
            future: 'Where do you see yourself in a few years? Where do you see yourself in the future?',
            before: 'Previews agreements & improvements',
            after: 'Points for improvement & appointments for the upcoming period'
        },
        actions: {
            save: 'Save',
            save_and_share: 'Save & share',
            revoke_share: 'Revoke share'
        },
        columns: {
            user_id: 'Name employee',
            job_title: 'Job title',
            date: 'Date of meeting',
            range: 'Date range this meeting is about'
        }
    },

    // Evaluation
    evaluation: {
        label: 'Evaluation',
        created_by: 'Created by',
        tab: 'Evaluations',
        create_button: 'Create new evaluation',
        crud_title: 'Evaluate submitted incidents',
        year_label: 'Evaluation about the year'
    },

    // Checklist
    checklist: 'Checklist',
    checklist_areas: {
        room: {
            title: 'Rooms',
            empty: 'No rooms',
            are_you_sure: 'Removing the room will remove it including all the underlying frequencies, tasks & reports. Are you sure you want to proceed?'
        },
        type: {
            title: 'Repeat the checklist',
            empty: 'No frequencies for {room}',
            are_you_sure: 'Removing a frequency will remove the underlying tasks & reports too. Are you sure you want to proceed?'
        },
        field: {
            title: '{type} tasks in {room}',
            empty: 'No fields for {type}'
        }
    },

    checklist_overview: {
        title: 'Overview',
        description: 'Choose a room to check',
        back: 'Back to Overview',
        empty: 'No checklist to show.'
    },
    checklist_submit: {
        title: '{type} checklist of {room} of {date}',
        step_one: 'Step 1 of 2: Checkout the executed task',
        step_two: 'Step 2 of 2: Sign and send',
        select_all: 'Select all',
        unselect_all: 'Unselect all',
        agree: 'I, {full_name} executed the tasks above at {date}'
    },
    checklist_history: {
        title: 'Reports',
        description: 'Checklist reports',
        empty: 'No checklists found for selection above.',
        go_to_current_month: 'Go to current month'
    },

    frequency: 'Frequency',
    frequency_steps: {
        frequency: 'Step 1 of 2: Create frequency',
        fields: 'Step 2 of 2: Create fields for frequency'
    },
    frequency_types: {
        daily: 'Daily',
        weekly: 'Weekly',
        two_weekly: 'Two weekly',
        monthly: 'Monthly',
        two_monthly: 'Two monthly',
        half_yearly: 'Half yearly',
        yearly: 'Yearly'
    },
    frequency_separator_one: 'on',
    frequency_separator_two: 'starting on',
    frequency_label: 'Show the checklist:',
    frequency_daily_description: 'Each working day',

    // Week names
    week_names: {
        mo: 'Mo',
        tu: 'Tu',
        we: 'We',
        th: 'Th',
        fr: 'Fr',
        sa: 'Sa',
        su: 'Su'
    },

    // Auth
    login: {
        title: 'Login Celder',
        submit: 'Login',
        link: 'Forgot a password ?'
    },
    create_password: {
        title: 'Create Password',
        submit: 'Activate',
        link: 'Back to login'
    },
    forgot_password: {
        title: 'Forgot a password ?',
        submit: 'Confirm',
        link: 'Back to login'
    },
    reset_password: {
        title: 'Reset Password',
        submit: 'Confirm',
        link: 'Back to login'
    },

    // Choose by
    choose_by: {
        rooms: 'Choose by rooms',
        users: 'Choose by users',
        types: 'Choose by types',
        statuses: 'Choose by statuses'
    },

    // Others -> Not found
    not_found_title: 'Oops! Nothing was found',
    not_found_description: 'The page you are looking for might have been removed had its name changed or is temporarily unavailable.',
    return_to_back: 'Return to back',

    // Notifications
    notifications: {
        published_new_note: {
            title: 'A new notification has been published'
        },
        log_hours_declined: {
            title: 'Your hours have been rejected'
        },
        complaint: {
            title: 'A new complaint has been filed'
        },
        complaint_reaction: {
            title: 'A response has been posted to an existing complaint from {patient}'
        },
        assigned_complaint: {
            title: 'A complaint has been assigned to you'
        },
        unassigned_complaint: {
            title: 'You are no longer assigned for the complaint “{topic}”'
        },
        free_day_request: {
            title: 'A new {type} request has been submitted'
        },
        free_day_accepted: {
            title: 'Your {type} hours have been accepted'
        },
        free_day_rejected: {
            title: 'Your {type} hours have been rejected'
        },
        log_hours_passed: {
            title: '{user} has just filled in the hours of {month}'
        },
        functional_interview: {
            title: 'Your functional interview’s minutes are available'
        },
        birthday: {
            title: 'Yaay! 🎉 Today is {anniversary_name}\'s birthday! {gender} turned {years} today.'
        },
        birthday_reminder: {
            title: 'Whoop whoop 🎉 {anniversary_name}\'s birthday is coming up! {gender} turns {years} next week {week_day}.'
        }
    },

    // Week days
    week_days: {
        '1nd': 'Monday',
        '2nd': 'Tuesday',
        '3nd': 'Wednesday',
        '4nd': 'Thursday',
        '5nd': 'Friday',
        '6nd': 'Saturday',
        '7nd': 'Sunday'
    },

    // Repeat
    repeat: 'Repeat',
    repeat_types: {
        every: 'Repeat every {day}',
        workday: 'Each working day'
    },
    repeat_actions: {
        date: 'Select end date',
        count: 'Stop after X times'
    },
    tooltip_on_updating_repeat: 'The current schedule event is a part of repeat. Editing this event will disconnect it from accessory events.',

    // Uploader
    uploader: {
        update_files: 'Update files',
        error: 'File type not supported, please select one of the following files to upload: PDF, WORD, EXCEL, PNG, JPEG, JPG, BMP, HTML.',
        title: 'Drop one or more files here or click to select.',
        category_placeholder: 'Select the category',
        uploaded_files: 'Uploaded files',
        categories: {
            'other': 'Other',
            'hepatitis-b-statement': 'Hepatitis B statement',
            'diplomas-and-certificates': 'Diploma\'s and certificates',
            'personal-files': 'Personal files'
        }
    },

    // Free day types
    free_day_types: {
        absence: 'absence',
        sick: 'sick',
        vacation: 'vacation'
    },

    // Duplicates
    free_hours: {
        label: 'Number of vacation hours',
        sick_checkbox: 'Use available vacation hours',
        available: 'Available free days or hours: {days} days ({hours} hours)'
    },
    sick_unused: 'Sick - No vacation hours used',
    sick_used: 'Sick - Vacation hours used',
    new: 'New',
    email: 'Email',
    password: 'Password',
    create: 'Create',
    share: 'Share',
    copy: 'Copy',
    copy_url: 'Copy URL',
    copied_to_clipboard: 'Copied to clipboard',
    protocol_categories: 'Protocol categories',
    close: 'Close',
    name: 'Name',
    pickers: 'Pickers',
    surname: 'Surname',
    gender: 'Salutation',
    genders: {
        male: 'Sir',
        female: 'Madam'
    },
    genders_basic: {
        male: 'He',
        female: 'She'
    },
    address: 'Address',
    bsn_number: 'BSN Number',
    birthday: 'Birthday',
    made_on: 'Made on',
    created_at: 'Created At',
    updated_at: 'Updated At',
    verified_at: 'Verified At',
    submitted_at: 'Submitted At',
    actions: 'Actions',
    update: 'Update',
    role: 'Role',
    permissions: 'Permissions',
    role_and_permissions: 'Role & Permissions',
    room: 'Room',
    delete: 'Delete',
    section: 'Section',
    save: 'Save',
    date: 'Date',
    roles: 'Roles',
    filters: 'Filters',
    rooms: 'Rooms',
    users: 'Users',
    only_me: 'Only me',
    user: 'User',
    color: 'Color',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    start: 'Start',
    end: 'End',
    time: 'Time',
    password_confirmation: 'Confirm password',
    activate: 'Activate',
    title: 'Title',
    content: 'Content',
    opening_hours: 'Opening Hours',
    working_days: 'Working Days',
    view_overtime: 'View overtime',
    description: 'Description',
    event: 'Event',
    start_date: 'Start date',
    end_date: 'End date',
    start_time: 'Start time',
    end_time: 'End time',
    seers: 'Seers',
    show_more: 'Show more',
    read: 'Read',
    unread: 'Unread',
    back_to_notes: 'Back to Notes',
    parent: 'Parent',
    back_to_protocols: 'Back to Protocols',
    back_to_first_category: 'Back to all categories',
    most_read: 'Most read',
    browse_by_category: 'Browse by category',
    subcategories: 'Subcategories',
    items: 'Items',
    category: 'Category',
    profile: 'Profile',
    logged_hours: 'Logged hours',
    no_logged_hours: 'No logged hours',
    choose_the_employee: 'Choose the employee',
    hour_type: 'Hour type',
    normal: 'Normal',
    over: 'Over',
    pause: 'Pause',
    pause_in_minutes: 'Pause (in minutes)',
    total: 'Total',
    salary_total: 'Send to accountant',
    cancel: 'Cancel',
    pass_title: 'Pass {month} {days}',
    pass_this_week: 'Pass this week',
    can_not_change_passed_hours: 'You can no longer change the hours after submitting it.',
    match_calendar: ' / ',
    absence: 'Absence',
    sick: 'Sick',
    vacation: 'Vacation',
    salary_amount: 'Salary amount',
    amount: 'Amount',
    specification: 'Specification',
    status: 'Status',
    reject: 'Reject',
    reason: 'Reason',
    submit: 'Submit',
    overview: 'Overview',
    approve: 'Approve',
    reject_title: 'Reject log hours in {month} of {user}',
    edit_title: 'Edit {user} salary for {month}',
    accept: 'Accept',
    decline: 'Decline',
    change: 'Change',
    choose: 'Choose',
    upload: 'Upload',
    edit: 'Edit',
    uploaded: 'Uploaded',
    pay: 'Pay',
    paid: 'Paid',
    not_passed: 'Not passed',
    code: 'Code',
    approved: 'Approved',
    accepted: 'Accepted',
    assign_to_employee: 'Assign to employee',
    patient_name: 'Patient name',
    phone: 'Phone',
    topic: 'Topic',
    treatment_at: 'Date of treatment',
    opened: 'Opened',
    closed: 'Closed',
    assigned: 'Assigned',
    search_complaint: 'Search complaint by code',
    react: 'React',
    is_patient: 'Message by {name}.',
    send_to_email: 'Send a message to {email}․',
    messages: 'Messages',
    company_code: 'Company code',
    your_name: 'Your name',
    your_email: 'Your email',
    your_phone: 'Your phone',
    current_password: 'Current password',
    change_password: 'Change password',
    password_placeholder: '(the new password should contain as minimum of 8 characters.)',
    postal_code: 'Postal code',
    place: 'Place',
    mark_all_as_read: 'Mark all as read',
    empty_notifications: 'You have no notifications',
    publish: 'Publish',
    type: 'Type',
    pending: 'Pending',
    rejected: 'Rejected',
    request: 'Request',
    send: 'Send',
    at: 'at',
    to: 'to',
    of: 'of',
    inclusive: 'to',
    select_end_date: 'Select end date',
    hour: 'o\'clock',
    hours: 'Hours',
    used_hours: 'Used hours',
    free_day_hours: 'Hours',
    free_day_notes: 'Notes',
    check: 'Check',
    files: 'Files',
    file: 'File',
    history: 'History',
    payroll_tax: 'Payroll Tax',
    pension_fund: 'Pension Fund',
    mark_as_paid: 'Mark as paid',
    set_start_and_end: 'Set start & end hours',
    set: 'Set',
    whole_day: 'Whole day',
    back: 'Back',
    next: 'Next',
    new_request: 'New request',
    reporting_sick: 'Reporting sick',
    request_type: 'Request type',
    select_the_days: 'Select the days',
    call_in_sick_now: 'Call in sick now',
    send_request: 'Send request',
    send_reporting_sick: 'Send reporting sick',
    back_to_request_type: 'Back to request type',
    files_and_folders: 'Files & folders',
    select_the_date_range: 'Select the date range',
    total_hours: 'Total hours',
    requested_hours: 'Requested hours',
    summary: 'Summary',
    edit_mode: "Edit mode",
    fields: 'Fields'
};

export default en;