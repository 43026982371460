<template>
    <div class="popup files-popup">
        <div class="popup-inner">
            <div class="popup-header">
                <h1>{{ $t('files') }}</h1>
                <img @click="close()" src="../../../../images/close.png" alt>
            </div>
            <div class="popup-body scroll-grey">
                <el-tag v-for="(file, key) in files" :key="'file-' + key" effect="plain">
                    <el-link :href="file.fullPath" target="_blank">
                        <span>{{ file.name }}</span>
                        <i class="el-icon-view el-icon--right"></i>
                    </el-link>
                </el-tag>
            </div>
            <div class="popup-footer">
                <el-button @click="close()" size="medium" type="danger" round>{{ $t('close') }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            files: {
                type: Array
            }
        },
        methods: {
            close(){
                this.$parent.toggleFiles();
            }
        }
    }
</script>