<template>
    <div v-if="show" class="profile-popup scroll-grey">
        <router-link :to="{name: 'settings', params: {prefix: 'profile'}}" tag="div">
            <i class="fa fa-user"></i>
            <span>{{ $t('my_profile') }}</span>
        </router-link>
        <div @click="logout()">
            <i class="fa fa-sign-out-alt"></i>
            <span>{{ $t('logout') }}</span>
        </div>
    </div>
</template>

<script>
    import {$http} from "../hooks/api";
    import {base_url} from "../constants/api";

    export default {
        props: {
            show: {
                type: Boolean
            }
        },
        methods: {
            clearStorage() {
                this.$store.commit("setAccessToken", null);
                this.$store.commit("setExpiresAt", null);
                this.$store.commit("setIsLogged", false);
                this.$store.commit("setUser", null);
                this.$router.push({name: "login"});
            },
            logout() {
                $http().post(base_url + "/api/logout").then(() => {
                    this.clearStorage();
                }).catch(() => {
                    this.clearStorage();
                });
            }
        }
    }
</script>