<template>
    <section class="auth-section">
        <h3 class="auth-title">{{ $t('forgot_password.title') }}</h3>
        <div class="auth-form">
            <div class="form-field">
                <div class="field-label">{{ $t('email') }}</div>
                <el-input @keyup.enter.native="submit()" size="medium" v-model="form.email"></el-input>
            </div>
        </div>
        <el-button @click="submit()">{{ $t('forgot_password.submit') }}</el-button>
        <router-link :to="{name: 'login'}" class="auth-link" tag="a">{{ $t('forgot_password.link') }}</router-link>
    </section>
</template>

<script>
    import {$http} from "../../hooks/api";
    import {base_url} from "../../constants/api";
    import {Message} from "element-ui";

    export default {
        data(){
            return {
                form: {
                    email: null
                }
            }
        },
        methods: {
            submit(){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/forgot-password', this.form).then(({data}) => {
                    if (data.success) {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'success'
                        });
                        this.$router.push({name: 'login'});
                    } else {
                        Message({
                            message: data.message,
                            showClose: true,
                            type: 'warning'
                        });
                    }
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        }
    }
</script>
