<template>
    <section class="free-days-section default-section">
        <div class="section-title">
            <h1>{{ $t('free_days') }}</h1>
            <div class="free-days-actions">
                <!--<el-button @click="toggleUsedHours()" size="medium" type="warning" round>{{ $t('used_hours') }}</el-button>-->
                <el-button @click="toggleRequest()" size="medium" type="primary" round>{{ $t('request') }}</el-button>
            </div>
        </div>

        <div class="free-days-filters">
            <div class="free-days-filter">
                <el-date-picker
                        @change="filtering()"
                        v-model="filters.dates"
                        format="dd-MM-yyyy"
                        type="daterange"
                        range-separator="-"
                        :start-placeholder="$t('start')"
                        :end-placeholder="$t('end')">
                </el-date-picker>
            </div>

            <div class="free-days-filter">
                <el-select
                        @change="filtering()"
                        v-model="filters.users"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        :placeholder="$t('choose_by.users')">
                    <el-option
                            class="option-with-image"
                            v-for="user in users"
                            :key="'filter-user-' + user.id"
                            :label="$FullName(user)"
                            :value="user.id">
                        <img :src="$Avatar(user)" alt="">
                        <span>{{ $FullName(user) }}</span>
                    </el-option>
                </el-select>

                <el-select
                        @change="filtering()"
                        v-model="filters.types"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        :placeholder="$t('choose_by.types')">
                    <el-option
                            v-for="type in types"
                            :key="'filter-type-' + type.value"
                            :label="type.label"
                            :value="type.value"
                    ></el-option>
                </el-select>

                <el-select
                        @change="filtering()"
                        v-model="filters.statuses"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        :placeholder="$t('choose_by.statuses')">
                    <el-option
                            v-for="status in statuses"
                            :key="'filter-status-' + status.value"
                            :label="status.label"
                            :value="status.value"
                    ></el-option>
                </el-select>
            </div>
        </div>

        <div class="free-days-content">
            <div class="table-parent scroll-grey">
                <el-table border :empty-text="$t('no_free_days')" :data="data">
                    <el-table-column v-if="isDirector" :label="$t('actions')" class-name="table-actions">
                        <template slot-scope="scope" v-if="scope.row[0].user_id !== $User().id">
                            <el-button
                                    :disabled="scope.row[0].status === 'rejected'"
                                    @click="toggleReject(scope.row[0])"
                                    icon="el-icon-error"
                                    type="danger"
                                    size="mini"
                                    circle
                            />
                            <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    :cancel-button-text="$t('no')"
                                    :confirm-button-text="$t('yes')"
                                    :title="$t('do_you_want', {status: $t('accept')})"
                                    @confirm="accept(scope.row[0])"
                            >
                                <el-button :disabled="scope.row[0].status === 'accepted'" slot="reference" size="mini" type="success" icon="el-icon-success" circle/>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('user')" class-name="image-text">
                        <template slot-scope="scope">
                            <img :src="$Avatar(scope.row[0].user)" alt="">
                            <span>{{ $FullName(scope.row[0].user) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('day')">
                        <template slot-scope="scope">
                            <span>{{ displayDay(scope.row) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('hours')">
                        <template slot-scope="scope">
                            <el-popover
                                    popper-class="request-popover"
                                    placement="bottom-start"
                                    trigger="hover">
                                <template>
                                    <slot name="content">
                                        <div class="request-content">
                                            <span class="requester-name">{{ $FullName(scope.row[0].user) }}'s <span>{{ $t('request') }}</span></span>
                                            <div class="request-items">
                                                <div class="request-item" v-for="(item, key) in scope.row" :key="'request-item-' + key">
                                                    <div>
                                                        <span>{{ moment(item.day, 'YYYY-MM-DD').format('dddd') }}</span>
                                                        <span>{{ moment(item.day, 'YYYY-MM-DD').format('D MMMM') }}</span>
                                                    </div>
                                                    <div>
                                                        <span>{{ $TimeToMinutes(item.time) / 60 }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="request-total">
                                                <span>{{ $t('total') }}</span>
                                                <span>{{ displayHours(scope.row) }}</span>
                                            </div>
                                            <div class="request-notes" v-if="scope.row[0].type === 'sick' && scope.row[0].notes">
                                                <div>{{ $t('free_day_notes') }}</div>
                                                <div v-html="scope.row[0].notes"></div>
                                            </div>
                                        </div>
                                    </slot>
                                    <span slot="reference">{{ displayHours(scope.row) }}</span>
                                </template>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('type')">
                        <template slot-scope="scope">
                            <span>{{ $t(scope.row[0].type) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('status')">
                        <template slot-scope="scope">
                            <el-tag class="capitalize" v-if="scope.row[0].status === 'pending'" effect="dark" type="warning">{{ $t('pending') }}</el-tag>
                            <el-tag class="capitalize" v-if="scope.row[0].status === 'rejected'" effect="dark" type="danger">{{ $t('rejected') }}</el-tag>
                            <el-tag class="capitalize" v-if="scope.row[0].status === 'accepted'" effect="dark" type="success">{{ $t('accepted') }}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <el-pagination
                    v-if="free_days.live.length > pagination.limit"
                    background
                    layout="pager"
                    @current-change="paginate"
                    :pagerCount="5"
                    :current-page="pagination.page"
                    :page-size="pagination.limit"
                    :total="free_days.live.length">
            </el-pagination>
        </div>

        <transition name="fade">
            <used-hours v-if="used_hours.popup"></used-hours>
        </transition>

        <transition name="fade">
            <Request v-if="request.popup"></Request>
        </transition>

        <transition name="fade">
            <Reject v-if="reject.popup"></Reject>
        </transition>
    </section>
</template>

<script>
    import {base_url} from "../../../../constants/api";
    import {roles} from "../../../../constants/roles";
    import helper from "../../../../mixins/helper";
    import {$http} from "../../../../hooks/api";
    import {Message} from "element-ui";
    import UsedHours from "./UsedHours";
    import Request from "./Request";
    import Reject from "./Reject";
    import moment from 'moment';

    export default {
        mixins: [helper],
        components: {UsedHours, Request, Reject},
        beforeMount() {
            this.getFreeDays();
        },
        data() {
            return {
                moment: moment,
                free_days: {
                    core: [],
                    live: []
                },
                pagination: {
                    page: 1,
                    limit: 10
                },
                used_hours: {
                    popup: false
                },
                request: {
                    popup: false
                },
                reject: {
                    popup: false,
                    free_day: null,
                    reason: null
                },
                users: [],
                types: [
                    {value: 'absence', label: this.$t('absence')},
                    {value: 'sick', label: this.$t('sick')},
                    {value: 'vacation', label: this.$t('vacation')}
                ],
                statuses: [
                    {value: 'pending', label: this.$t('pending')},
                    {value: 'rejected', label: this.$t('rejected')},
                    {value: 'accepted', label: this.$t('accepted')}
                ],
                filters: {
                    dates: [],
                    users: [],
                    types: [],
                    statuses: []
                }
            }
        },
        methods: {
            getFreeDays() {
                this.$store.commit('setPreloader', true);
                $http().get(base_url + '/api/client/free-day').then(({data}) => {
                    this.users = data.users;
                    this.free_days.core = JSON.parse(JSON.stringify(data.free_days));
                    this.free_days.live = JSON.parse(JSON.stringify(data.free_days));
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            },
            filtering(){
                let result = JSON.parse(JSON.stringify(this.free_days.core));

                result = result.filter((group) => {
                    let free_day = group[0];
                    let getting = true;

                    if (this.filters.dates && this.filters.dates.length === 2){
                        let dates = {
                            start: moment(this.filters.dates[0]),
                            end: moment(this.filters.dates[1])
                        };

                        if (moment(free_day.day).diff(dates.start) < 0 || moment(free_day.day).diff(dates.end) > 0){
                            getting = false;
                        }
                    }

                    if (this.filters.users.length){
                        if (this.filters.users.indexOf(free_day.user_id) === -1){
                            getting = false;
                        }
                    }

                    if (this.filters.types.length){
                        if (this.filters.types.indexOf(free_day.type) === -1){
                            getting = false;
                        }
                    }

                    if (this.filters.statuses.length){
                        if (this.filters.statuses.indexOf(free_day.status) === -1){
                            getting = false;
                        }
                    }

                    return getting;
                });

                this.free_days.live = result;
            },
            displayDay(group){
                if (group.length === 1){
                    return this.$Date(group[0]['day'], 'DD-MM-YYYY');
                }
                return this.$Date(group[0]['day'], 'DD-MM-YYYY') + ' ' + this.$t('to') + ' ' + this.$Date(group[group.length - 1]['day'], 'DD-MM-YYYY');
            },
            displayHours(group){
                if (group.length === 1){
                    return this.$TimeToMinutes(group[0].time) / 60;
                }
                return group.reduce((acc, free_day) => {
                    return acc + this.$TimeToMinutes(free_day.time);
                }, 0) / 60;
            },
            paginate(page) {
                this.pagination.page = page;
            },
            toggleUsedHours(){
                this.used_hours.popup = !this.used_hours.popup;
            },
            toggleRequest(){
                this.request.popup = !this.request.popup;
            },
            toggleReject(free_day = null){
                if (this.reject.popup){
                    this.reject = {
                        popup: false,
                        free_day: null,
                        reason: null
                    };
                } else {
                    this.reject = {
                        popup: true,
                        free_day: free_day,
                        reason: null
                    };
                }
            },
            accept(free_day){
                this.$store.commit('setPreloader', true);
                $http().post(base_url + '/api/client/free-day-accept', {group: free_day.group}).then(({data}) => {
                    this.getFreeDays();
                    Message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    });
                    this.$store.commit('setPreloader', false);
                }).catch(() => {
                    this.$store.commit('setPreloader', false);
                });
            }
        },
        computed: {
            data: function () {
                return this.free_days.live.slice(this.pagination.limit * this.pagination.page - this.pagination.limit, this.pagination.limit * this.pagination.page);
            },
            isDirector: function () {
                return this.$RoleIs(roles.director);
            }
        },
        watch: {
            data: {
                deep: true,
                immediate: true,
                handler: function (data) {
                    if (data.length === 0 && this.pagination.page > 1){
                        this.pagination.page--;
                    }
                }
            }
        }
    }
</script>