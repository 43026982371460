import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from "../messages";
import {locale, fallbackLocale} from "../constants/localization";

Vue.use(VueI18n);

export default new VueI18n({
    locale: locale,
    fallbackLocale: fallbackLocale,
    messages: messages
});